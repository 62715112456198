import React from 'react'
import Header from '../../components/Header'
import Footer from '../../components/Footer'
import { DeviceContainer } from './ProductDevice.style'
import { imageLink, riplayDocUrl } from './constant'
import InquiryForm from '../userCriteria/inquiryForm'
import ScrollToTop from '../../components/ScrollToTop'
import { ImageUrl } from '../../Images'

const ProductDevice = () => {
  return (
   <DeviceContainer>
        <Header />
        <div className='hero-container'>
            <img src={imageLink.deviceText}/>
            <h1>Gadget Aman dan Hati Tenang <br />dengan Perlindungan Device</h1>
            <p>Gadget keren selalu di tangan, Jangan lupa asuransi, <br />Dengan Asuransi Device Tap Insure, semua aman, Kerusakan tak jadi masalah lagi.</p>
            <div className='hero-div'>
                <div className='hero-div-inner'>
                    <div className='hero-div-items'>
                        <p>Perlindungan Mulai Dari</p>
                        <p className='amount'><span className='super'>Rp</span>99rb<span className='normal'>/tahun</span></p>
                        <p>Dengan Manfaat <br/> Setara Harga Barang</p>
                    </div>
                </div>
            </div>
        </div>
        <div className='content'>
            <div className='content-inner'>
                <div className='tap-device-sec2 section-container'>
                    <h1 className='section-title'>Mengapa Asuransi Device dari TAP Insure?</h1>
                    <p className='section-subtitle'>Kenali apa yang membuat Asuransi Device Tap Insure berbeda.</p>
                    <img src={imageLink.section2Image}/> 
                </div>
                <div className='tap-device-sec3 section-container'>
                    <h1 className='section-title'>Menjamin Devicemu dari Apa Saja?</h1>
                    <p className='section-subtitle'>Produk ini melindungi kamu dari kerugian karena:</p>
                    <div className='table-benefit'>
                            <div className='table-header'>
                                <h4>Kerusakan</h4>
                                <h4>Rusak Total</h4>
                                <h4>Rusak Sebagian</h4>
                            </div>
                        <div className='table-item-container'>
                            <div className='table-items'>
                                <div className='benefit'>
                                    <img src={imageLink.phoneDrop}/>
                                    Kejadian yang tidak terduga
                                </div>
                            </div>
                            <div className='table-items'>
                                <div className='claim-amount'>
                                    Setara harga barang
                                </div>
                            </div>
                            <div className='table-items'>
                                <div className='claim-amount'>
                                    Setara biaya perbaikan
                                </div>
                            </div>
                            <div className='table-items'>
                                <div className='benefit'>
                                    <img src={imageLink.waterDrop}/>
                                    Terkena atau kemasukan cairan
                                </div>
                            </div>
                            <div className='table-items'>
                                <div className='claim-amount'>
                                    Setara harga barang
                                </div>
                            </div>
                            <div className='table-items'>
                                <div className='claim-amount'>
                                    Setara biaya perbaikan
                                </div>
                            </div>
                            <div className='table-items'>
                                <div className='benefit'>
                                    <img src={imageLink.flammable}/>
                                    Kebakaran, sambaran petir, <br />ledakan, dan bencana alam
                                </div>
                            </div>
                            <div className='table-items'>
                                <div className='claim-amount'>
                                    Setara harga barang
                                </div>
                            </div>
                            <div className='table-items'>
                                <div className='claim-amount'>
                                    Setara biaya perbaikan
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='table-benefit table-benefit-alt'>
                            <div className='table-header'>
                                <h4>Kehilangan</h4>
                                <h4>Pertanggungan</h4>
                            </div>
                        <div className='table-item-container'>
                            <div className='table-items'>
                                <div className='benefit'>
                                    <img src={imageLink.manWithMask}/>
                                    <p>Tindakan kriminal seperti pencurian atau kerampokan* <br /><span className='optional'>*hanya berlaku dipaket Platinum</span></p>
                                </div>
                            </div>
                            <div className='table-items'>
                                <div className='claim-amount'>
                                    Setara Harga Barang
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='tap-device-sec4 section-container'>
                    <h1 className='section-title'>Penting!</h1>
                    <p className='section-subtitle'>Klaim asuransi kamu tidak akan diterima untuk kecelakaan yang disebabkan oleh:</p>
                    <div className='tap-device-sec4-group'>
                        <div className='group-items'>
                            <img src={imageLink.phoneWithStats}/>
                            <h3>Kerugian Sebagian <br />(Partial Loss)</h3>
                            <p>Jika perangkatmu rusak, ajukan klaim untuk bagian yang bermasalah. Kami memastikan Kamu akan mendapatkan kompensasi yang adil untuk perbaikan, bahkan jika kerusakannya tidak total.</p>
                        </div>
                        <div className='group-items'>
                            <img src={imageLink.phoneCrack}/>
                            <h3>Kerugian Total <br />(Total Loss)</h3>
                            <p>Jika perangkatmu rusak sepenuhnya, Kamu bisa klaim untuk mendapatkan penggantian. Kami akan memastikan Kamu mendapat dukungan penuh jika perangkatmu tidak bisa dipakai sama sekali.</p>
                        </div>
                        <div className='group-items'>
                            <img src={imageLink.roberry}/>
                            <h3>Pencurian <br />(Burglary)</h3>
                            <p>Jika perangkatmu dicuri, Kamu bisa mengajukan klaim untuk mendapatkan penggantian. Kami menyediakan perlindungan khusus untuk melindungi perangkatmu dari kehilangan yang tidak diinginkan akibat pencurian.</p>
                        </div>

                    </div>
                </div>
                <div className='tap-device-sec5 section-container'>
                    <h1 className='section-title'>Mau Tau Lebih Lanjut?</h1>
                    <p className='section-subtitle'>Cek info lengkap Asuransi Device Tap Insure sekarang!</p>
                    <div className='flex-ctn'>
                        <a href={riplayDocUrl} target='_blank'>
                            <button className="btn-device">
                                Unduh Informasi Produk
                            </button>
                            <button className="btn-device-circle"><img src={ImageUrl.DownloadWhite} width='17px' height='17px' alt='download'/></button>
                        </a>
                    </div>
                    <img src={imageLink.section5Image2}/>
                </div>
                <div className='tap-device-sec6 section-container'>
                    <h1 className='section-title'>Dapatkan Perlindunganmu disini!</h1>
                    <p className='section-subtitle'>Lindungi dirimu hanya dengan beberapa ketukan</p>
                    <div className='sales-form'>
                        <InquiryForm product={"Asuransi Device"}/>
                    </div>
                </div>
                <div className='tap-device-sec7 section-container'>
                    <h1 className='section-title'>Saat Hidup Membawa Kejutan, <br/>TAP Insure Siap Memberikan Perlindungan.</h1>
                    <p className='section-subtitle'>Dengan asuransi Care, kamu bisa fokus menjalani aktivitas tanpa khawatir. Dari kejadian <br/>kecil hingga yang besar, Tap Insure selalu siap memberikan perlindungan bagi kamu dan <br/> kesejahteraanmu. Karena diri kamu, preoritas utama dalam perlindungan.</p>
                    < img src={imageLink.section8Image} />
                </div>
            </div>
        </div>
        <ScrollToTop />
        <Footer />
   </DeviceContainer>
  )
}

export default ProductDevice
