import styled from "styled-components";
import LargeButton from "../../components/LargeButton";

export const PaymentMethodsWrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: url("https://storage.googleapis.com/pp_img/tap-travel-website/common/Bottom-Vector.svg");
  background-repeat: no-repeat;
  background-size: auto;
  background-color: #f5f5f5;
  background-position: bottom;
  gap: 64px;
  flex-direction: column;
  min-height: 80vh;

   @media screen and (max-width: 720px) {
    gap: 16px;
    min-height: 80vh;
    justify-content: flex-start;
  }
`;

export const PaymentMethodsCtn = styled.div`
  width: 80%;
  display: flex;
  justify-content: center;
  gap: 24px;

  @media screen and (max-width: 720px) {
    width: 92%;
    flex-direction: column;
    text-align: center;
    font-size: 10px;
  }
`;
export const PaymentMethodsImageCtn = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  @media screen and (max-width: 720px) {
    flex-direction: row;
    gap: 10px;
    justify-content: flex-start;
    align-items: center;
  }
`
export const PaymentMethodsList = styled.div`
  padding: 48px;
  border-radius: 48px;
  background: #ffffff;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 16%;

  @media screen and (max-width: 720px) {
    width: 85%;
    padding: 24px;
    display: grid;
    border-radius: 16px;
    grid-template-columns: 65% 30%;
    gap: 5%;
  }
`;
export const PaymentMethodImage = styled.img`
  width: 64px;
  height: 64px;

  @media screen and (max-width: 720px) {
    width: 48px;
    height: 48px;
  }
`
export const PaymentMethodName = styled.div`
  font-family: Mulish;
  font-size: 18px;
  font-weight: 800;
  line-height: 22px;
  text-align: center;
  color: #333333;
  margin: 10px 0px 24px 0px;

  @media screen and (max-width: 720px) {
   margin: inherit;
    font-size: 12px;
  }
`;
export const PaymentSelectBtn = styled(LargeButton)`
  width: 70%;

  @media screen and (max-width: 720px) {
    width: 100%;
    font-size: 12px;
  }
`;
export const ErrorCtn = styled.div`
  width: 30%;
  padding: 24px 32px 24px 32px;
  border-radius: 24px;
  background: #fadcdc;
  text-xlign: center;
  color: #dc0000;
  font-family: Mulish;
  font-size: 16px;
  font-weight: 700;
  line-height: 32px;
  text-align: center;

  @media screen and (max-width: 720px) {
    width: auto;
    margin: 0px 20px;
    font-size: 11px;
    line-height: 14px;
    font-weight: 600;
    padding: 16px;
    border-radius: 16px;
  }
`;
export const PaymentHeader = styled.p`
  font-family: Mulish;
  font-size: 18px;
  font-weight: 800;
  line-height: 22px;
  text-align: center;

  @media screen and (max-width: 720px) {
    font-size: 12px;
    line-height: 16px;
    margin-top: 24px;
  }
`;
export const PaymentDescription = styled.p`
  font-family: Mulish;
  font-size: 16px;
  font-weight: 400;
  line-height: 20px;
  text-align: center;

  @media screen and (max-width: 720px) {
    font-size: 10px;
    line-height: 14px;
  }
`;
