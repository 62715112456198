import React from 'react'
import Header from '../../components/Header'
import Footer from '../../components/Footer'
import { TravelContainer } from './ProductTravel.style'
import { imageLink, riplayDocUrl } from './constant'
import UserCriteria from '../userCriteria/travelForm'
import ScrollToTop from '../../components/ScrollToTop'
import { ImageUrl } from '../../Images'

const ProductTravel = () => {
  return (
    <TravelContainer>
        <Header />
        <div className='hero-container'>
            <img src={imageLink.travelText}/>
            <h1>Dilindungi TAP Insure</h1>
            <h1>dari Awal Hingga Akhir Perjalananmu</h1>
            <p>Jalan-jalan ke Kota Tua, Jangan lupa beli kamera,</p>
            <p>Buat kamu yang suka liburan ke mana-mana,</p>
            <p>Asuransi Travel Tap Insure bikin liburan bebas drama!</p>
            <div className='hero-div'>
                <div className='hero-div-inner'>
                    <div className='hero-div-items'>
                        <p>Perlindungan <strong>Domestik</strong></p>
                        <p>Mulai Dari</p>
                        <p className='amount'><span className='super'>Rp</span>25.000</p>
                    </div>
                    <div className='hero-div-items'>
                        <p>Perlindungan <strong>Internasional</strong></p>
                        <p>Mulai dari</p>
                        <p className='amount'><span className='super'>Rp</span>74.000</p>
                    </div>
                </div>
            </div>
        </div>
        <div className='content'>
            <div className='content-inner'>
                <div className='tap-travel-sec2 section-container'>
                    <h1 className='section-title'>Mengapa Asuransi Travel dari TAP Insure?</h1>
                    <p className='section-subtitle'>Kenali apa yang membuat Asuransi Travel Tap Insure berbeda.</p>
                    <img src={imageLink.section2Banner}/>
                </div>
                <div className='tap-travel-sec3 section-container'>
                    <h1 className='section-title'>Cocok untuk Semua Jenis Perjalanan</h1>
                    <p className='section-subtitle'>Asuransi Travel Tap Insure melindungi perjalanan Kamu dari awal hingga kembali</p>
                    <p className='section-subtitle'>dengan aman, sehingga Kamu bisa menikmati perjalanan tanpa khawatir.</p>
                    <div className='tap-travel-sec3-group'>
                        <div className='group-items'>
                            <img src={imageLink.shieldHealth}/>
                            <h3>Lebih dari <br />Sekadar Asuransi</h3>
                            <p>Dapatkan manfaat ekstra seperti perpanjangan otomatis, perlindungan tanggung jawab pribadi, biaya hukum, dan keamanan dari ancaman terorisme.</p>
                        </div>
                        <div className='group-items'>
                            <img src={imageLink.severancePay}/>
                            <h3>Kompensasi Tinggi, <br />Tanpa Khawatir</h3>
                            <p>Dapatkan perlindungan hingga Rp2,5 miliar! Dengan TAP Insure, keuanganmu aman dari berbagai kejadian tak terduga, jadi kamu bisa lebih tenang</p>
                        </div>
                        <div className='group-items'>
                            <img src={imageLink.certificate}/>
                            <h3>Paket yang Sesuai <br />dengan Gaya Hidupmu</h3>
                            <p>Pilih paket yang cocok untuk kebutuhanmu - baik untuk perjalanan sendiri, liburan keluarga, atau bersenang-senang dengan teman, TAP Travel punya semuanya!</p>
                        </div>
                        <div className='group-items'>
                            <img src={imageLink.giveMoney}/>
                            <h3>Klaim Mudah <br />dan Cepat</h3>
                            <p>Rasakan kemudahan dan kecepatan klaim melalui aplikasi TAP Insure. Nikmati perjalanan tanpa khawatir biaya tambahan karena risiko yang mungkin terjadi.</p>
                        </div>
                        <div className='group-items'>
                            <img src={imageLink.kidsAndParent}/>
                            <h3>Untuk Siapa Pun, <br />Kapan Pun</h3>
                            <p>Asuransi yang melindungi semua orang, mulai dari bayi 3 bulan hingga usia 69 tahun. Nikmati perlindungan perjalanan tanpa batas untuk kenyamanan serta dan keamananmu dan keluarga, kapan saja, dan di mana saja.</p>
                        </div>
                        <div className='group-items'>
                            <img src={imageLink.briefcase}/>
                            <h3>Perlindungan Penuh, <br />Perjalanan Tenang</h3>
                            <p>Dengan Asuransi Perjalanan TAP Insure, kamu akan terlindungi sepenuhnya sepanjang perjalanan  dari rumah hingga tiba di tujuanmu. Perlindungan lengkap meliputi kecelakaan pribadi, darurat medis, hingga kehilangan bagasi, jadi kamu bisa bepergian tanpa rasa khawatir.</p>
                        </div>
                        <div className='group-items'>
                            <img src={imageLink.earthAndPlane}/>
                            <h3>Keliling Dunia <br />Tanpa Cemas</h3>
                            <p>Ke mana pun kamu pergi, TAP Insure siap melindungi perjalananmu, baik di dalam negeri maupun luar negeri. Plus, ada manfaat ekstra buat kamu yang hobi jalan-jalan keliling dunia.</p>
                        </div>
                    </div>
                </div>
                <div className='tap-travel-sec4 section-container'>
                    <h1 className='section-title'>Pilih Perlindunganmu</h1>
                    <img src={imageLink.travelGif}/>
                </div>
                <div className='tap-travel-sec5 section-container'>
                    <h1 className='section-title'>Menjamin Perjalananmu dari Apa Saja?</h1>
                    <p className='section-subtitle'>Produk ini melindungi kamu dari kerugian atau kerusakan karena:</p>
                    <div className='table-benefit'>
                        <div className='table-header'>
                            <h4>INTERNASIONAL</h4>
                            <h4>ASEAN</h4>
                            <h4>DOMESTIK</h4>
                        </div>
                        <div className='table-item-container'>
                            <div className='table-items'>
                                <div className='benefit'>
                                    <img src={imageLink.medicalExpenses}/>
                                    Biaya Medis & <br />Biaya Darurat Lainnya
                                </div>
                            </div>
                            <div className='table-items'>
                                <div className='claim-amount'>
                                    Sampai dengan
                                    <h1>Rp2.5 M</h1>
                                </div>
                            </div>
                            <div className='table-items'>
                                <div className='claim-amount'>
                                    Sampai dengan
                                    <h1>Rp350 jt</h1>
                                </div>
                            </div>
                            <div className='table-items'>
                                <div className='claim-amount'>
                                    Sampai dengan
                                    <h1>Rp150 jt</h1>
                                </div>
                            </div>
                            <div className='table-items'>
                                <div className='benefit'>
                                    <img src={imageLink.manwithBandage}/>
                                    Kecelakaan Diri
                                </div>
                            </div>
                            <div className='table-items'>
                                <div className='claim-amount'>
                                    Sampai dengan
                                    <h1>Rp1.5 M</h1>
                                </div>
                            </div>
                            <div className='table-items'>
                                <div className='claim-amount'>
                                    Sampai dengan
                                    <h1>Rp350 jt</h1>
                                </div>
                            </div>
                            <div className='table-items'>
                                <div className='claim-amount'>
                                    Sampai dengan
                                    <h1>Rp250 jt</h1>
                                </div>
                            </div>
                            <div className='table-items'>
                                <div className='benefit'>
                                    <img src={imageLink.cancelPlane}/>
                                    Pembatalan & <br />Perubahan Perjalanan
                                </div>
                            </div>
                            <div className='table-items'>
                                <div className='claim-amount'>
                                    Sampai dengan
                                    <h1>Rp 65 jt</h1>
                                </div>
                            </div>
                            <div className='table-items'>
                                <div className='claim-amount'>
                                    Sampai dengan
                                    <h1>Rp15 jt</h1>
                                </div>
                            </div>
                            <div className='table-items'>
                                <div className='claim-amount'>
                                    Sampai dengan
                                    <h1>Rp5 jt</h1>
                                </div>
                            </div>
                            <div className='table-items'>
                                <div className='benefit'>
                                    <img src={imageLink.luggage}/>
                                    Perlindungan Bagasi<br />dan Barang Pribadi
                                </div>
                            </div>
                            <div className='table-items'>
                                <div className='claim-amount'>
                                    Sampai dengan
                                    <h1>Rp30 jt</h1>
                                </div>
                            </div>
                            <div className='table-items'>
                                <div className='claim-amount'>
                                    Sampai dengan
                                    <h1>Rp12 jt</h1>
                                </div>
                            </div>
                            <div className='table-items'>
                                <div className='claim-amount'>
                                    Sampai dengan
                                    <h1>Rp5 jt</h1>
                                </div>
                            </div>
                            <div className='table-items'>
                                <div className='benefit'>
                                    <img src={imageLink.checklist}/>
                                    Manfaat Lainnya
                                </div>
                            </div>
                            <div className='table-items'>
                                <div className='claim-amount'>
                                    Sampai dengan
                                    <h1>Rp2.5 M</h1>
                                </div>
                            </div>
                            <div className='table-items'>
                                <div className='claim-amount'>
                                    Sampai dengan
                                    <h1>Rp350 jt</h1>
                                </div>
                            </div>
                            <div className='table-items'>
                                <div className='claim-amount'>
                                    Sampai dengan
                                    <h1>Rp150 jt</h1>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='tap-travel-sec6 section-container'>
                    <h1 className='section-title'>Jika Dibutuhkan...</h1>
                    <p className='section-subtitle'>Manfaat lainnya dari asuransi travel dari Tap Insure:</p>
                    <div className='group-item-container'>
                        <div className='group-items'>
                                <img src={imageLink.householdItems}/>
                                <h3>Kehilangan <br />Isi Rumah</h3>
                                <p>Melindungi barang-barang di rumah hingga Rp20.000.000 selama kamu bepergian, jadi kamu bisa pergi tanpa khawatir.</p>
                        </div>
                        <div className='group-items'>
                                <img src={imageLink.lawHammer}/>
                                <h3>Tanggung Gugat <br />Pribadi</h3>
                                <p>Perlindungan hingga Rp2.500.000.000 untuk tanggung jawab hukum di luar negeri, jadi bisa bepergian dengan aman.</p>
                        </div>
                        <div className='group-items'>
                                <img src={imageLink.manWithMask}/>
                                <h3>Perlindungan Dari<br />Pembajakan</h3>
                                <p>Perlindungan finansial hingga Rp10.000.000 jika terjadi pembajakan selama perjalanan, memberikan dukungan di saat sulit.</p>
                        </div>
                        <div className='group-items'>
                                <img src={imageLink.schedule}/>
                                <h3>Perpanjang Jaminan<br />Otomatis</h3>
                                <p>Perlindunganmu otomatis diperpanjang jika terjadi hal tak terduga, memberikan ketenangan selama perjalanan.</p>
                        </div>
                        <div className='group-items'>
                                <img src={imageLink.court}/>
                                <h3>Pertanggungan <br />Biaya Hukum</h3>
                                <p>Menjamin biaya hukum hingga Rp250.000.000 akibat situasi tak terduga selama perjalanan, untuk rasa aman ekstra.</p>
                        </div>
                        <div className='group-items'>
                                <img src={imageLink.keyCar}/>
                                <h3>Perlindungan Kendaraan<br />Sewa</h3>
                                <p>Menjamin kerusakan atau kehilangan kendaraan sewaan hingga Rp10.000.000, supaya kamu bisa berkendara dengan tenang.</p>
                        </div>
                        <div className='group-items'>
                                <img src={imageLink.creditCard}/>
                                <h3>Penyalahgunaan<br />Kartu Kredit</h3>
                                <p>Perlindungan hingga Rp10.000.000 dari penyalahgunaan kartu kredit selama perjalanan, menjaga keamanan finansialmu.</p>
                        </div>
                        <div className='group-items'>
                                <img src={imageLink.hotel}/>
                                <h3>Keehilangan Reservasi <br/>Hotel</h3>
                                <p>Menjamin hingga Rp5.000.000 jika kehilangan reservasi hotel karena alasan tak terduga, memastikan kamu tetap dapat akomodasi.</p>
                        </div>
                    </div>
                </div>
                <div className='tap-travel-sec7 section-container'>
                    <h1 className='section-title'>Mau Tau Lebih Lanjut?</h1>
                    <p className='section-subtitle'>Cek info lengkap Asuransi Travel Tap Insure sekarang!</p>
                    <div className='flex-ctn'>
                        <a href={riplayDocUrl} target='_blank'>
                            <button className="btn-travel">
                                Unduh Informasi Produk
                            </button>
                            <button className="btn-travel-circle"><img src={ImageUrl.DownloadWhite} width='17px' height='17px' alt='download'/></button>
                        </a>
                    </div>
                    <img src={imageLink.section7Banner}/>
                </div>
                <div className='tap-travel-sec8 section-container'>
                    <h1 className='section-title'>Dapatkan Perlindunganmu disini!</h1>
                    <p className='section-subtitle'>Lindungi perjalanan kamu hanya dengan beberapa ketukan.</p>
                    <div className='sales-form'>
                        <UserCriteria />
                    </div>
                </div>
                <div className='tap-travel-sec9 section-container'>
                    <h1 className='section-title'>Saat Kamu Menjalani Petualangan,<br />TAP Insure Siap Memberikan Perlindungan.
                    </h1>
                    <p className='section-subtitle'>Dengan asuransi Travel Tap Insure, kamu bisa menikmati perlindungan menyeluruh, mulai dari perjalanan hingga <br />aset pribadi kamu. Ke mana pun kamu pergi, Tap Insure selalu siap melindungi kamu dan semua yang kamu sayangi</p>
                    <img src={imageLink.section9Banner}/>
                </div>
            </div>
        </div>
        <ScrollToTop />
        <Footer />
    </TravelContainer>
  )
}

export default ProductTravel
