import React, { useEffect, useState } from "react";
import {
  SelectionWrapper,
  SelectInputCtn,
  SelectInput,
  Countries,
  SelectCountryCtn,
  Placeholder,
  SelectCountryName,
  SelectCountry,
} from "./elements";
import { useField, ErrorMessage } from "formik";
import { ImageUrl } from "../../../Images";
import { NoDestinationCtn } from "../SelectMultipleCountry/elements";

const SelectDomesticDestination = (props) => {
  const [field, meta, helpers] = useField(props.name);
  const [countryList, setCountryList] = useState(
    props.countryListOptions || []
  );

  useEffect(() => {
    if (!props.countryListOptions) return

    const newOptionState = props.countryListOptions.length
    if (newOptionState) {
        setCountryList(props.countryListOptions)
    }
    if(props.hideOption) {
        setCountryList(props.countryListOptions?.filter((city) =>
        city.city_name !== props.hideOption
    ))
    }
  }, [props.hideOption, props.countryListOptions])

  const handleFilter = (val) => {
    if (val === "") {
      setCountryList(props.countryListOptions);
    } else {
      const fileteredList = countryList.filter((item) =>
        props.renderer(item).toLowerCase().includes(val.toLowerCase())
      );
      setCountryList(fileteredList);
    }
  };

  const handleClick = (val) => {
    helpers.setValue(val);
    props.onSubmit(val);
  };

  return (
    <SelectionWrapper>
      <SelectCountryCtn>
          <SelectCountry>
              <Placeholder>Dari</Placeholder>
              <SelectCountryName>{props.selectCountry}</SelectCountryName>
          </SelectCountry>
          <img
              style={{cursor: 'pointer'}}
              src={ImageUrl.Edit}
              width="16px"
              height="16px"
              alt="search-icon"
              onClick={() => props.handleEditFromCountry()}
          />
      </SelectCountryCtn>
      <SelectInputCtn>
        <SelectInput
          onChange={(e) => handleFilter(e.target.value)}
          placeholder={props.placeholder}
        />
        <img
          src={ImageUrl.Search}
          width="16px"
          height="16px"
          alt="search-icon"
        />
      </SelectInputCtn>
      {countryList.length ? countryList?.map((country) => {
        return (
          <Countries onClick={() => handleClick(props.getter(country))}>
            {props.renderer(country)}
          </Countries>
        );
      }) : <NoDestinationCtn>Tidak ada tujuan yang ditemukan</NoDestinationCtn>}
    </SelectionWrapper>
  );
};

export default SelectDomesticDestination;
