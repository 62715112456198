import React from "react";
import Header from "../../components/Header";
import Footer from "../../components/Footer";
import {
  AnchorTag,
  DetailsSectionBoldText,
  DetailsSectionImageCtn,
  FooterCtn,
  FormContainer,
} from "./elements";
import SupportForm from "./supportForm";
import DetailsSection from "./detailsSection";
import ScrollToTop from "../../components/ScrollToTop";
import {
  AnchorTag2,
  DetailsSectionText,
  TermsB,
} from "../WhistleBlowing/elements";
import { FormattedMessage } from "react-intl";
import { ImageUrl } from "../../Images";

const DEFAULT_CHUNK_MAP = {
  b: (chunks) => <TermsB><AnchorTag2 href='mailto: ayolapor@tap-insure.com' target="_blank">{chunks}</AnchorTag2></TermsB>,
  br: <br />,
};

const CustomerSupportForm = () => {
  return (
    <div>
      <Header />
      <FormContainer>
        <DetailsSection />
        <SupportForm />
      </FormContainer>
      <FooterCtn>
        <DetailsSectionText>
          <FormattedMessage
            id="Untuk pengaduan & layanan klaim silakan {br} menghubungi Customer Service kami"
            defaultMessage="Untuk pengaduan & layanan klaim silakan {br} menghubungi Customer Service kami"
            values={DEFAULT_CHUNK_MAP}
          />
        </DetailsSectionText>
        <DetailsSectionBoldText>
          <FormattedMessage
            id="Senin - Jumat (09.00 - 18.00)"
            defaultMessage="Senin - Jumat (09.00 - 18.00)"
          />
        </DetailsSectionBoldText>
        <div>
          <DetailsSectionImageCtn>
            <img src={ImageUrl.SMS} height="24px" width="24px" alt="sms" />
            <DetailsSectionText>
              <AnchorTag href="mailto:cs@tap-insure.com" target="_blank">
                <FormattedMessage
                  id="cs@tap-insure.com"
                  defaultMessage="cs@tap-insure.com"
                />
              </AnchorTag>
            </DetailsSectionText>
          </DetailsSectionImageCtn>
          <DetailsSectionImageCtn>
            <img src={ImageUrl.Whatsapp} height="24px" width="24px" alt="sms" />
            <DetailsSectionText underline={true}>
              <AnchorTag
                href="https://api.whatsapp.com/send/?phone=62818889019&text&type=phone_number&app_absent=0"
                target="_blank"
              >
                <FormattedMessage
                  id="0818-889-019"
                  defaultMessage="0818-889-019"
                />
              </AnchorTag>
            </DetailsSectionText>
          </DetailsSectionImageCtn>
        </div>
        <DetailsSectionText>
          <FormattedMessage
            id="Tim CS kami akan segera menghubungi kamu"
            defaultMessage="Tim CS kami akan segera menghubungi kamu"
          />
        </DetailsSectionText>
      </FooterCtn>
      <ScrollToTop />
      <Footer />
    </div>
  );
};

export default CustomerSupportForm;
