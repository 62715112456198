import AboutUs from '../../containers/AboutUs/AboutUs'
import VisiMisi from '../../containers/VisiMisi'
import Organization from '../../containers/Organization'
import CorporateGovernance from '../../containers/CorporateGovernance'

const aboutUsRoutes = [
  {
    path: 'tentang-kami',
    element: <AboutUs />
  },
  {
    path: 'visi-misi',
    element: <VisiMisi />
  },
  {
    path: 'organisasi',
    element: <Organization />
  },
  {
    path: 'tata-kelola-perusahaan', 
    element: <CorporateGovernance />
  }

].map((route) => ({ ...route, path: `/${route.path}` }))

export default aboutUsRoutes