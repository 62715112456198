import React from "react";
import { OptionBtn, OptionsWrappper, PromptError, PromptErrorMsg, OptionstCtn, OptionBtn2 } from "./elements";
import { ErrorMessage, useField } from "formik";
import { ImageUrl } from "../../../Images";

const OptionValue = (props) => {
  const [field, meta, helpers] = useField(props.name);

  const handleClick = (val) => {
    helpers.setValue(val);
  };

  return (
    <OptionstCtn>
      <OptionsWrappper length={props.optionsList.length}>
        {
            props.optionsList.map(option => {
                return (
                    <OptionBtn
                        error={meta.touched && !!meta.error}
                        isSelected={field.value === props.getter(option)}
                        onClick={() =>  props.disabledBtn !== props.getter(option) && handleClick(props.getter(option))}
                        value={props.getter(option)}
                        isDisabled={props.showDisable &&  props.disabledBtn && (props.disabledBtn === props.getter(option))}
                    >
                        {
                            field.value === props.getter(option) ? <img src={ImageUrl.Check} width='16px' height='16px' style={{marginLeft: '14px'}} alt='check'/> : <img src={ImageUrl.CheckGrey} width='16px' height='16px' style={{marginLeft: '14px'}} alt='check'/>
                        }
                        {props.renderer(option)}
                    </OptionBtn>
                )
            })
        }
      </OptionsWrappper>
      <ErrorMessage
        name={props.name}
        render={(msg) => (
          <PromptError>
            <PromptErrorMsg>
              <div>{msg}</div>
            </PromptErrorMsg>
          </PromptError>
        )}
      />
    </OptionstCtn>
  );
};

export default OptionValue;
