// import format from 'date-fns/format'
import PropTypes from 'prop-types'

import {
  LargeButtonContainer,
  LargeButtonContent,
  LargeButtonSpinner
} from './elements'

const LargeButton = ({ children, disabled, loading, onClick, ...props }) => {
  const handleClick = (e) => {
    if (!disabled) {
      onClick(e)
    }
  }
  return (
    <LargeButtonContainer
      disabled={disabled || loading}
      onClick={handleClick}
      {...props}
    >
      <LargeButtonContent disabled={disabled || loading} type={props.type}>
        {children}
      </LargeButtonContent>
      {loading && (
        <LargeButtonSpinner />
      )}
    </LargeButtonContainer>
  )
}

LargeButton.defaultProps = {
  onClick: () => {},
  children: null,
  loading: false,
  disabled: false
}

LargeButton.propTypes = {
  onClick: PropTypes.func,
  children: PropTypes.oneOfType([PropTypes.element, PropTypes.arrayOf(PropTypes.element)]),
  loading: PropTypes.bool,
  disabled: PropTypes.bool
}

export default LargeButton
