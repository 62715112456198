import styled from 'styled-components'

export const OrganizationContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    @import url('https://fonts.googleapis.com/css2?family=Mulish:ital,wght@0,200..1000;1,200..1000&display=swap');
    font-family: "Mulish", sans-serif;

    * {
        padding: 0;
        margin: 0;
    }

    .hero-section {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        padding: 32px 0px 0px 0px;
        width: 100%;  
    }

    .hero-section h1 {
        font-size: 40px;
        word-break: break-word;
        max-width: 858px;
        text-align: center;
    }

    .content {
        width: 100%;  
        background: linear-gradient(180deg, rgba(217,217,217,1) 0%, rgba(255,255,255,1) 80%);
        margin-bottom: 50px;
    }

    .content-inner {
        width: 100%;
        max-width: 1000px;         
        margin: 0 auto;            
        text-align: justify;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        padding: 32px;
        line-height: 32px;
        gap: 40px;        
    }

    .section-title {
        width: 100%;
        text-align: center;
        font-size: 40px;
    }

    .section-subtitle {
        background-color: #FAF000;
        padding: 16px 32px;
        border-radius: 100px;
    }

    .section-subtitle h2 {
        font-size: 18px;
    }

    .committee-container {
        max-width: 1200px;
        margin-bottom: 48px;
    }

    .committee-btn-container {
        display: flex;
        justify-content: space-around;
        gap: 24px;
    }

    .committee-btn {
        display: flex;
        justify-content: space-between;
        align-items: center;
        border: 1px solid #000;
        padding: 16px 24px;
        border-radius: 100px;
        width: 300px;
        gap: 16px;
        line-height: 24px;
        opacity: 0.7;
        cursor: pointer;
        transition: bacground-color 0.5s ease, opacity 0.5s ease;
        text-align: left;

        &:hover {
            background-color: #FAF000;
            opacity: 1;
            border: 1px solid #FAF000;
        }
    }

    .committee-btn .committee-btn-name {
        width: 45%;
    }    

    .committee-btn.active {
        background-color: #FAF000;
        opacity: 1;
        border: 1px solid #FAF000;
    }

    .committee-btn.active .committee-btn-image {
        background-color: #FFF;
    }

    .committee-btn-image {
        width: 160px;
        height: 160px;
        background-color: #D9D9D9;
        border-radius: 50%;
        display: flex;
        justify-content: center;
        align-items: center; 
        overflow: hidden;
        position: relative;
        box-sizing: border-box;
        transition: bacground-color 0.5s ease, opacity 0.5s ease;
    }

    .committee-btn-image img {
        width: auto;
        height: 100%;
        object-fit: cover;
        border-radius: 50%;
    }

    .committee-btn:hover .committee-btn-image {
        background-color: #FFF;
    }

    .committee-name {
        display: none;
    }

    .committee-name.active {
        display: block;
        width: 70%;
        min-height: 468px;
    }

    .committee-pict {
        display: none;
    }

    .committee-pict.active {
        display: block;
        width: 50px;

    }

    .image-container {
        width: 350px;
        height: 350px;
        background-color: #D9D9D9;
        border-radius: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
        overflow: hidden;
    }

    .image-container img {
        max-width: 345px;
    }

    .committee-btn h3 {
        font-size: 18px;
        text-align: left;
        margin-bottom: 12px;
    }

    .committee-btn p {
        font-size: 12px;
        line-height: 12px;
    }

    .committee-items-inner {
        display: flex;
        width: 100%;
        justify-content: space-betweeen;
        align-items: center;
        gap: 32px;
    }

    .committee-items-inner p {
        font-size: 14px;
    }

    .committee-items-inner h2 {
        font-size: 40px;
        margin-bottom: 20px;
    }

    .committee-items-inner h5 {
        font-size: 20px;
        max-width: 558px;
        word-break: break-word;
        font-weight: 400;
        line-height: 24px;
        color: #555;
    }

    .committee-items-inner  h5:last-of-type {
        margin-bottom: 32px;
    }

    .committee-items-inner p {
        line-height: 24px;
        margin-bottom: 24px;
    }

    .committee-graph {
        border-top: 2px solid #FAF000;
        width: 100%;
        padding: 16px;
    }

    .committee-graph-inner {
        display: flex;
        justify-content: center;
        max-height: 0; 
        opacity: 0;    
        overflow: hidden; 
        transition: max-height 0.5s ease, opacity 0.5s ease; 
    }

    .committee-graph-inner.open {
        max-height: 1000px; 
        opacity: 1;
    }

    .committee-graph h3 {
        display: flex;
        justify-content: space-between;
        cursor: pointer;
    }

    .arrow-aboutus {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 24px;
        height: 24px;
        background-color: #FAF000;
        border-radius: 50%;
        padding: 8px;
    }

    .arrow-aboutus img {
        height: 24px;
        width: 24px;
        transition: transform 0.5s ease;
    }

    .arrow-aboutus.open img {
        transform: rotate(180deg);
    }

    .organization-structure img {
        width: 1090px;
    }

    .committee-graph-inner img {
        width: 699px;
    }

`