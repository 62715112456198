import RoadAssistance from '../../containers/roadAssistance'
import HowToClaim from '../../containers/howToClaim'
import BengkelPage from '../../containers/workshop/workshop'
import PackageDetails from '../../containers/packageDetail'
import TravelForms from '../../containers/tarvelForms'
import ReviewOrder from '../../containers/travelReviewOrder'
import PaymentMethods from '../../containers/paymentMethods'
import PaymentStatus from '../../containers/Payment-Status'
import CustomerSupportForm from '../../containers/CustomerSupportForm'
import WhistleBlowingForm from '../../containers/WhistleBlowing'

export const customerSupportroutes = [
  {
    path: 'road-assistance',
    element: <RoadAssistance />
  },
  {
    path: 'klaim',
    element: <HowToClaim />
  },
  {
    path: 'bengkel-rekanan',
    element: <BengkelPage/>
  },
  {
    path: 'hubungi-kami',
    element: <CustomerSupportForm />
  },
  {
    path: 'whistleblowing',
    element: <WhistleBlowingForm />
  }

].map((route) => ({ ...route, path: `/${route.path}` }))

export const userDataRoutes = [
  {
    path: 'produk-travel-sales-flow/package-details',
    element: <PackageDetails />
  },
  {
    path: 'produk-travel-sales-flow/insured-details',
    element: <TravelForms />
  },
  {
    path: 'produk-travel-sales-flow/review-order',
    element: <ReviewOrder />
  },
  {
    path: 'payment/payment-mode',
    element: <PaymentMethods />
  },
  {
    path: 'payment/payment-status',
    element: <PaymentStatus />
  }
]
