import FinanceReport from "../../containers/FinanceReport"

const customerSupportroutes = [
  {
    path: 'laporan-keuangan',
    element: <FinanceReport />
  },

].map((route) => ({ ...route, path: `/${route.path}` }))

export default customerSupportroutes