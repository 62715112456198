import { ImageUrl } from "../../Images";
import convertToIDR from "../utility/convertToIDR";

const Benefitdata  = (payload) => [
    {
        icon: ImageUrl.Tag,
        title: "Tipe Perjalanan",
        value: payload?.package_request?.trip_type ? GetTripType[payload?.package_request?.trip_type] : '-',
    },
    {
        icon: ImageUrl.Rocket,
        title: "Dari",
        value: payload?.package_request?.trip_start_from || '-',
    },
    {
        icon: ImageUrl.Location,
        title: "Tujuan",
        value: payload?.package_request?.destinations.join(', ') || '-',
    },
    {
        icon: ImageUrl.Date,
        title: "Mulai Perjalanan",
        value: payload?.package_request?.trip_start_date || '-',
    },
    {
        icon: ImageUrl.Date,
        title: "Akhir Perjalanan",
        value: payload?.package_request?.trip_end_date || '-',
    },
    {
        icon: ImageUrl.File,
        title: "Jenis Perlindungan",
        value: payload?.package_request?.product_type  === "family-A" || payload?.package_request?.product_type  === "family-B" ? GetProtectionType['Family'] : payload?.package_request?.product_type ? GetProtectionType[payload?.package_request?.product_type] : '-',
    },
    {
        icon: ImageUrl.Profile,
        title: "Jumlah Tertanggung",
        value: `${payload?.package_request?.no_of_insured_persons} wistawan`,
    },
    {
        icon: ImageUrl.Calendar,
        title: "Pengajuan Visa Schengen",
        value: payload?.package_request?.is_schengen ? 'Ya' : 'Tidak',
    },
]

const BenefitPackageData = (payload, draftData) => {
  const currPackage = localStorage.getItem('currPackage')
  const currPkg = payload && payload?.data?.packages?.filter(pkg => pkg.package_code === currPackage, [])
  const packageData = payload && payload?.data?.package_benefits?.filter(pack => Object.keys(pack)[0] === currPackage, [])
  const benefitdetailsData = packageData && packageData.length && Object.keys(packageData[0][currPackage]).map(item => {
    return {
      icon: packageData[0][currPackage][item].icon || '',
      title: packageData[0][currPackage][item].title || '',
      value: convertToIDR(packageData[0][currPackage][item].values.amount || '')
    }
  })
  
    return {
        packageDeatils: [
          {
            icon: ImageUrl.Sheild,
            title: "Paket Asuransi",
            value: draftData?.package_response?.package_name ?  draftData?.package_response?.package_name : currPkg ? currPkg[0] ? currPkg[0].package_name : '-' : '-',
          },
          {
            icon: ImageUrl.Bag,
            title: "Total Premi",
            value: convertToIDR(draftData?.package_response?.total_premium ? draftData?.package_response?.total_premium : currPkg ? currPkg[0] ? currPkg[0].total_premium : 0 : 0),
          },
        ],
        benefitDetails: benefitdetailsData,
      }
};

const GetProtectionType = {
  'individual': 'Individu',
  'group': 'Grup',
  'family': 'Keluarga',
  'Individual': 'Individu',
  'Group': 'Grup',
  'Family': 'Keluarga',
}

const GetTripType = {
  'domestic': 'Domestik',
  'international': 'Internasional',
  'Domestic': 'Domestik',
  'International': 'Internasional'
}

export {Benefitdata, BenefitPackageData, GetProtectionType, GetTripType}