import React, { useCallback, useEffect } from 'react'
import { ErrorMessage, useField } from 'formik'
import PropTypes from 'prop-types'

import {
  CounterInputContainer,
  CounterInputField,
  CounterInputFieldGroup,
  CounterInputButton,
  CounterInputButtonIcon,
  CounterInputFieldContainer,
  CounterInputFieldLabel,
  CounterInputFieldLabelRequired,
  CounterInputError,
  CounterInputErrorIcon,
  CounterInputErrorMsg,
  CounterInputRejectRemark
} from './elements'

const CounterInput = (props) => {
  const [field, meta, helpers] = useField(props)

  const onChange = useCallback((addVal) => {
    let newValue = Number(field.value)
    if (isNaN(newValue)) {
      newValue = 0
    }
    newValue += addVal

    if (newValue < props.minVal) {
      newValue = props.minVal
    }
    if (newValue > props.maxVal) {
      newValue = props.maxVal
    }
    helpers.setValue(newValue)
  }, [field.value])

  useEffect(() => {
    let newValue = Number(field.value)
    if (isNaN(newValue)) {
      newValue = 0
    }
    if (newValue < props.minVal) {
      newValue = props.minVal
      helpers.setValue(newValue)
    }
    if (newValue > props.maxVal) {
      newValue = props.maxVal
      helpers.setValue(newValue)
    }
  }, [field.value, props.minVal, props.maxVal])

  return (
    <CounterInputContainer>
      <CounterInputFieldContainer>
        <CounterInputFieldGroup>
          <CounterInputButton type='sub' onClick={() => onChange(-1)} disabled={field.value < props.minVal + 1}>-</CounterInputButton>
          <CounterInputField
            {...field}
            {...meta}
            id={`${props.name}~input`}
            error={(meta.touched && !!meta.error)}
            disabled={props.disabled}
            placeholder='Masukan Label'
          />
          <CounterInputButton type='add' onClick={() => onChange(1)} disabled={field.value > props.maxVal - 1}>+</CounterInputButton>
        </CounterInputFieldGroup>
      </CounterInputFieldContainer>
      <ErrorMessage
        name={props.name}
        render={msg => (
          <CounterInputError>
            <CounterInputErrorIcon
              src='/images/icons/form-item-error-icon.svg'
              width='12px'
              height='12px'
            />
            <CounterInputErrorMsg>
              {msg}
            </CounterInputErrorMsg>
          </CounterInputError>
        )}
      />
      {props.reject_remarks && props.status === 'invalid' && (
        <CounterInputRejectRemark>
          {/* <FormattedMessage
            id={props.reject_remarks}
            defaultMessage={props.reject_remarks}
          /> */}
        </CounterInputRejectRemark>
      )}
    </CounterInputContainer>
  )
}

CounterInput.propTypes = {
  label: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  minVal: PropTypes.number.isRequired,
  maxVal: PropTypes.number.isRequired
}

CounterInput.defaultProps = {
}

export default CounterInput
