import styled from 'styled-components'

export const ProductFAQContainer = styled.div`
`
export const EmptyFAQ = styled.div`
    padding: 24px;
    border-radius: 16px;
    background: #ffffff;
    font-size: 11px;
    font-weight: 600;
    line-height: 18px;
    letter-spacing: 0.02em;
    text-align: left;
`
export const OrderedList = styled.div`
    display: grid;
    grid-template-columns: 25% 70%;
    gap: 5%;
    text-align: left;
`
export const UnderlineText = styled.span`
    text-decoration: underline;
`