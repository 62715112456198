
import React, { useRef, useState } from "react";
import {
  TooltipWrapper,
  TooltipTarget,
  CenterContainer,
  TooltipBox
} from "./elements";

function Tooltip({ position, text, children, background, styleMe = false }) {
  const [isCliked, setIsClicked] = useState(false);
  const [isFocused, setIsFocused] = useState(false);
  const targetRef = useRef(null);
  const showTooltip = isCliked || isFocused;
  const containerRef = React.useRef();

  const handleClick = (e) => {
    e.preventDefault();
    setIsClicked(true)
    if (!isCliked) {
       document.addEventListener("click", handleOutsideClick, false);
    } else {
    document.removeEventListener("click", handleOutsideClick, false);
    }
      setIsClicked(!isCliked);
  };


  const handleOutsideClick = e => {
    if (containerRef?.current?.contains(e.target)) {
      return;
    }
    setIsClicked(false);
  };

  return (
    <TooltipWrapper ref={containerRef}>
      <TooltipTarget
        onFocus={() => setIsFocused(true)}
        onBlur={() => setIsFocused(false)}
        onClick={handleClick}
        ref={targetRef}
        styleMe={styleMe}
        showOnFocus={isFocused}
      >
        {children}
      </TooltipTarget>
      {showTooltip && (
        <CenterContainer position={position}>
          <TooltipBox background={background} position={position}>
            {text}
          </TooltipBox>
        </CenterContainer>
      )}
    </TooltipWrapper>
  );
}

export default Tooltip;
