import React from "react";
import { AnchorTag, DetailsSectionBoldText, DetailsSectionCtn, DetailsSectionHeader, DetailsSectionImageCtn, DetailsSectionText, ImageCtn } from "./elements";
import { FormattedMessage } from "react-intl";
import { ImageUrl } from "../../Images";

const DetailsSection = () => {

    return (
        <DetailsSectionCtn>
            <DetailsSectionHeader>
                <FormattedMessage 
                    id='Jangan Ragu untuk Menghubungi TAP Insure'
                    defaultMessage='Jangan Ragu untuk Menghubungi TAP Insure'
                />
            </DetailsSectionHeader>
            <DetailsSectionText>
                <FormattedMessage 
                    id='Isi formulir di samping untuk mendapatkan perlindunganmu'
                    defaultMessage='Isi formulir di samping untuk mendapatkan perlindunganmu'
                />
            </DetailsSectionText>
            <ImageCtn><img src={ImageUrl.CustomerSupport} width='115%' height='430px' alt='support'/></ImageCtn>
        </DetailsSectionCtn>
    )
}

export default DetailsSection