import React, { useRef, useState } from "react";
import * as Yup from "yup";
import {
  CaptchaErrorMsg,
  CaptchaWrapper,
  FormItem,
  FormSubmitBtn,
  FormSubmitBtnCtn,
  GridItem,
  SupportFormCtn,
  SupportFormWarpper,
  Title,
} from "./elements";
import FormHook from "../../components/FormComponents/FormHook";
import TextInputV3 from "../../components/FormComponents/InputTextV3";
import { Formik } from "formik";
import TextArea from "../../components/FormComponents/TextArea";
import { useSendEmail } from "../../lib/api";
import cogoToast from "cogo-toast";
import OptionValue from "../../components/FormComponents/OptionsValue";
import Modal from "../../components/Modal";
import TextInput from "../../components/FormComponents/InputText";
import DatePeriod from "../../components/DatePeriod";
import Upload from "../../components/Upload";
import WhistleblowingSend from "./formSend";
import ReCAPTCHA from "react-google-recaptcha";
import { FormattedMessage } from "react-intl";
import extractPhoneNo from "../../lib/utility/extractPhoneNo";

const siteKey = process.env.REACT_APP_SITE_KEY;

const INTIIAL_VALUES = {
  name: "",
  phone: "",
  email: "",
  type_of_action: "",
  reported_person: "",
  position: "",
  date_of_incident: "",
  location: "",
  chronology: "",
  attachments: "",
};

const FORM_SCHEMA = {
  name: Yup.string()
    .required("Nama Pelapor tidak boleh kosong")
    .max(100, "Nama Pelapor maksimal 100 karakter")
    .nullable(),
  phone: Yup.string()
    .required("Nomor Ponsel tidak boleh kosong")
    .min(10, "Nomor Ponsel miniaml 10 karakter")
    .max(20, "Nomor Ponsel maksimal 20 karakter")
    .nullable(),
  email: Yup.string()
    .required("Email tidak boleh kosong")
    .max(100, "Email maksimal 100 karakter")
    .matches(/^[^\s@]+@[^\s@]+\.[^\s@]+$/, "Masukkan Email yang valid")
    .nullable(),
  type_of_action: Yup.string()
    .required("Pilih Tindakan / Perbuatan yang Dilaporkan yang diinginkan")
    .nullable(),
  reported_person: Yup.string()
    .required("Nama Terlapor tidak boleh kosong")
    .max(100, "Nama Terlapor maksimal 100 karakter")
    .nullable(),
  position: Yup.string()
    .required("Jabatan Terlapor tidak boleh kosong")
    .max(100, "Jabatan Terlapor maksimal 100 karakter")
    .nullable(),
  date_of_incident: Yup.string()
    .required("Waktu Kejadian tidak boleh kosong")
    .nullable(),
  location: Yup.string()
    .required("Tempat Kejadian tidak boleh kosong")
    .max(100, "Tempat Kejadian maksimal 100 karakter")
    .nullable(),
  chronology: Yup.string()
    .required("Kronologi Kejadian tidak boleh kosong")
    .max(1000, "Kronologi Kejadian maksimal 1000 karakter")
    .nullable(),
};

const WhistleBlowingForm = () => {
  const [formValues, setFormValues] = useState(INTIIAL_VALUES);
  const [formSchema, setFormSchema] = useState(FORM_SCHEMA);
  const [dateModal, setDateModal] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [captchaError, setCaptchaError] = useState(false);
  const recaptcha = useRef();

  const { sendEmailApiCall, isLoading: sendEmailApidataLoading } = useSendEmail(
    {
      onSuccess: () => {
        setShowModal(true);
        setFormValues(INTIIAL_VALUES);
        recaptcha.current.forceReset();
      },
      onFailure: () => {
        cogoToast.error("Error", { position: "top-right" });
      },
    }
  );

  const handleChange = (values) => {
    setFormValues(values);
  };

  const dummySubmit = (_values, _formikbag) => {
    // dummy handle submit func
  };

  const handleSubmit = (formProps) => {
    const captchaValue = recaptcha.current.getValue();
    formProps.submitForm().then(() => {
      Yup.object(formSchema)
        .validate(formProps.values)
        .catch((err) => {
          console.error(err);
        })
        .then((values) => {
          if (!captchaValue) {
            setCaptchaError(true);
          } else {
            if (values) {
              const payload = {
                data: {
                  response_token: captchaValue,
                  form_data: {
                    ...values,
                    phone: extractPhoneNo(values.phone),
                    attachments: values.attachments ? [values.attachments] : [],
                  }
                }
              };
              sendEmailApiCall(payload);
            }
          }
        });
    });
  };

  const handleChangeCaptcha = () => {
    const captchaValue = recaptcha.current.getValue();
    if (captchaValue) {
      setCaptchaError(false);
    } else {
      setCaptchaError(true);
    }
  };

  return (
    <>
      <Formik
        onSubmit={dummySubmit}
        enableReinitialize
        initialValues={formValues}
        validationSchema={Yup.object(formSchema)}
      >
        {(formProps) => (
          <SupportFormWarpper>
            <SupportFormCtn>
              <FormHook values={formProps.values} onChange={handleChange} />
              <FormItem>
                <Title>Nama Pelapor</Title>
                <TextInputV3
                  name="name"
                  label="Nama Pelapor"
                  required
                  showIcon={false}
                  readonly={false}
                />
              </FormItem>
              <GridItem>
                <FormItem>
                  <Title>Nomor Ponsel</Title>
                  <TextInputV3
                    name="phone"
                    label="87811223344"
                    type="number"
                    required
                    showIcon
                    readonly={false}
                    prefix="+62"
                    icontype="phone"
                  />
                </FormItem>
                <FormItem>
                  <Title>Email</Title>
                  <TextInputV3
                    name="email"
                    type="email"
                    label="Masukkan email"
                    required
                    showIcon
                    readonly={false}
                    icontype="sms"
                  />
                </FormItem>
              </GridItem>
              <FormItem>
                <Title>Tindakan / Perbuatan yng dilaporkan</Title>
                <OptionValue
                  name="type_of_action"
                  optionsList={[
                    {
                      display_name: "Fraud",
                      value: "fraud",
                    },
                    {
                      display_name: "Pelanggaran Kode Etik",
                      value: "pelanggaran-kode-etik",
                    },
                    {
                      display_name: "Pelanggaran Benturan Kepentingan",
                      value: "pelanggaran-benturan-kepentingan",
                    },
                    {
                      display_name: "Pelanggaaran Hukum",
                      value: "pelanggaaran-hukum",
                    },
                  ]}
                  getter={(val) => val.value}
                  renderer={(val) => val.display_name}
                />
              </FormItem>
              <GridItem>
                <FormItem>
                  <Title>Nama Terlapor</Title>
                  <TextInputV3
                    name="reported_person"
                    label="Nama Terlapor"
                    required
                    showIcon={false}
                    readonly={false}
                  />
                </FormItem>
                <FormItem>
                  <Title>Jabatan Terlapor</Title>
                  <TextInputV3
                    name="position"
                    label="Jabatan Terlapor"
                    required
                    showIcon={false}
                    readonly={false}
                  />
                </FormItem>
              </GridItem>
              <GridItem>
                <FormItem>
                  <Title>Waktu Kejadian</Title>
                  <TextInput
                    name="date_of_incident"
                    label=""
                    showLabel={false}
                    placeholder="Waktu Kejadian"
                    inputValue={formProps?.values?.date_of_incident}
                    onClick={() => setDateModal(true)}
                    icontype="time"
                  />
                </FormItem>
                <Modal
                  title={"Waktu Kejadian"}
                  show={dateModal}
                  onClose={() => setDateModal(false)}
                  maxWidth="500px"
                >
                  <DatePeriod
                    name="date_of_incident"
                    label="Waktu Kejadian"
                    setFormValues={formProps.setValues}
                    formValues={formProps.values}
                    setDateModal={setDateModal}
                    startMaxDate={new Date()}
                  />
                </Modal>
                <FormItem>
                  <Title>Lokasi Kejadian</Title>
                  <TextInputV3
                    name="location"
                    label="Lokasi"
                    required
                    readonly={false}
                    showIcon={false}
                  />
                </FormItem>
              </GridItem>
              <FormItem>
                <Title>Kronologi Kejadian</Title>
                <TextArea
                  name="chronology"
                  label="Ceritakan tentang detail kejadian"
                  width="100%"
                  placeholder="Ceritakan tentang detail kejadian"
                  onChange={() => {}}
                />
              </FormItem>
              <FormItem>
                <Upload
                  name="attachments"
                  label="Upload File"
                  showLabel={false}
                  inputValue={formProps?.values?.attachments}
                  formValues={formProps.values}
                  setFormValues={formProps.setValues}
                />
              </FormItem>
            </SupportFormCtn>
            <FormSubmitBtnCtn>
              <CaptchaWrapper>
                <ReCAPTCHA
                  ref={recaptcha}
                  sitekey={siteKey}
                  onChange={handleChangeCaptcha}
                  hl='id'
                />
                {captchaError && (
                  <CaptchaErrorMsg>
                    <FormattedMessage
                      id="Captcha tidak boleh kosong"
                      defaultMessage="Captcha tidak boleh kosong"
                    />
                  </CaptchaErrorMsg>
                )}
              </CaptchaWrapper>
              <FormSubmitBtn
                onClick={() => handleSubmit(formProps)}
                id="travel-trip-detail-btn"
                loading={sendEmailApidataLoading}
                type="yellow"
              >
                Kirim
              </FormSubmitBtn>
            </FormSubmitBtnCtn>
          </SupportFormWarpper>
        )}
      </Formik>
      <Modal
        title={"Form Marketing"}
        show={showModal}
        onClose={() => setShowModal(false)}
        showHeader={false}
      >
        <WhistleblowingSend setShowModal={setShowModal} />
      </Modal>
    </>
  );
};

export default WhistleBlowingForm;
