// src/constants.js
export const strings = {
  mainHeading: "Perlindungan Utama Kamu, Prioritas Kami",
  claimButtonText: "Klaim disini",
  claimStepsHeading: "Langkah Mudah untuk Klaim Kamu",
  claimProcessDescription: "Klaim Tanpa Batas, Sesimpel",
  claimHashTag: '#TAPInAja',
  downloadCTA: "Unduh Sekarang dan Nikmati Klaim yang Mudah dan Cepat!",
  securityMessage: "Keamanan dan Ketenangan Pikiran Kamu adalah Prioritas Kami. Klaim Asuransi Kamu Sekarang!",
  downloadText: 'Unduh di',
  foundOn: 'TEMUKAN DI',
  appStore: "App Store",
  playStore: "Play Store",
  companyFooter: "© 2024 TAP Insure. Hak cipta dilindungi Undang-Undang.",
  companyDetails: "PT Ausuradi Untuk Semua merupakan Perusahaan Asuransi yang bertitan dan diawasi oleh Otoritas Jasa Keuangan.",
  companyLocation: "Gedung One Pacific Place Lantai 10 unit C Kawasan SCBD, Jl. Jend. Sudirman Kav. 52-53, Jakarta Selatan 12190",
  companyLicense: "Nomor lisensi OJK No. KEP-540/D.06/2022",
};

export const imagesLink = {
  appleIcon: 'https://storage.googleapis.com/pp_img/tap-travel-website/road-assistance/apple.svg',
  arrowDown: 'https://storage.googleapis.com/pp_img/tap-travel-website/road-assistance/arrow-down.svg',
  arrowLeft: 'https://storage.googleapis.com/pp_img/tap-travel-website/road-assistance/arrow-left.svg',
  arrowRight: 'https://storage.googleapis.com/pp_img/tap-travel-website/road-assistance/arrow-right.svg',
  bannerForeGround: 'https://storage.googleapis.com/pp_img/tap-travel-website/road-assistance/banner-foreground.svg',
  beachForeground: 'https://storage.googleapis.com/pp_img/tap-travel-website/road-assistance/beachForeground.svg',
  beachBackground: 'https://storage.googleapis.com/pp_img/tap-travel-website/road-assistance/beach-background.svg',
  claimIllustration: 'https://storage.googleapis.com/pp_img/tap-travel-website/road-assistance/claim-illustration.svg',
  familyClaimApply: 'https://storage.googleapis.com/pp_img/tap-travel-website/road-assistance/family_claim_apply.svg',
  howToClaimBackdropBanner: 'https://storage.googleapis.com/pp_img/tap-travel-website/road-assistance/how-to-claim-backdrop-banner.svg',
  indoMap: 'https://storage.googleapis.com/pp_img/tap-travel-website/road-assistance/indo-map.svg',
  claimIllustration1: 'https://storage.googleapis.com/pp_img/tap-travel-website/road-assistance/klaim%20illustration-03%201.svg',
  claimIllustration2: 'https://storage.googleapis.com/pp_img/tap-travel-website/road-assistance/klaim%20illustration-04%201.svg',
  locationIcon: 'https://storage.googleapis.com/pp_img/tap-travel-website/road-assistance/location-icon.svg',
  manCallingAfterAccident: 'https://storage.googleapis.com/pp_img/tap-travel-website/road-assistance/man-calling-after-accident.svg',
  manHoldingIpad: 'https://storage.googleapis.com/pp_img/tap-travel-website/road-assistance/man-holding-ipad.svg',
  manTryingToRepair: 'https://storage.googleapis.com/pp_img/tap-travel-website/road-assistance/man-trying-to-repair.svg',
  operationalUpdate: 'https://storage.googleapis.com/pp_img/tap-travel-website/road-assistance/operational_update.svg',
  playStoreIcon: 'https://storage.googleapis.com/pp_img/tap-travel-website/road-assistance/playstore-icon.svg',
  roadAssistanceBannerBackdrop: 'https://storage.googleapis.com/pp_img/tap-travel-website/road-assistance/raod-assistance-banner-backdrop.svg',
  roadAssistanceBannerImage: 'https://storage.googleapis.com/pp_img/tap-travel-website/road-assistance/raod-assistance-banner-image.svg',
  searchIcon: 'https://storage.googleapis.com/pp_img/tap-travel-website/road-assistance/search-icon.svg',
  towTruck: 'https://storage.googleapis.com/pp_img/tap-travel-website/road-assistance/tow-truck.svg',
  yellowLine: 'https://storage.googleapis.com/pp_img/tap-travel-website/road-assistance/yellow_line.svg',
  claimIllustrationPolicyList: 'https://storage.googleapis.com/pp_img/tap-travel-website/road-assistance/klaim%20illustration%20policy%20list.svg',
  claimIllustrationBenefitList: 'https://storage.googleapis.com/pp_img/tap-travel-website/road-assistance/klaim%20illustration-%20benefit%20list.svg'
}

export const steps = [
  {
    icon: imagesLink.claimIllustration, // fake URL for the icon image
    description: "<b> Buka Polis</b> di <b>Aplikasi TAP Insure</b>",
  },
  {
    icon: imagesLink.claimIllustrationPolicyList,
    description: "<b>Pilih Polis</b> yang Ingin <b>Diklaim</b>",
  },
  {
    icon: imagesLink.claimIllustration1,
    description: "Tentukan Manfaat Asuransi",
  },
  {
    icon: imagesLink.claimIllustration2,
    description: "<b>Isi Formulir & Unggah Dokumen Klaim</b>",
  },
  {
    icon: imagesLink.operationalUpdate,
    description: "<b>Tim Operasional</b> Kami Akan <b>Menilai Klaimmu</b>",
  },
];

