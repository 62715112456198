import React from 'react'
import { AboutUsContainer } from './AboutUs.style'
import Header from '../../components/Header'
import Footer from '../../components/Footer'
import { imagesLink } from './constant'
import ScrollToTop from '../../components/ScrollToTop'

const AboutUs = () => {
  return (
    <AboutUsContainer>
        <Header />
        <div className='hero-section'>
            <img src={imagesLink.heroImage}/>
        </div>
        <div className='content'>
            <div className='content-inner'>
                <p><strong>PT Asuransi Untuk Semua</strong> (selanjutnya disebut <strong>“TAP Insure”</strong>) didirikan dengan tujuan untuk dapat memberikan proteksi seluas-luasnya kepada seluruh lapisan masyarakat di Indonesia melalui beragam produk yang dirancang khusus sesuai kebutuhan yang melekat dalam keseharian masyarakat.</p>
                <p>Melalui teknologi terkini dan inovasi berkelanjutan, TAP Insure dengan <strong>izin usaha bernomor KEP-56/D.05/2022</strong> menyediakan produk asuransi kerugian, asuransi kecelakaan diri, asuransi kesehatan, asuransi kendaraan bermotor dan asuransi mikro berbasis teknologi, yang ditujukan baik untuk perorangan maupun korporasi melalui strategi peningkatan kolaborasi usaha dengan berbagai partner saluran distribusi.</p>
            </div>
        </div>
        <ScrollToTop />
        <Footer />
    </AboutUsContainer>
  )
}

export default AboutUs
