import { useState, useRef, useEffect } from 'react'
import PropTypes from 'prop-types'

import {
  CollapsibleContainer,
  CollapsibleTitle,
  CollapsibleIcon,
  CollapsibleHeader,
  CollapsibleContent,
  CollapsibleChildren,
  CollapsibleIconBlue
} from './elements'

const Collapsible = ({ onChange, title, isOpen, children, containerStyle = {}, headerStyle = {} }) => {
  const [height, setHeight] = useState(isOpen ? undefined : 0)
  const [isOpenState, setIsOpenState] = useState(isOpen)
  const content = useRef(null);

  useEffect(() => {
    if (isOpen) {
      setIsOpenState(true)
    } else {
      setIsOpenState(false)
    }
  }, [isOpen])

  useEffect(() => {
    if (isOpenState) {
      setHeight(content.current?.getBoundingClientRect().height)
    } else {
      setHeight(0)
    }
  }, [isOpenState])

  const handleChange = () => {
    if (onChange) {
      onChange()
    } else {
      setIsOpenState(!isOpenState)
    }
  }

  return (
    <CollapsibleContainer style={containerStyle}>
      <CollapsibleHeader onClick={handleChange} style={headerStyle}>
        <CollapsibleTitle style={containerStyle}>
          {title || 'collapse header'}
        </CollapsibleTitle>
        {containerStyle.dropdownIcon ? <CollapsibleIconBlue className={isOpenState ? 'open' : 'close'}/>
        : <CollapsibleIcon className={isOpenState ? 'open' : 'close'}/>}
      </CollapsibleHeader>
      <CollapsibleContent style={{ height }}>
        <CollapsibleChildren ref={content} style={containerStyle}>
          {children}
        </CollapsibleChildren>
      </CollapsibleContent>
    </CollapsibleContainer>
  )
}

Collapsible.propTypes = {
  active: PropTypes.string,
  onChange: PropTypes.func,
  title: PropTypes.string,
  isOpen: PropTypes.bool,
  children: PropTypes.node
}

export default Collapsible
