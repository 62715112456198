import ProductAuto from "../../containers/ProductAuto"
import ProductCare from "../../containers/ProductCare"
import ProductDevice from "../../containers/ProductDevice"
import ProductExtendedWarranty from "../../containers/ProductExtendedWarranty"
import ProductTravel from "../../containers/ProductTravel"
import PropertyPage from "../../containers/Property/PropertyPage"
import OtherProductsPage from "../../containers/OtherProducts/OtherProductsPage"

const productRoutes = [
  {
    path: 'produk-auto',
    element: <ProductAuto />
  },
  {
    path: 'produk-travel',
    element: <ProductTravel />
  },
  {
    path: 'produk-safe',
    element: <ProductCare />
  },
  {
    path: 'produk-device',
    element: <ProductDevice />
  },
  {
    path: 'produk-extended-warranty',
    element: <ProductExtendedWarranty />
  },
  {
    path: 'produk-property',
    element: <PropertyPage />
  },
  {
    path: 'produk-lainnya',
    element: <OtherProductsPage />
  }
].map((route) => ({ ...route, path: `/${route.path}` }))

export default productRoutes
