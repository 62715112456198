import React from 'react'
import Header from '../../components/Header'
import Footer from '../../components/Footer'
import { CareContainer } from './ProductCare.style'
import { imageLink, riplayDocUrl } from './constant'
import InquiryForm from '../userCriteria/inquiryForm'
import ScrollToTop from '../../components/ScrollToTop'
import { ImageUrl } from '../../Images'

const ProductCare = () => {
  return (
    <CareContainer>
        <Header />
        <div className='hero-container'>
            <img src={imageLink.safeText}/>
            <h1>Safe-in Hidupmu, <br />Aman Sampai Akhir!</h1>
            <p>Minum es teh di tengah taman, Udara segar membuat lega, <br/>Asuransi Safe Tap Insure berikan perlindungan, Untuk hidup yang lebih terjaga!</p>
            <div className='hero-div'>
                <div className='hero-div-inner'>
                    <div className='hero-div-items'>
                        <p>Perlindungan Mulai Dari</p>
                        <p className='amount'><span className='super'>Rp</span>56.000</p>
                    </div>
                </div>
            </div>
        </div>
        <div className='content'>
            <div className='content-inner'>
                <div className='tap-safe-sec2 section-container'>
                    <h1 className='section-title'>Mengapa Asuransi Safe dari TAP Insure?</h1>
                    <p className='section-subtitle'>Kenali apa yang membuat Asuransi Safe Tap Insure berbeda.</p>
                    <div className='tap-safe-sec2-group'>
                        <div className='group-items'>
                            <img src={imageLink.redShield}/>
                            <h3>Perlindungan Komprehensif</h3>
                            <p>Mencakup kecelakaan serta risiko pandemi COVID-19.</p>
                        </div>
                        <div className='group-items'>
                            <img src={imageLink.redShield}/>
                            <h3>Harga Terjangkau</h3>
                            <p>Mulai dari hanya Rp56.000, kamu bisa mendapatkan perlindungan yang dibutuhkan.</p>
                        </div>
                        <div className='group-items'>
                            <img src={imageLink.redShield}/>
                            <h3>Fleksibilitas</h3>
                            <p>Pilih periode perlindungan yang sesuai dengan kebutuhan kamu — 6 bulan atau 12 bulan.</p>
                        </div>
                    </div>
                    <img src={imageLink.section2Image}/>
                </div>
                <div className='tap-safe-sec3 section-container'>
                    <h1 className='section-title'>Yang Terlindungi</h1>
                    <p className='section-subtitle'>Asuransi Safe Tap Insure memberikan rasa aman dalam menjalani setiap fase kehidupan, apakah <br />kamu baru memulai perjalanan dewasa atau sedang menikmati masa-masa emas. Perlindungan <br />Tap Insure hadir untuk memberikan ketenangan pikiran. Dengan Asuransi Safe, kamu bisa fokus <br />pada yang terpenting, sementara Tap Insure menjaga sisanya.</p>
                    <img src={imageLink.section3Image}/>
                </div>
                <div className='tap-safe-sec4 section-container'>
                    <h1 className='section-title'>Menjamin Perjalananmu dari Apa Saja?</h1>
                    <p className='section-subtitle'>Produk ini melindungi kamu dari kerugian *akibat Kecelakaan:</p>
                    <div className='table-benefit'>
                        <div className='table-header'>
                            <h4>PLATINUM</h4>
                            <h4>GOLD</h4>
                            <h4>BASIC</h4>
                        </div>
                        <div className='table-item-container'>
                            <div className='table-items'>
                                <div className='benefit'>
                                    <img src={imageLink.RIP}/>
                                    Meninggal Dunia*
                                </div>
                            </div>
                            <div className='table-items'>
                                <div className='claim-amount'>
                                    Sampai dengan
                                    <h1>Rp100 jt</h1>
                                </div>
                            </div>
                            <div className='table-items'>
                                <div className='claim-amount'>
                                    Sampai dengan
                                    <h1>Rp50 jt</h1>
                                </div>
                            </div>
                            <div className='table-items'>
                                <div className='claim-amount'>
                                    Sampai dengan
                                    <h1>Rp30 jt</h1>
                                </div>
                            </div>
                            <div className='table-items'>
                                <div className='benefit'>
                                    <img src={imageLink.wheelChair}/>
                                    Cacat Tetap Total*
                                </div>
                            </div>
                            <div className='table-items'>
                                <div className='claim-amount'>
                                    Sampai dengan
                                    <h1>Rp100 jt</h1>
                                </div>
                            </div>
                            <div className='table-items'>
                                <div className='claim-amount'>
                                    Sampai dengan
                                    <h1>Rp50 jt</h1>
                                </div>
                            </div>
                            <div className='table-items'>
                                <div className='claim-amount'>
                                    Sampai dengan
                                    <h1>Rp30 jt</h1>
                                </div>
                            </div>
                            <div className='table-items'>
                                <div className='benefit'>
                                    <img src={imageLink.cructh}/>
                                    Cacat Tetap Sebagian*
                                </div>
                            </div>
                            <div className='table-items'>
                                <div className='claim-amount'>
                                    Sampai dengan
                                    <h1>Rp100 jt</h1>
                                </div>
                            </div>
                            <div className='table-items'>
                                <div className='claim-amount'>
                                    Sampai dengan
                                    <h1>Rp50 jt</h1>
                                </div>
                            </div>
                            <div className='table-items'>
                                <div className='claim-amount'>
                                    Sampai dengan
                                    <h1>Rp30 jt</h1>
                                </div>
                            </div>
                            <div className='table-items'>
                                <div className='benefit'>
                                    <img src={imageLink.medicalExpenses}/>
                                    Biaya Medis*
                                </div>
                            </div>
                            <div className='table-items'>
                                <div className='claim-amount'>
                                    Sampai dengan
                                    <h1>Rp10 jt</h1>
                                </div>
                            </div>
                            <div className='table-items'>
                                <div className='claim-amount'>
                                    Sampai dengan
                                    <h1>Rp5 jt</h1>
                                </div>
                            </div>
                            <div className='table-items'>
                                <div className='claim-amount'>
                                    Sampai dengan
                                    <h1>Rp3 jt</h1>
                                </div>
                            </div>
                            <div className='table-items'>
                                <div className='benefit'>
                                    <img src={imageLink.ambulance}/>
                                    Biaya Ambulans*
                                </div>
                            </div>
                            <div className='table-items'>
                                <div className='claim-amount'>
                                    Sampai dengan
                                    <h1>Rp2.5 jt</h1>
                                </div>
                            </div>
                            <div className='table-items'>
                                <div className='claim-amount'>
                                    Sampai dengan
                                    <h1>Rp2.5 jt</h1>
                                </div>
                            </div>
                            <div className='table-items'>
                                <div className='claim-amount'>
                                    Sampai dengan
                                    <h1>Rp2 jt</h1>
                                </div>
                            </div>
                            <div className='table-items'>
                                <div className='benefit'>
                                    <img src={imageLink.RIPWithShield}/>
                                    Santunan Pemakaman*
                                </div>
                            </div>
                            <div className='table-items'>
                                <div className='claim-amount'>
                                    Sampai dengan
                                    <h1>Rp3 jt</h1>
                                </div>
                            </div>
                            <div className='table-items'>
                                <div className='claim-amount'>
                                    Sampai dengan
                                    <h1>Rp2 jt</h1>
                                </div>
                            </div>
                            <div className='table-items'>
                                <div className='claim-amount'>
                                    Sampai dengan
                                    <h1>Rp1 jt</h1>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='tap-safe-sec5 section-container'>
                    <h1 className='section-title'>Penting!</h1>
                    <p className='section-subtitle'>Penting untuk diperhatikan bahwa klaim asuransi tidak akan diterima untuk kecelakaan yang disebabkan oleh:</p>
                    <div className='tap-safe-sec5-group'>
                        <div className='group-items'>
                            <img src={imageLink.climbing}/>
                            <p>Kegiatan seperti terbang sebagai non-penumpang, olahraga ekstrem, tindak kejahatan, pelanggaran hukum, kondisi kesehatan tertentu, atau infeksi penyakit.</p>
                        </div>
                        <div className='group-items'>
                            <img src={imageLink.war}/>
                            <p>Tugas dinas militer atau kepolisian, kerusuhan, kekerasan, dan situasi konflik atau perang.</p>
                        </div>
                        <div className='group-items'>
                            <img src={imageLink.nuclear}/>
                            <p>Reaksi inti atom atau nuklir.</p>
                        </div>
                        <div className='group-items'>
                            <img src={imageLink.ribbon}/>
                            <p>Biaya pencegahan kerugian, tindakan yang disengaja, dan pengobatan terkait infeksi HIV/AIDS.</p>
                        </div>
                    </div>
                    <p className='section-footer'>Untuk kelancaran klaim, pastikan kecelakaan yang kamu alami tidak termasuk dalam <br />pengecualian di atas. Tap Insure akan memprosesnya sesuai ketentuan polis yang berlaku.</p>
                </div>
                <div className='tap-safe-sec6 section-container'>
                    <h1 className='section-title'>Mau Tau Lebih Lanjut?</h1>
                    <p className='section-subtitle'>Cek info lengkap Asuransi Safe Tap Insure sekarang!</p>
                    <div className='flex-ctn'>
                        <a href={riplayDocUrl} target='_blank'>
                            <button className="btn-safe">
                                Unduh Informasi Produk
                            </button>
                            <button className="btn-safe-circle"><img src={ImageUrl.DownloadWhite} width='17px' height='17px' alt='download'/></button>
                        </a>
                    </div>
                </div>
                <div className='tap-safe-sec7 section-container'>
                    <h1 className='section-title'>Dapatkan Perlindunganmu disini!</h1>
                    <p className='section-subtitle'>Lindungi perjalanan kamu hanya dengan beberapa ketukan.</p>
                    <div className='sales-form'>
                        <InquiryForm product={"Asuransi Safe"}/>
                    </div>
                </div>
                <div className='tap-safe-sec8 section-container'>
                    <h1 className='section-title'>Saat Hidup Membawa Kejutan, <br />TAP Insure Siap Memberikan Perlindungan.</h1>
                    <p className='section-subtitle'>Dengan asuransi Care, kamu bisa fokus menjalani aktivitas tanpa khawatir. Dari kejadian <br />kecil hingga yang besar, TAP Insure selalu siap memberikan perlindungan bagi kamu dan <br />kesejahteraanmu. Karena diri kamu, preoritas utama dalam perlindungan.</p>
                    <img src={imageLink.section8Image}/>
                </div>
            </div>
        </div>
        <ScrollToTop />
        <Footer />
    </CareContainer>
  )
}

export default ProductCare
