import styled from 'styled-components';

export const TermsConditionContainer = styled.div`
    @import url('https://fonts.googleapis.com/css2?family=Mulish:ital,wght@0,200..1000;1,200..1000&display=swap');
    font-family: "Mulish", sans-serif;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 48px;

    * {
        padding: 0;
        margin: 0;
    }

    .content {
        width: 100%;  
        background: linear-gradient(180deg, rgba(217,217,217,1) 0%, rgba(255,255,255,1) 80%);
        margin-bottom: 50px;
    }

    .hero-section {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        padding: 32px;
        width: 100%;               
    }

    .hero-title {
        text-align: center;
        font-size: 56px;
        max-width: 858px;
        word-break: break-word;
    }

    .content-inner {
        width: 100%;
        max-width: 1000px;         
        margin: 0 auto;            
        text-align: justify;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        padding: 32px;
        line-height: 32px;
        gap: 40px;        
    }

    .content-inner h1, .content-inner h2, .content-inner ol {
        width: 100%;
        text-align: left;
    }

    .content-inner h2 {
        font-size: 40px;
        line-height: 48px;
        font-weight: 800;
    }

    .content-inner ol {
        text-align: justify;
    }

    .content-inner ol li > a {
        color: #000;
    }

    ol {
        padding-left: 20px;
    }

    /* Mobile-First Styling */
    @media (max-width: 576px) {
        margin-top: 50px;
        padding: 16px;

        .hero-title {
            font-size: 24px; /* Reduce title size for mobile */
            padding: 0 8px;
        }

        .content {
            margin-bottom: 24px;
            width: 100vw;
            padding: 16px;
        }

        margin-top: 40px;
        margin-bottom: 16px;



        .content-inner {
            width: 80%;            
            max-width: 100%;        
            margin: 0 auto;         
            padding: 12px;          
            line-height: 26px;
            text-align: justify;
        }

        .content-inner h2 {
            font-size: 20px; 
        }

        .content-inner ol {
            padding-left: 16px; 
            font-size: 16px;    
        }

        .content-inner h1, .content-inner h2 {
            text-align: left; /* Ensure text is left-aligned on smaller screens */
        }
    }

    /* Tablet Styling */
    @media (max-width: 768px) {
        .hero-title {
            font-size: 32px; /* Medium font size for tablets */
        }

        .content-inner {
            padding: 24px;   /* Adjust padding for tablets */
            max-width: 600px; /* Narrow the max width for better readability */
        }

        .content-inner h2 {
            font-size: 28px;  /* Adjust heading size for tablets */
        }

        .content-inner ol {
            padding-left: 18px; /* Adjust list padding */
        }
    }

    /* Larger Screens */
    @media (min-width: 1200px) {
        .hero-title {
            font-size: 48px; /* Larger font size for bigger screens */
        }

        .content-inner {
            padding: 72px 32px 32px 32px;
            max-width: 800px; /* Ensure better readability on larger screens */
        }
    }
`;
