export const imagesLink = {
  PropertyHeading: 'https://storage.googleapis.com/pp_img/tap-travel-website/Product-Property/propertyheading.png',
  PropertyFireWhy: 'https://storage.googleapis.com/pp_img/tap-travel-website/Product-Property/property_why_image.png',
  PropertyFireBenefit: 'https://storage.googleapis.com/pp_img/tap-travel-website/Product-Property/property_benefit_list.png',
  PropertyFireInfo: 'https://storage.googleapis.com/pp_img/tap-travel-website/Product-Property/property_coverage.png',
  PropertyFireOthers: 'https://storage.googleapis.com/pp_img/tap-travel-website/Product-Property/property_other_benefits.png',
  PropertyRiskWhy: 'https://storage.googleapis.com/pp_img/tap-travel-website/Product-Property/property_risks_info.png',
  PropertyRiskBenefit: 'https://storage.googleapis.com/pp_img/tap-travel-website/Product-Property/property_risks_benefits.png',
  PropertyMoreInfo: 'https://storage.googleapis.com/pp_img/tap-travel-website/Product-Property/more_info.png',
  PropertyAllBenefit: 'https://storage.googleapis.com/pp_img/tap-travel-website/Other-Product/shipping_banner.png',
  PropertyLogo: 'https://storage.googleapis.com/pp_img/tap-travel-website/Product-Property/property_logo.png',
  PropertyBannerHeading: 'https://storage.googleapis.com/pp_img/tap-travel-website/Product-Property/property_banner_header.png',
  PropertyFooterImage: 'https://storage.googleapis.com/pp_img/tap-travel-website/Product-Property/property-banner.png'
}

export const PROPERTY_TYPE = [
  {
    key: 'fire',
    activeIcon: 'https://storage.googleapis.com/pp_img/tap-travel-website/Product-Property/property_fire.png',
    label: 'Property Fire',
    productName:'fire',
  },
  {
    key: 'risk',
    activeIcon: 'https://storage.googleapis.com/pp_img/tap-travel-website/Product-Property/property_all_risk.png',
    label: 'Property All Risk',
    productName:'risk',
  }
]

export const riplayDocUrl = {
  risk: 'https://storage.googleapis.com/pp_img/tap-travel-website/RIPLAY%20Docs/Property%20-%20All%20Risk.pdf',
  fire: 'https://storage.googleapis.com/pp_img/tap-travel-website/RIPLAY%20Docs/Property%20-%20Fire.pdf'
}