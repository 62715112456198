import styled from 'styled-components';

export const HomePageContainer = styled.div`
    @import url('https://fonts.googleapis.com/css2?family=Mulish:ital,wght@0,200..1000;1,200..1000&display=swap');
    font-family: "Mulish", sans-serif;

    // * {
    //     margin: 0;
    //     padding: 0;
    // }

    background-image: linear-gradient(to bottom, #dbdbdb, #ffffff);
    
    .hero-section {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        padding: 32px;

        .banner-asset {
            width: 500px;
        }

        img {
            max-width: 1200px;
            width: 100%;
        }

        h1 {
            font-weight: 400;
            font-size: 40px;
            margin-bottom: 32px;
        }

        .hero-items {
            text-align: left;
            word-break: break-word;
            max-width: 280px;
            position: relative;
        }

        .hero-items h2 {
            font-weight: 800;
        }

        strong {
            font-weight: 800;
        }

        .hero-items:first-of-type {
            bottom: 500px;
            left: 430px;
        }

        .hero-items:nth-of-type(2) {
            bottom: 470px;
            right: 430px;
        }

        .hero-items:last-of-type {
            bottom: 500px;
            left: 400px;
        }

        .hero-subtitle {
            text-align: center;
            margin-top: 0;
            line-height: 24px;
        }
    }

    .product {
        margin-top: -250px;
        h1 {
            text-align: center;
            margin-bottom: 24px;
            font-weight: 800;
        }
    }

    .banner-footer {
        width: 800px !important;
        margin-top: 32px;
    }

    @media (max-width: 1200px) {
        .hero-section img {
            max-width: 1000px;
            width: 100%;
        }
    }

    @media (max-width: 992px) {
        .hero-section img {
            max-width: 800px;
        }

        .product h1 {
            font-size: 24px;
        }
    }

    @media (max-width: 768px) {
        .hero-section img {
            max-width: 600px;
        }

        .product h1 {
            font-size: 22px;
            margin-bottom: 20px;
        }

        .hero-section {
            padding: 24px;
        }
    }

    @media (max-width: 576px) {
        .hero-section img {
            max-width: 400px;
        }

        .product h1 {
            font-size: 20px;
            margin-bottom: 16px;
        }

        .hero-section {
            padding: 16px;
        }
    }
`;

export const DownloadButtons = styled.div`
  display: flex;
  justify-content: center;
  gap: 30px;
  margin-top: 20px;
`;

export const DownalodIcon = styled.img`
  width: 24.199px;
  height: 29.748px;
  `

export const DownloadButton = styled.a`
  padding: 10px 20px;
  font-size: 1em;
  border-radius: 20px;
  text-decoration: none;
  color: #000;
  background-color: #FAF000;
  align-items: center;
  display: flex;
  font-weight: 800;
  gap: 14px;
  cursor: pointer;
  transition: background-color 0.5s ease;
  
  img {
    width: 24px !important;
  }

  .download-text {
    font-size: 12px;
    text-align: left;
    font-weight: 600;
    margin: 0;
  }

  p {
    margin: 0;
  }

  &:hover {
    background-color: #d9d004;
  }
`;
