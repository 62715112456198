import styled from "styled-components";
import LargeButton from "../../components/LargeButton";

export const ReviewOrderWrapper = styled.div`
  position: relative;
  background: url("https://storage.googleapis.com/pp_img/tap-travel-website/common/Bottom-Vector.svg");
  background-repeat: no-repeat;
  background-size: auto;
  background-color: #f5f5f5;
  background-position: bottom;
`;
export const ReviewOrderCtn = styled.div`
  width: 100%;
  min-height: 70vh;
`;
export const ReviewOrderdDetailsCtn = styled.div`
  width: 65%;
  display: grid;
  grid-template-columns: 65% 32%;
  gap: 3%;
  margin: 40px auto;

  @media screen and (max-width: 720px) {
    width: 92%;
    display: flex;
    flex-direction: column;
    gap: 30px;
  }
`;
export const FlexColumnCtn = styled.div`
  display: flex;
  flex-direction: column;
`;
export const ReviewOrderDetailCtn = styled.div`
  display: flex;
  flex-direction: column;
  gap: 30px;
`;
export const ReviewOrderDetailWrapper = styled.div`
  padding: 48px;
  border-radius: 32px;
  background: #ffffff;
  display: flex;
  flex-direction: column;
  gap: 16px;
  height: fit-content;

  @media screen and (max-width: 720px) {
    padding: 24px;
  }
`;
export const BenefitDetailsCtn = styled.div`
  display: grid;
  grid-template-columns: 15% 83%;
  gap: 2%;
  margin: 10px 0px;
`;
export const Benefitdescription = styled.div`
  font-family: Mulish;
  font-size: 14px;
  font-weight: 700;
  line-height: 22px;
  text-align: left;
  color: #333333;
  word-wrap: break-word;
`;
export const BenefiitTitle = styled.div`
  font-family: Mulish;
  font-size: 12px;
  font-weight: 600;
  line-height: 16px;
  text-align: left;
  color: #999999;
`;
export const GridContainer = styled.div`
  display: grid;
  grid-template-columns: 49% 49%;
  gap: 2%;

  @media screen and (max-width: 720px) {
    display: flex;
    flex-direction: column;
    gap: 10px;
  }
`;
export const CardTitle = styled.div`
  font-family: Mulish;
  font-size: 16px;
  font-weight: 700;
  line-height: 22px;
  text-align: left;
  color: #b43cfa;
  margin-top: 20px;

  @media screen and (max-width: 720px) {
    font-size: 12px;
    line-height: 16px;
  }
`;
export const BenefitDetailSubmitBtn = styled.div`
  padding: 12px 25px 12px 25px;
  border-radius: 8px;
  border: 2px solid #b43cfa;
  font-size: 14px;
  font-family: Mulish;
  font-weight: 700;
  line-height: 16px;
  text-align: center;
  color: #b43cfa;
  margin-top: 10px;
  width: fit-content;
  cursor: pointer;
  transition: background 0.5s ease-out;

  &:hover {
    background: #b43cfa;
    color: #ffffff;
  }
`;
export const TravelHolderDetails = styled.div`
  padding: 48px;
  border-radius: 32px;
  background: #ffffff;
  height: fit-content;
  margin-top: 30px;

  @media screen and (max-width: 720px) {
    padding: 24px;
  }
`;
export const CardDeatilCtn = styled.div`
  border-bottom: 1px solid #eeeeee;
  padding: 10px 10px 0px 10px;

   &:last-child {
    border-bottom: none;
  }
`;
export const CardDeatilTitle = styled.div`
  font-family: Mulish;
  font-size: 14px;
  font-weight: 700;
  line-height: 18px;
  text-align: left;
  margin: 10px 0px;
`;
export const PaymentBreakUpContainer = styled.div`
  font-family: "Mulish";
  font-style: normal;
  font-weight: 600;
  font-size: 10px;
  line-height: 12px;
  letter-spacing: 0.02em;
  margin-top: 10px;
`;
export const AmountNameContainer = styled.div`
  color: ${(props) => (props.color ? props.color : "#333333")};
  font-weight: ${(props) => props.weight || "600"};
  display: flex;
  align-items: center;
  font-size: 12px;
  font-family: Mulish;
`;
export const AmountRowContainer = styled.div`
  background-color: #ffffff;
  display: flex;
  padding: 12px 0px;
  justify-content: space-between;
  &:last-child {
    border: 0;
  }
`;
export const GroupPackageAmountCtn = styled.div`
  color: ${(props) => (props.color ? props.color : "#333333")};
  font-weight: ${(props) => props.weight || "600"};
  display: flex;
  flex-direction: column;
  font-size: 12px;
  font-family: Mulish;
`;
export const GroupPackageInsured = styled.div`
  font-family: Mulish;
  font-size: 10px;
  font-weight: ${(props) => props.weight || "700"};
  line-height: 12px;
  letter-spacing: 0.04em;
  text-align: left;
  color: ${(props) => (props.color ? props.color : "#333333")};
`;
export const PromoTitleCtn = styled.div`
  font-family: Mulish;
  font-size: 10px;
  font-weight: 800;
  line-height: 14px;
  letter-spacing: 0.02em;
  display: flex;
  justify-content: space-between;
`;
export const PromoContentCtn = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  font-size: 10px;
  font-family: Mulish;
  font-weight: 600;
  line-height: 14px;
  letter-spacing: 0.02em;
  text-align: left;
  padding: 4px 0px;
  color: #999999;
  border-bottom: 1px solid #dddddd;
`;
export const PromoDisclaimer = styled.div`
  font-family: Mulish;
  font-size: 8px;
  font-weight: 700;
  line-height: 12px;
  letter-spacing: 0.04em;
  text-align: left;
  padding: 0px 0px 10px 12px;
  color: #999999;
`;
export const PromoValues = styled.div`
  font-family: Mulish;
  font-size: 10px;
  font-weight: 500;
  line-height: 14px;
  letter-spacing: 0.02em;
  text-align: right;
  color: #999999;
  display: flex;
`;
export const PromoDiscountValue = styled.span`
  color: #999999;
`;
export const RedAsterisk = styled.span`
  color: #999999;
  font-size: 12px;
  font-family: Mulish;
`;
export const PaymentDetailsCtn = styled.div`
  border-radius: 16px;
  padding: 48px;
  background: #ffffff;
  height: fit-content;

  @media screen and (max-width: 720px) {
    padding: 24px;
  }
`;
export const BlackHr = styled.div`
  width: 100%;
  height: 2px;
  background: #333333;
`;
export const PromoInputCtn = styled.div`
  padding: 20px;
  gap: 0px;
  border-radius: 12px;
  border: 2px solid ${(props) => (props.isError ? "#DC0000" : "#EEEEEE")};
  margin: 12px 0px;
  display: flex;
  background: ${(props) => (props.isDisabled ? "#EEEEEE" : "#FFFFFF")};
`;
export const PromoInput = styled.input`
  font-family: Mulish;
  font-weight: 700;
  line-height: 20px;
  text-align: left;
  outline: none;
  border-radius: 4px;
  border: none;
  font-size: 16px;
  font-family: Mulish;
  background: ${(props) => (props.disabled ? "#EEEEEE" : "#ffffff")};
  outline: none;
  width: 90%;

  :disabled {
    ${(props) =>
      props.isEmpty &&
      `
      -webkit-text-fill-color: #f5f5f5f;
    `}
    ${(props) =>
      !props.isEmpty &&
      `
      -webkit-text-fill-color: #f5f5f5;
      opacity: 1;
    `}
  }
`;
export const SumitButton = styled(LargeButton)`
  width: 100%;
`;
export const PromoErrorText = styled.p`
  font-family: Mulish;
  font-size: 14px;
  font-weight: 500;
  line-height: 18px;
  text-align: left;
  color: #dc0000;
`;
export const HeaderText = styled.p`
  font-family: Mulish;
  font-size: 18px;
  font-weight: 800;
  line-height: 22px;
  text-align: center;
  margin-top: 32px;

  @media screen and (max-width: 720px) {
    font-size: 12px;
    line-height: 16px;
    padding: 0px 40px;
  }
`;
export const SecondHeaderText = styled.p`
  font-family: Mulish;
  font-size: 16px;
  font-weight: 400;
  line-height: 20px;
  text-align: center;

  @media screen and (max-width: 720px) {
    font-size: 10px;
    line-height: 14px;
    padding: 0px 40px;
  }
`;
export const PoliciesInfoCtn = styled.div`
  padding: 24px 32px 24px 32px;
`;
export const FooterTnCCtn = styled.div`
  width: 90%;
  display: grid;
  grid-template-columns: 60% 40%;
  padding: 22px 32px 22px 32px;
  gap: 0px;
  justify-content: space-between;
  background: #ffffff;
`;
export const FooterCheckCtn = styled.div`
  display: flex;
  gap: 10px;
  align-items: center;
`;
export const FooterText = styled.div`
  font-family: Mulish;
  font-size: 12px;
  font-weight: 800;
  line-height: 18px;
  text-align: left;
`;
export const TnCAcceptBtn = styled(LargeButton)`
  margin: inherit;
  min-width: 200px;
`;

export const LargeButtonSpinner = styled.div`
  border: 3px solid #B43CFA; 
  border-top: 3px solid #ffffff;
  border-radius: 50%;
  width: 14px;
  height: 14px;
  animation: spin 2s linear infinite;
  margin-left: 8px;

  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }
`
