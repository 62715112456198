import React, { useState } from 'react';
import { imagesLink as commonImage } from '../../../constants/imageLink';
import {
  DropdownWrapper,
  DropdownHeader,
  DropdownList,
  DropdownListItem,
  Icon,
  PlaceholderIcon
} from './Dropdown.style';

const Dropdown = ({ options, placeholder, isDisabled = false, placeholderIcon, onSelect }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [selected, setSelected] = useState(null);

  const toggleDropdown = () => {
    if (!isDisabled) {
      setIsOpen(!isOpen);
    }
  };
  const handleSelect = (option) => {
    setSelected(option);
    setIsOpen(false);
    onSelect(option);
  };

  return (
    <DropdownWrapper>
      <DropdownHeader onClick={toggleDropdown} isDisabled={isDisabled}>
        <div className='placeholder-text'> 
            {selected === null && <PlaceholderIcon src={placeholderIcon}/>}
            {selected || placeholder}
        </div>
        <Icon src={commonImage.arrowDown} isOpen={isOpen} isDisabled={isDisabled}/>
      </DropdownHeader>
      {isOpen && (
        <DropdownList>
          {options.map((option, index) => (
            <DropdownListItem key={index} onClick={() => handleSelect(option)} isDisabled={isDisabled}>
              {option}
            </DropdownListItem>
          ))}
        </DropdownList>
      )}
    </DropdownWrapper>
  );
};

export default Dropdown;
