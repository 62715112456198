export const imagesLink = {
    heroImage: 'https://storage.googleapis.com/pp_img/tap-travel-website/Abiout%20Us/Hero%20Image%20Struktur%20Organisasi.png',
    edySetiadiBtn: 'https://storage.googleapis.com/pp_img/tap-travel-website/Abiout%20Us/edy.png',
    yasrilRasyidBtn: 'https://storage.googleapis.com/pp_img/tap-travel-website/Abiout%20Us/yasril.png',
    michaelAlvinBtn: 'https://storage.googleapis.com/pp_img/tap-travel-website/Abiout%20Us/mike.png',
    cleosentRandingBtn: 'https://storage.googleapis.com/pp_img/tap-travel-website/Abiout%20Us/cleo.png',
    suhermanDarmawanBtn: 'https://storage.googleapis.com/pp_img/tap-travel-website/Abiout%20Us/pak%20su.png',
    makkiIbrahimBtn: 'https://storage.googleapis.com/pp_img/tap-travel-website/Abiout%20Us/makki.png',
    organizationStructure: 'https://storage.googleapis.com/pp_img/tap-travel-website/Abiout%20Us/Organization%20Structure%202.png',
    commissionerGraph: 'https://storage.googleapis.com/pp_img/tap-travel-website/Abiout%20Us/Komite%20di%20bawah%20dewan%20komisaris%202.png',
    directionGraph: 'https://storage.googleapis.com/pp_img/tap-travel-website/Abiout%20Us/Komite%20di%20bawah%20direksi%202.png'
}