import styled from "styled-components";

export const OptionsWrappper = styled.div`
  display: grid;
  width: 100%;
  grid-template-columns: repeat(${(props) => props.length}, 1fr);
  gap: 2%;
`;
export const OptionBtn = styled.div`
  padding: 16px 16px 16px 16px;
  gap: 8px;
  border-radius: 16px;
  font-family: Mulish;
  font-size: 15px;
  font-weight: 700;
  line-height: 25px;
  text-align: center;
  cursor: pointer;
  background: ${(props) => (props.isSelected ? "#FAFA96" : "#ffffff")};
  color: ${(props) => (props.isSelected ? "#333333" : "#999999")};
  border: ${(props) =>
    props.isSelected ? "2px solid #FAFA96" : "2px solid #EEEEEE"};

  &:hover {
    background: #FAFA96;
    color: #333333;
    box-shadow: 3px 2px 22px 1px rgba(0, 0, 0, 0.10); 
  }
`;

export const PromptError = styled.div`
  width: 100%;
  margin: 8px 0px;
  display: flex;
  gap: 1%;
`;
export const PromptErrorMsg = styled.div`
  display: inline;
  margin-left: 4px;
  font-family: "Mulish";
  font-weight: 700;
  font-size: 0.7rem;
  line-height: 1rem;
  letter-spacing: 0.02em;
  color: #DC0000;
`;
export const OptionstCtn = styled.div`
  width: 100%;
`;
