import React from 'react'
import PropTypes from 'prop-types'
import {
  CheckboxContainer,
  CheckboxLabel,
  CheckboxInput
} from './elements'

const Checkbox = (props) => {
  return (
    <CheckboxContainer name={props.name}>
      <CheckboxInput {...props} id={props.name} />
      <CheckboxLabel checked={props.checked} htmlFor={props.name} disabled={props.disabled} color={props.color} borderColor={props.borderColor}/>
    </CheckboxContainer>
  )
}

Checkbox.defaultProps = {
  name: 'name',
  disabled: false,
  checked: false,
  onChange: () => {}
}

Checkbox.propTypes = {
  name: PropTypes.string,
  disabled: PropTypes.bool,
  checked: PropTypes.bool,
  onChange: PropTypes.func
}

export default Checkbox
