import { useMemo } from 'react'
import PropTypes from 'prop-types'
import { ProductFAQContainer, CollapsibleCtn } from './elements'
import TermsContent from '../AppTerms/TermsContent'
import Collapsible from '../Collapsible'
import { ImageUrl } from '../../Images'

const style = {
  borderRadius: '16px',
  fontSize: '1rem',
  childPadding: '16px',
  haveBorder: true,
  dropdownIcon: true
}

const ProductFAQ = (props) => {
  const memoizedData = useMemo(() => props.getter(props.data), [props.getter, props.data])

  return (
    <ProductFAQContainer>
      {(Array.isArray(memoizedData) && memoizedData.length) ? 
        memoizedData.map(c => (
          <>
            {c?.sub_categories?.map(sb => {
              return sb.questions.map(q => {
                return (
                    <Collapsible id={q.title} key={q.title} title={q.title} containerStyle={style}>
                      <TermsContent termsData={q} getter={x => x.content} noDescriptionPadding={true}/>
                    </Collapsible>
                )
              })}
            )}
          </>
        ))
      : null}
    </ProductFAQContainer>
  )
}

ProductFAQ.propTypes = {
  getter: PropTypes.func
}

export default ProductFAQ