import styled from 'styled-components'

export const TermsContentContainer = styled.div`
  margin: 0px auto 0px auto;
  overflow-y: scroll;
`

export const TermsOrderedList = styled.ol`
  list-style: ${props => props.listStyle ? props.listStyle : 'decimal'};
  font-family: 'Mulish';
  font-weight: 400;
  font-size: 0.9rem;
  line-height: 2rem;
  color: #333333;
  padding: ${props => props.noDescriptionPadding ? '0px' : '0px 10px'};
`

export const TermsUnorderedList = styled.ul`
  list-style: ${props => props.listStyle ? props.listStyle : 'decimal'};
  font-family: 'Mulish';
  font-weight: 400;
  font-size: 0.9rem;
  line-height: 2rem;
  color: #333333;
  padding: ${props => props.noDescriptionPadding ? '0px' : '0px 10px'};
`

export const TermsListItem = styled.li`
  font-family: 'Mulish';
  font-weight: 400;
  font-size: 0.9rem;
  line-height: 2rem;
  color: #333333;

  &:first-child {
    counter-reset: index;
  }

  ${props => props.circleBackground && (
    `
      list-style: none;
      padding-left: 20px;

      &:before {
        background-color: #ffffff;
        margin-left: -20px;
        margin-right: 3px;
        border-radius: 50%;
        padding: 0 5px;
        content: counter(index,decimal);
        counter-increment: index;
      }
    `
  )}

`

export const TermsListItemDescription = styled.p`
  display: inline;
`

export const TermsListItemSubDescription = styled.p`
`

export const TermsH2 = styled.div`
  font-family: 'Mulish';
  font-weight: 600;
  font-size: 1.1rem;
  line-height: 2rem;
  color: #333333;
`

export const TermsP = styled.div`
  font-family: 'Mulish';
  font-weight: 400;
  font-size: 0.9rem;
  line-height: 2rem;
  color: #333333;
`

export const TermsB = styled.b`
  font-family: 'Mulish';
  font-weight: 800;
  font-size: 0.9rem;
  line-height: 2rem;
  color: #333333;
`

export const TermsA = styled.a`
  font-family: 'Mulish';
  font-weight: 800;
  font-size: 0.9rem;
  line-height: 2rem;
  color: #333333;
`

export const TermsI = styled.i`
  font-family: 'Mulish';
  font-weight: 400;
  font-size: 0.9rem;
  line-height: 2rem;
  font-style: italic;

  color: #333333;
`
export const FAQTitle = styled.div`
  font-family: 'Mulish';
  font-weight: 800;
  font-size: 0.9rem;
  line-height: 2rem;
  color: #333333;
`
export const FAQSubTitle = styled.div`
  font-family: 'Mulish';
  font-weight: 800;
  font-size: 0.9rem;
  line-height: 2rem;
  color: #333333;
`

export const TermsU = styled.u`
  font-family: 'Mulish';
  font-weight: 400;
  font-size: 0.9rem;
  line-height: 2rem;
  font-style: italic;

  color: #333333;
`
export const DescriptionContainer = styled.div`
  background: #ffffff;
  border-radius: 16px;
`
export const Description = styled.div`
  display: flex;
  padding: ${props => props.noDescriptionPadding ? '0px' :  props.havePaddng ? '0px 0px 0px 10px' : '10px'};
`