import {
    GENDER_MAP,
    RELATIONSHIP_MAP
  } from "../../constants/travel";
import { ImageUrl } from "../../Images";
  
  const insuredCardProcessor = (insuredPayload) => {
    const citizenship = !insuredPayload.citizenship ? 'Paspor' : insuredPayload.citizenship === 'Indonesian' ? 'WNI' : 'WNA'
    const idNo = insuredPayload.passport_no || insuredPayload.kitas_kitap || insuredPayload.ktp
  
    const finalPayloadDomestic = {
      'Detail Personal': [
          {
              title: `Nama Lengkap Sesuai ${
                citizenship === "WNI" ? "KTP" : "KITAS/KITAP"
              }`,
              content: insuredPayload?.name,
              span: 1,
              icon: ImageUrl.Profile
          },
          {
            title: "Jenis Kelamin",
            content: GENDER_MAP[insuredPayload?.gender],
            span: 1,
            icon: ImageUrl.Star
          },
          {
              title: "Hubungan",
              content: insuredPayload?.relationship,
              span: 1,
              icon: ImageUrl.Link
          },
          {
              title: "Tanggal Lahir",
              content: insuredPayload?.dob,
              span: 1,
              icon: ImageUrl.DOB
          },
      ],
        'Detail Identitas': [
            {
                title: "Kewarganegaraan",
                content: citizenship,
                span: 1,
                icon: ImageUrl.Globe
            },
            {
                title: `Nomor ${!insuredPayload.citizenship ? "Sesuai Paspor" : insuredPayload.relationship === 'child' ? 'lengkap' :  insuredPayload.citizenship === "Indonesian" ? "Sesuai KTP" : "Sesuai KITAS/KITAP" }`,
                content: idNo,
                span: 1,
                icon: ImageUrl.Calendar
            }
        ],
      }
  
  const finalPayloadInternational = {
    'Detail Personal': [
      {
          title: 'Nama Sesuai Paspor',
          content: insuredPayload?.name,
          span: 1,
          icon: ImageUrl.Profile
      },
      {
        title: "Jenis Kelamin",
        content: GENDER_MAP[insuredPayload?.gender],
        span: 1,
        icon: ImageUrl.Star
      },
      {
          title: "Hubungan",
          content: insuredPayload?.relationship,
          span: 1,
          icon: ImageUrl.Link
      },
      {
          title: "Tanggal Lahir",
          content: insuredPayload?.dob,
          span: 1,
          icon: ImageUrl.DOB
      },
  ],
    'Detail Identitas': [
        {
            title: `Nomor Sesuai Paspor`,
            content: idNo,
            span: 1,
            icon: ImageUrl.Calendar
        }
    ],
  }
  
    return citizenship === 'Paspor' ? finalPayloadInternational : finalPayloadDomestic;
  }
  
  export default insuredCardProcessor
  