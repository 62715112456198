import {
  BenefitDetailsCtn,
  FlexColumnCtn,
  InsuredCardDataContainer,
  InsuredCardDataItem,
  InsuredCardDataItemContent,
  InsuredCardDataItemTitle,
} from "./element";
import PropTypes from "prop-types";

const PolicyHolderInfoCard = ({ data }) => {
  return (
    <InsuredCardDataContainer>
      {data.map((d) => (
          d.content ? (
              <InsuredCardDataItem key={d.title} span={d.span}>
                <BenefitDetailsCtn>
                  <img width="40px" height="40px" src={d.icon} />
                  <FlexColumnCtn>
                    <InsuredCardDataItemTitle>{d.title}</InsuredCardDataItemTitle>
                    <InsuredCardDataItemContent>
                      {d.title === "Nomor Handphone" ? `+${d.content}` : d.content}
                    </InsuredCardDataItemContent>
                  </FlexColumnCtn>
                </BenefitDetailsCtn>
              </InsuredCardDataItem>
          ) : null
      ))}
    </InsuredCardDataContainer>
  );
};
PolicyHolderInfoCard.propTypes = {
  data: PropTypes.object,
};

export default PolicyHolderInfoCard;
