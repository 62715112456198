import styled from "styled-components";
import { ImageUrl } from "../../Images";

export const PageNotFoundWrapper = styled.div`
`
export const PageNotFoundCtn = styled.div`
    width: 100%;
    position: relative;
    background: url(${ImageUrl.PageNotFound});
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    min-height: 700px;
    margin-top: 110px;
`
export const PageNotFoundHeader = styled.div`
    font-family: Mulish;
    font-size: 40px;
    font-weight: 800;
    line-height: 56px;
    text-align: center;
    margin-bottom: 24px;
`
export const PageNotFoundText = styled.div`
    font-family: Mulish;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    text-align: center;
    margin-bottom: 24px;
`
export const DescriptionContainer = styled.div`
  display: flex;
  justify-content: center;
`
export const DescriptionButton = styled.button`
  color: #333333;
  font-family: Mulish;
  font-size: 14px;
  font-style: normal;
  font-weight: 800;
  border-radius: 100px;
  background: #FAF000;
  border: none;
  padding: 14px 35px;
  cursor: pointer;

  &:hover {
      background-color: #ece30c;
  }
`
export const DescriptionBtn = styled.button`
  color: #333333;
  font-family: Mulish;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  border-radius: 50%;
  background: #FAF000;
  border: none;
  padding: 0px;
  width: 49px;
  height: 47px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;

  &:hover {
      background-color: #ece30c;
  }
`
export const BottomCtn = styled.div`
    height: 50px;
    background-image: linear-gradient(to bottom, #dbdbdb, #ffffff);
`