import React, { useMemo } from "react";
import {
  ContentWrapper,
  PaymentTopImageCtn,
  PaymnetSucessFullWarpper,
  MiddleIconCtn,
  DetailsWrapper,
  PolicyNumberCtn,
  DetailsTabCtn,
  DetailsTab,
  DetailsTabValue,
  DetailsTabHeader,
  BottomText,
  PlayStoreIconCtn,
  PageHeader,
  DetailsText,
  PaymentBoldText,
  DocumentBtn,
  AnchorTag,
  BounceLoaderCtn,
  PolicyNumberCard,
  PolicyNumberWrapper,
  GreyHr,
  DocumentBtn3,
} from "../elements";
import { ImageUrl } from "../../../Images";
import { FormattedMessage } from "react-intl";
import { BounceLoader } from "react-spinners";
import convertToIDR from "../../../lib/utility/convertToIDR";
import { isArray } from "lodash";

const PaymentSucessFull = ({ data: paymentStatusData }) => {

  const detailCard = useMemo(() => {
    return [
      {
        icon: ImageUrl.CalendarBlue,
        title: "Tanggal Transaksi",
        value: paymentStatusData?.transaction_date || "-",
      },
      {
        icon: ImageUrl.NotesBlue,
        title: "Status Transaksi",
        value: paymentStatusData?.payment_status || "-",
      },
      {
        icon: ImageUrl.BagBlue,
        title: "Total Biaya",
        value: convertToIDR(paymentStatusData?.amount || ""),
      },
      {
        icon: ImageUrl.SheildBlue,
        title: "Periode Perlindungan",
        value: paymentStatusData?.coverage_date || "-",
      },
    ];
  }, [paymentStatusData]);

  return paymentStatusData?.app_number ? (
    <PaymnetSucessFullWarpper>
      <PaymentTopImageCtn banner={ImageUrl.PaySuccessfull} />
      <ContentWrapper>
        <MiddleIconCtn>
          <img
            src={ImageUrl.SuccessIcon}
            height="128px"
            width="128px"
            alt="success-icon"
          />
        </MiddleIconCtn>
        <DetailsWrapper>
          <PageHeader color="#009632">Anda Sudah Terlindungi</PageHeader>
          {paymentStatusData?.text && paymentStatusData?.text[0] && (
            <DetailsText>
              <FormattedMessage
                id="Terima kasih telah membeli TAP Travel"
                defaultMessage={paymentStatusData?.text[0]}
                values={{
                  b: (chunks) => <PaymentBoldText>{chunks}</PaymentBoldText>,
                  br: <br />,
                }}
              />
            </DetailsText>
          )}
          {isArray(paymentStatusData?.policyholder_data) &&
          paymentStatusData?.policyholder_data.length == 1 ? (
            <AnchorTag
              href={paymentStatusData?.policyholder_data[0]?.doc_url}
              target="_blank"
              rel="noreferrer"
            >
              <DocumentBtn
                id="send-otp-btn"
                loading={false}
                onClick={() => {}}
                type="secondary"
              >
                Lihat Detail Polis
              </DocumentBtn>
            </AnchorTag>
          ) : null}
          <PolicyNumberCtn>
            Nomor Polis
            {isArray(paymentStatusData?.policyholder_data) &&
            paymentStatusData?.policyholder_data.length == 1 ? (
              `: ${paymentStatusData?.policyholder_data[0].insurance_number}`
            ) : (
              <>
                <PolicyNumberWrapper>
                  {paymentStatusData?.policyholder_data
                    ?.slice(0, 5)
                    ?.map((person) => {
                      const {name = '', doc_url = '', insurance_number = ''} = person 
                      return (
                        <PolicyNumberCard>
                          <DetailsTabValue>
                            {insurance_number}
                          </DetailsTabValue>
                          <DetailsTabHeader>{name}</DetailsTabHeader>
                          {doc_url && <AnchorTag
                            href={doc_url}
                            target="_blank"
                            rel="noreferrer"
                          >
                            <DocumentBtn3
                              id="send-otp-btn"
                              loading={false}
                              onClick={() => {}}
                              type="secondary"
                            >
                              Detail Polis
                            </DocumentBtn3>
                          </AnchorTag>}
                        </PolicyNumberCard>
                      );
                    })}
                </PolicyNumberWrapper>
                {paymentStatusData?.policyholder_data?.slice(5) && paymentStatusData?.policyholder_data?.slice(5).length ? (
                  <>
                    <GreyHr />
                    <PolicyNumberWrapper>
                      {paymentStatusData?.policyholder_data
                        ?.slice(5)
                        ?.map((person) => {
                          const {name = '', doc_url = '', insurance_number = ''} = person 
                          return (
                            <PolicyNumberCard>
                              <DetailsTabValue>
                                {insurance_number}
                              </DetailsTabValue>
                              <DetailsTabHeader>{name}</DetailsTabHeader>
                              {doc_url && <AnchorTag
                                href={doc_url}
                                target="_blank"
                                rel="noreferrer"
                              >
                                <DocumentBtn3
                                  id="send-otp-btn"
                                  loading={false}
                                  onClick={() => {}}
                                  type="secondary"
                                >
                                  Detail Polis
                                </DocumentBtn3>
                              </AnchorTag>}
                            </PolicyNumberCard>
                          );
                        })}
                    </PolicyNumberWrapper>
                  </>
                ) : null}
              </>
            )}
          </PolicyNumberCtn>
          <DetailsTabCtn>
            {detailCard.map((card) => {
              return (
                <DetailsTab>
                  <img src={card.icon} width="32px" height="32px" alt="icon" />
                  <DetailsTabHeader>{card.title}</DetailsTabHeader>
                  <DetailsTabValue>{card.value}</DetailsTabValue>
                </DetailsTab>
              );
            })}
          </DetailsTabCtn>
          <BottomText>
            Unduh aplikasi TAP Insure untuk melihat detail polis, cek daftar
            manfaat serta melakukan proses pengajuan klaim yang cepat dan mudah
          </BottomText>
          <PlayStoreIconCtn>
            <AnchorTag
              href={
                "https://play.google.com/store/apps/details?id=com.pasarpolis.tapinsurance&pcampaignid=web_share"
              }
              target="_blank"
              rel="noreferrer"
            >
              <img
                src={ImageUrl.GooglePlay}
                width="156px"
                height="56px"
                alt="google-play"
              />
            </AnchorTag>
            <AnchorTag
              href={"https://apps.apple.com/id/app/tap-insure/id1629379936"}
              target="_blank"
              rel="noreferrer"
            >
              <img
                src={ImageUrl.AppStore}
                width="156px"
                height="56px"
                alt="app-store"
              />
            </AnchorTag>
          </PlayStoreIconCtn>
        </DetailsWrapper>
      </ContentWrapper>
    </PaymnetSucessFullWarpper>
  ) : (
    <BounceLoaderCtn>
      <BounceLoader color="#b33cfa" size={90} />
    </BounceLoaderCtn>
  );
};

export default PaymentSucessFull;
