import React from "react";
import {
  FormCtn,
  ImfoCtn,
  InfoText,
  InputContainer2,
  InputText2,
  InputTextCtn,
  PrefixCtn,
  PromptError,
  PromptErrorMsg,
  SearchImageContainer,
  SearchImageContainer2,
  TextWrapper,
  TextWrapper2,
} from "../InputText/elements";
import { ErrorMessage, Field, useField } from "formik";
import { ImageUrl } from "../../../Images";

const iconGenerator = {
  text: ImageUrl.UpDownArorw,
  date: ImageUrl.Calendar3,
  sms: ImageUrl.SMSGrey,
  phone: ImageUrl.PhoneGrey,
  pin: ImageUrl.Pin,
  time: ImageUrl.Time
};

export default function TextInputV3({
  type = "text",
  label = "",
  icontype = "text",
  readonly = true,
  showIcon = true,
  infoText = "",
  prefix = '',
  ...props
}) {
  const [field, meta, helpers] = useField(props.name);

  return (
    <InputTextCtn>
      <TextWrapper2 disabled={props.disabled} error={meta.touched && !!meta.error}>
        <FormCtn disabled={props.disabled}>
          <Field {...props}>
            {({ field, meta }) => (
              <InputContainer2>
                {
                    prefix && <PrefixCtn value={prefix}/>
                }
                <InputText2
                  {...field}
                  {...meta}
                  id={`${props.name}~input`}
                  type={type}
                  data-attr-slug={`${props.name}~input`}
                  error={meta.touched && !!meta.error}
                  disabled={props.disabled}
                  isFilled={field.value}
                  placeholder={label}
                />
              </InputContainer2>
            )}
          </Field>
        </FormCtn>
        {showIcon ? (
          <SearchImageContainer2>
            <img
              src={iconGenerator[icontype]}
              width="24px"
              height="24px"
              alt="search-icon"
            />
          </SearchImageContainer2>
        ) : null}
      </TextWrapper2>
      {infoText && (
        <ImfoCtn>
          <img src={ImageUrl.BlueInfo} alt="info" width="20px" height="20px" />
          <InfoText>{infoText}</InfoText>
        </ImfoCtn>
      )}
      <ErrorMessage
        name={props.name}
        render={(msg) => (
          <PromptError>
            <PromptErrorMsg>
              <div>{msg}</div>
            </PromptErrorMsg>
          </PromptError>
        )}
      />
    </InputTextCtn>
  );
}
