
import { BrowserRouter } from 'react-router-dom';
import AppRouters from './routes';
import ScrollToTop from './components/RouteToTop';

function App() {
  return (
    <div className="App">
       <BrowserRouter>
          <ScrollToTop />  
          <AppRouters/>
       </BrowserRouter>
    </div>
  );
}

export default App;
