import styled from "styled-components";

export const TravelFormsWarpper = styled.div`
  position: relative;
  background: url("https://storage.googleapis.com/pp_img/tap-travel-website/common/Bottom-Vector.svg");
  background-repeat: no-repeat;
  background-size: auto;
  background-color: #f5f5f5;
  background-position: bottom;
`;
export const TravelFormCtn = styled.div`
  width: 100%;
`;
export const TravelInsuredDetailsCtn = styled.div`
  width: 65%;
  display: grid;
  grid-template-columns: 65% 32%;
  gap: 3%;
  margin: 40px auto;

  @media screen and (max-width: 720px) {
    width: 92%;
    display: flex;
    flex-direction: column;
    gap: 30px;
  }
`;
export const HolderFormWrapper = styled.div`
  padding: 48px;
  border-radius: 32px;
  background: #ffffff;
  margin: 0px 0px 30px 0px;
  display: flex;
  flex-direction: column;
  gap: 16px;

  @media screen and (max-width: 720px) {
    padding: 24px;
  }
`;
export const HolderTitle = styled.div`
  font-family: Mulish;
  font-size: 16px;
  font-weight: 700;
  line-height: 22px;
  text-align: left;
  color: #b43cfa;

  @media screen and (max-width: 720px) {
    font-size: 12px;
    line-height: 16px;
  }
`;
export const Title = styled.div`
  font-family: Mulish;
  font-size: 16px;
  font-weight: 700;
  line-height: 20px;
  text-align: left;
  color: #333333;
  margin: 16px 0px 0px 0px;

  @media screen and (max-width: 720px) {
    font-size: 12px;
    line-height: 16px;
  }
`;
export const FormItem = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`;

export const GridContainer = styled.div`
  display: grid;
  grid-template-columns: 49% 49%;
  gap: 2%;

  @media screen and (max-width: 720px) {
    display: flex;
    flex-direction: column;
    gap: 10px;
  }
`;
export const InsuredFormWrapper = styled.div`
  padding: 48px;
  border-radius: 32px;
  background: #ffffff;
  margin: 0px 0px 30px 0px;
  display: flex;
  flex-direction: column;
  gap: 16px;

  @media screen and (max-width: 720px) {
    padding: 24px;
  }
`;
export const BenefitDetailWrapper = styled.div`
  padding: 48px;
  border-radius: 32px;
  background: #ffffff;
  display: flex;
  flex-direction: column;
  gap: 16px;
  height: fit-content;

  @media screen and (max-width: 720px) {
    padding: 24px;
  }
`;
export const BenefitDetailsCtn = styled.div`
  display: grid;
  grid-template-columns: 25% 73%;
  gap: 2%;
`;
export const FlexColumnCtn = styled.div`
  display: flex;
  flex-direction: column;
`;
export const Benefitdescription = styled.div`
  font-family: Mulish;
  font-size: 14px;
  font-weight: 700;
  line-height: 22px;
  text-align: left;
  color: #333333;
`;
export const BenefiitTitle = styled.div`
  font-family: Mulish;
  font-size: 12px;
  font-weight: 600;
  line-height: 16px;
  text-align: left;
  color: #999999;
`;
export const BenefitDetailSubmitBtn = styled.div`
  font-family: Mulish;
  padding: 12px 16px 12px 16px;
  border-radius: 8px;
  border: 2px solid #b43cfa;
  font-size: 14px;
  font-weight: 700;
  line-height: 16px;
  text-align: center;
  color: #b43cfa;
  margin-top: 10px;
  cursor: pointer;
  transition: background 0.5s ease-out;

  &:hover {
    background: #b43cfa;
    color: #ffffff;
  }
`;
export const HeaderText = styled.p`
  font-size: 18px;
  font-family: Mulish;
  font-weight: 800;
  line-height: 22px;
  text-align: center;
  margin-top: 32px;

  @media screen and (max-width: 720px) {
    font-size: 12px;
    line-height: 16px;
    padding: 0px 40px;
  }
`;
export const SecondHeaderText = styled.p`
  font-family: Mulish;
  font-size: 16px;
  font-weight: 400;
  line-height: 20px;
  text-align: center;

  @media screen and (max-width: 720px) {
    font-size: 10px;
    line-height: 14px;
    padding: 0px 40px;
  }
`;
export const BounceLoaderCtn = styled.div`
  height: 70vh;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`