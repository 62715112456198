import React, { useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";

import {
  PromptError,
  PromptErrorMsg,
  TextInputContainer,
  TextInputItem,
  TextInputItemContainer,
} from "./elements";
import { ErrorMessage, Field } from "formik";

const TextArea = (props) => {
  const { label, width } = props;
  const textareaRef = useRef(null);
  const [currentValue, setCurrentValue] = useState("");

  useEffect(() => {
    textareaRef.current.style.height = "0px";
    const scrollHeight = textareaRef.current.scrollHeight;
    textareaRef.current.style.height = scrollHeight + "px";
  }, [currentValue]);
  return (
    <TextInputContainer>
      <TextInputItemContainer width={width}>
        <Field {...props}>
          {({ field, meta }) => (
            <TextInputItem
              {...field}
              {...meta}
              ref={textareaRef}
              id={`${props.name}~textarea`}
              data-attr-slug={`${props.name}~input`}
              disabled={props.disabled}
              pattern={props.pattern}
              type={props.type}
              error={meta.touched && !!meta.error}
              placeholder={label}
            />
          )}
        </Field>
      </TextInputItemContainer>
      <ErrorMessage
        name={props.name}
        render={(msg) => {
          return (
            <PromptError>
              <PromptErrorMsg>
                <div>{msg}</div>
              </PromptErrorMsg>
            </PromptError>
          );
        }}
      />
    </TextInputContainer>
  );
};

TextArea.propTypes = {
  label: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
};

TextArea.defaultProps = {};

export default TextArea;
