export const imageLink = {
    heroImage: 'https://storage.googleapis.com/pp_img/tap-travel-website/Product%20Page/TAP%20AUTO%20Product%20Page%20Banner%202.png',
    section2Banner: 'https://storage.googleapis.com/pp_img/tap-travel-website/Product%20Page/Section%202%20Tap%20Auto%20Product%20Banner%202.png',
    section3Banner: 'https://storage.googleapis.com/pp_img/tap-travel-website/Product%20Page/car.png',
    smartphoneDrop: 'https://storage.googleapis.com/pp_img/tap-travel-website/Product%20Page/Smartphone%20Drop.png',
    checklist: 'https://storage.googleapis.com/pp_img/tap-travel-website/Product%20Page/Checklist.png',
    carCrash: 'https://storage.googleapis.com/pp_img/tap-travel-website/Product%20Page/Car%20Crash.png',
    carCriminal: 'https://storage.googleapis.com/pp_img/tap-travel-website/Product%20Page/Car%20Criminal.png',
    roberry: 'https://storage.googleapis.com/pp_img/tap-travel-website/Product%20Page/robberry.png',
    lightning: 'https://storage.googleapis.com/pp_img/tap-travel-website/Product%20Page/lightning.png',
    section7Banner: 'https://storage.googleapis.com/pp_img/tap-travel-website/Product%20Page/Section%207%20TAP%20Auto%20Banner%20Image.png',
    manHitbyCar: 'https://storage.googleapis.com/pp_img/tap-travel-website/Product%20Page/man%20hit%20by%20car.svg',
    hammerLaw: 'https://storage.googleapis.com/pp_img/tap-travel-website/Product%20Page/Hammer%20Law.svg',
    manWithBandage: 'https://storage.googleapis.com/pp_img/tap-travel-website/Product%20Page/manWithBandage.svg',
    ambulance: 'https://storage.googleapis.com/pp_img/tap-travel-website/Product%20Page/ambulance.svg',
    toolkit: 'https://storage.googleapis.com/pp_img/tap-travel-website/Product%20Page/toolkit.svg',
    towTruck: 'https://storage.googleapis.com/pp_img/tap-travel-website/Product%20Page/tow%20truck.svg',
    waterHammer: 'https://storage.googleapis.com/pp_img/tap-travel-website/Product%20Page/water%20hammer.svg',
    flood: 'https://storage.googleapis.com/pp_img/tap-travel-website/Product%20Page/flood.svg',
    tsunami: 'https://storage.googleapis.com/pp_img/tap-travel-website/Product%20Page/tsunami.svg',
    demonstration: 'https://storage.googleapis.com/pp_img/tap-travel-website/Product%20Page/demonstration.svg',
    terrorism: 'https://storage.googleapis.com/pp_img/tap-travel-website/Product%20Page/terrorism.svg',
    mobilPenumpang: 'https://storage.googleapis.com/pp_img/tap-travel-website/Product%20Page/Icon_Mobil_Penumpang.svg',
    truck: 'https://storage.googleapis.com/pp_img/tap-travel-website/Product%20Page/Icon_Bus_Truk_Pickup.svg',
    section9Banner: 'https://storage.googleapis.com/pp_img/tap-travel-website/Product%20Page/Section%209%20TAP%20Auto%20Banner%20Image.png',
}

export const riplayDocUrl = 'https://storage.googleapis.com/pp_img/tap-travel-website/RIPLAY%20Docs/Auto.pdf'