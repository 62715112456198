import { useState } from "react";
import {
  PropertyContainer,
  ProductListIcon,
  ProductListIconWrapper,
  ProductListWrapper,
  ProductListTitle,
  ProductListIconContainer,
  ProductButtonContainer,
  ProductButton,
  ProductBtnArrow,
  ProductDescription,
  ProductHeaderText,
  AnchorTag,
} from "./PropertyPage.style";
import Header from "../../components/Header";
import { imagesLink, PROPERTY_TYPE, riplayDocUrl } from "./constants";
import Footer from "../../components/Footer";
import { FormattedMessage } from "react-intl";
import { ImageUrl } from "../../Images";
import InquiryForm from "../userCriteria/inquiryForm";
import ScrollToTop from "../../components/ScrollToTop";

const PropertyPage = () => {
  const [activeProduct, setActiveProduct] = useState("fire");

  return (
    <PropertyContainer>
      <Header />
      <div className="section">
        <img src={imagesLink.PropertyLogo} />
      </div>
      <div className='heading-text'>
        <FormattedMessage
          id='heading'
          defaultMessage='Perlindungan Pasti untuk Properti,{br} Tenang Setiap Hari!'
          values={{
            br: <br />,
          }}
        />
      </div>
      <div className='hero-container'>
        <p>Jalan-jalan ke pasar malam, hati tenang, semua terasa nyaman.<br />
        Perlindungan properti jadi andalan, harta berharga aman, hati pun tak beban.</p>
        <div className='hero-div'>
            <div className='hero-div-inner'>
                <div className='hero-div-items'>
                    <p className='price-title'>Perlindungan Mulai Dari</p>
                    <p className='amount'><span className='super'>Rp</span>299.000</p>
                </div>
            </div>
        </div>
      </div>
      <ProductListWrapper>
        {PROPERTY_TYPE.map((product) => {
          return (
            <ProductListIconWrapper
              onClick={() => {
                setActiveProduct(product.productName);
              }}
            >
              <ProductListIconContainer
                active={activeProduct === product.productName}
              >
                <ProductListIcon src={product.activeIcon} alt={product.key} />
              </ProductListIconContainer>
              <ProductListTitle
                isActive={activeProduct === product.productName}
              >
                {product.label}
              </ProductListTitle>
            </ProductListIconWrapper>
          );
        })}
      </ProductListWrapper>
      {activeProduct === "fire" && (
        <>
          <ProductHeaderText>
            <FormattedMessage
              id='heading'
              defaultMessage='Mengapa Asuransi Property Fire{br} dari TAP Insure?'
              values={{
                br: <br />,
              }}
            />
          </ProductHeaderText>
          <ProductDescription>
            <FormattedMessage
              id='heading'
              defaultMessage='Asuransi Property Fire TAP Insure menjamin perlindungan terhadap harta benda terhadap kerugian{br} akibat Kebakaran, Petir, Ledakan, Kejatuhan Pesawat dan Asap.'
              values={{
                br: <br />,
              }}
            />
          </ProductDescription>
          <div className="section m-100">
            <img src={imagesLink.PropertyFireWhy} />
          </div>
          <ProductHeaderText>
            <FormattedMessage
              id='heading'
              defaultMessage='Yang Terlindungi'
              values={{
                br: <br />,
              }}
            />
          </ProductHeaderText>
          <ProductDescription>
            <FormattedMessage
              id='heading'
              defaultMessage='Asuransi Property Fire Tap Insure memberikan perlindungan untuk semua aset berharga, mulai dari{br} rumah, apartemen, hingga barang berharga lainnya. Apakah kamu sedang memulai perjalanan{br} baru atau menikmati momen-momen spesial, kami siap menjaga keamanan propertimu.'
              values={{
                br: <br />,
              }}
            />
          </ProductDescription>
          <div className="section m-50">
            <img src={imagesLink.PropertyFireInfo} />
          </div>
          <ProductHeaderText>
            <FormattedMessage
              id='heading'
              defaultMessage='Proteksi Properti, Bebas Worry!'
              values={{
                br: <br />,
              }}
            />
          </ProductHeaderText>
          <ProductDescription>
            <FormattedMessage
              id='heading'
              defaultMessage='Asuransi Property Fire Tap Insure melindungi properti kamu dari:'
              values={{
                br: <br />,
              }}
            />
          </ProductDescription>
          <div className="section m-50">
            <img src={imagesLink.PropertyFireBenefit} />
          </div>
          <ProductHeaderText>
            <FormattedMessage
              id='heading'
              defaultMessage='Jika Dibutuhkan...'
              values={{
                br: <br />,
              }}
            />
          </ProductHeaderText>
          <ProductDescription>
            <FormattedMessage
              id='heading'
              defaultMessage='Manfaat lainnya dari Asuransi Property Fire Tap Insure:'
              values={{
                br: <br />,
              }}
            />
          </ProductDescription>
          <div className="section">
            <img src={imagesLink.PropertyFireOthers} />
          </div>
          <ProductHeaderText>
            <FormattedMessage
              id="heading"
              defaultMessage="Mau Tau Lebih Lanjut?"
              values={{
                br: <br />,
              }}
            />
          </ProductHeaderText>
          <ProductDescription>
            <FormattedMessage
              id="Cek info lengkap Asuransi Property Fire Tap Insure sekarang!"
              defaultMessage="Cek info lengkap Asuransi Property Fire Tap Insure sekarang!"
              values={{
                br: <br />,
              }}
            />
          </ProductDescription>
          <AnchorTag href={riplayDocUrl.fire} target="_blank">
            <ProductButtonContainer>
              <ProductButton>
                <FormattedMessage
                  id="Unduh"
                  defaultMessage="Unduh Informasi Produk"
                />
              </ProductButton>
              <ProductBtnArrow padding="0px">
                <img src={ImageUrl.DownloadWhite} width="17px" height="17px" />
              </ProductBtnArrow>
            </ProductButtonContainer>
          </AnchorTag>
        </>
      )}
      {activeProduct === "risk" && (
        <>
          <ProductHeaderText>
            <FormattedMessage
              id="heading"
              defaultMessage="Mengapa Asuransi Property All Risk{br} dari TAP Insure?"
              values={{
                br: <br />,
              }}
            />
          </ProductHeaderText>
          <ProductDescription>
            <FormattedMessage
              id="text"
              defaultMessage="Asuransi Property All-Risk TAP Insure menjamin perlindungan terhadap harta benda{br} secara menyeluruh terhadap risiko, kecuali risiko yang dikecualikan dalam polis."
              values={{
                br: <br />,
              }}
            />
          </ProductDescription>
          <div className="section m-50">
            <img src={imagesLink.PropertyRiskWhy} />
          </div>
          <ProductHeaderText>
            <FormattedMessage
              id="heading"
              defaultMessage="Proteksi Properti, Bebas Worry!"
              values={{
                br: <br />,
              }}
            />
          </ProductHeaderText>
          <ProductDescription>
            <FormattedMessage
              id="text"
              defaultMessage="Asuransi Property All-Risk Tap Insure melindungi properti kamu dari:"
              values={{
                br: <br />,
              }}
            />
          </ProductDescription>
          <div className="section">
            <img src={imagesLink.PropertyRiskBenefit} />
          </div>
          <ProductHeaderText>
            <FormattedMessage
              id="heading"
              defaultMessage="Mau Tau Lebih Lanjut?"
              values={{
                br: <br />,
              }}
            />
          </ProductHeaderText>
          <ProductDescription>
            <FormattedMessage
              id="Cek info lengkap Asuransi Property All Risk Tap Insure sekarang!"
              defaultMessage="Cek info lengkap Asuransi Property All Risk Tap Insure sekarang!"
              values={{
                br: <br />,
              }}
            />
          </ProductDescription>
          <AnchorTag href={riplayDocUrl.risk} target="_blank">
            <ProductButtonContainer>
              <ProductButton>
                <FormattedMessage
                  id="Unduh"
                  defaultMessage="Unduh Informasi Produk"
                />
              </ProductButton>
              <ProductBtnArrow padding="0px">
                <img src={ImageUrl.DownloadWhite} width="17px" height="17px" />
              </ProductBtnArrow>
            </ProductButtonContainer>
          </AnchorTag>
        </>
      )}
      <div className="section">
        <img src={imagesLink.PropertyFooterImage} width="50%" height="600px" />
      </div>
      <InquiryForm product="Asuransi Property" />
      <ProductHeaderText>
        <FormattedMessage
          id="heading"
          defaultMessage="Saat Hidup Membawa Kejutan,{br}TAP Insure Siap Memberikan Perlindungan."
          values={{
            br: <br />,
          }}
        />
      </ProductHeaderText>
      <ProductDescription>
        <FormattedMessage
          id="heading"
          defaultMessage="Lindungi yang berharga, jalani hidup tanpa worry! Dari kejadian kecil sampai bencana{br} besar, kami siap jaga semua asetmu. Properti aman, pikiran tenang"
          values={{
            br: <br />,
          }}
        />
      </ProductDescription>
      <div className="section">
        <img src={imagesLink.PropertyAllBenefit} />
      </div>
      <ScrollToTop />
      <Footer />
    </PropertyContainer>
  );
};

export default PropertyPage;
