import styled from 'styled-components'

export const CorporateGovernanceContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;

    * {
        margin: 0;
        padding: 0;
    }

    .content {
        width: 100%;  
        background: linear-gradient(180deg, rgba(217,217,217,1) 0%, rgba(255,255,255,1) 80%);
        margin-bottom: 50px;
    }

    .hero-section {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        padding: 32px 0px 0px 0px;
        width: 100%;               
    }

    .hero-section h1 {
        margin-bottom: 48px;
    }
    
    .content-inner {
        width: 100%;
        max-width: 1000px;         
        margin: 0 auto;            
        text-align: justify;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        padding: 32px;
        line-height: 32px;
        gap: 40px;        
    }

    .guidelines-container {
        width: 100%;
    }

    .guidelines-items {
        display: flex;
        justify-content: space-between;
        align-items: center;
        border-bottom: 2px solid #FAF000;
        padding: 24px 0;
    }

    .guidelines-items h3 {
        font-size: 16px;
    }

    .guidelines-items a {
        background-color: #FAF000;
        box-shadow: none;
        border: none;
        padding: 10px 18px;
        border-radius: 100px;
        cursor: pointer;
        transition: background-color 0.5s ease;
        text-decoration: none;
        color: #000;
        font-weight: 700;
        font-size: 14px; 

        &:hover{
            background-color: #FBF33E;
        }
    }

    .flex-btn{
      display: flex;   
    }
     .btn-circle {
        box-shadow: none;
        border: none;
        border-radius: 50%;
        cursor: pointer;
        background-color: #FAFC00;
        transition: background-color 0.5s ease;
        color: #FFF;
        width: 47px;
        height: 47px;

        &:hover {
            background-color: #E5E700;
        }
    }

    .guidelines-items .btn-download {
        border: none;
        background-color: #FAFC00;
        padding: 16px 24px;
        border-radius: 24px;
        cursor: pointer;
        transition: background-color 0.5s ease;
        font-weight: 700;

        &:hover {
            background-color: #E5E700;
        }
    }
`