import React from "react";
import {
  BottomCtn,
  DescriptionBtn,
  DescriptionButton,
  DescriptionContainer,
  PageNotFoundCtn,
  PageNotFoundHeader,
  PageNotFoundText,
  PageNotFoundWrapper,
} from "./elements";
import Header from "../Header";
import Footer from "../Footer";
import { FormattedMessage } from "react-intl";
import { ImageUrl } from "../../Images";
import { useNavigate } from "react-router-dom";

function NotFoundPage(props) {
  const navigate = useNavigate();

  const goHome = () => {
    navigate({
      pathname: "/",
    });
  };

  return (
    <PageNotFoundWrapper>
      <Header />
      <PageNotFoundCtn>
        <PageNotFoundHeader>
          <FormattedMessage
            id="Oops! Halaman Tidak Ditemukan"
            defaultMessage="Oops! Halaman Tidak Ditemukan"
          />
        </PageNotFoundHeader>
        <PageNotFoundText>
          <FormattedMessage
            id="Halaman yang kamu cari tidak ketemu,Tap kembali ke beranda, banyak yang seru menunggu!"
            defaultMessage="Halaman yang kamu cari tidak ketemu,{br}Tap kembali ke beranda, banyak yang seru menunggu!"
            values={{
              br: <br />,
            }}
          />
        </PageNotFoundText>
        <DescriptionContainer onClick={goHome}>
          <DescriptionButton>
            <FormattedMessage
              id="Tap ke Beranda"
              defaultMessage="Tap ke Beranda"
            />
          </DescriptionButton>
          <DescriptionBtn>
            <img src={ImageUrl.RightArrow} />
          </DescriptionBtn>
        </DescriptionContainer>
      </PageNotFoundCtn>
      <BottomCtn />
      <Footer />
    </PageNotFoundWrapper>
  );
}

export default NotFoundPage;
