import styled from "styled-components";
import LargeButton from "../LargeButton";

export const OtpActionSectionContainer = styled.div`
  padding: 48px;
`;

export const OtpActionSectionInputContainer = styled.div`
  width: 90%:
  max-width: 450px;
  margin: auto;
  margin-bottom: 16px;
`;

export const OtpActionSectionButtonContainer = styled.div`
  max-width: 450px;
  margin: 20px auto;
  border-radius: 12px;
`;
export const OtpActionSectionButtonContainer2 = styled.div`
  max-width: 320px;
  margin: 20px auto;
  border-radius: 12px;
`;

export const OtpActionSectionResend = styled.div`
  font-family: Mulish;
  font-weight: 500;
  font-size: 1rem;
  line-height: 1.6rem;

  text-align: center;
  letter-spacing: 0.02em;
  color: #333333;
  margin-bottom: 16px;
`;
export const OtpActionDescription = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-bottom: 30px;
`;
export const ConfirmOTPText = styled.div`
  font-family: Mulish;
  font-size: 18px;
  font-weight: 800;
  line-height: 26px;
  text-align: center;
`;
export const DesciptionText = styled.div`
  font-family: Mulish;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  text-align: center;
`;
export const PhoneNumberText = styled.div`
  font-family: Mulish;
  font-size: 16px;
  font-weight: 800;
  line-height: 24px;
  text-align: center;
`;
export const SubmitBtnOTP = styled(LargeButton)`
  min-width: 60%;
`