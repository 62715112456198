import {useState} from 'react'
import { DatePickerContainer } from '../DateOfBirth/DateOfBirth.style'
import {format} from 'date-fns'
import Calendar from 'react-calendar'

const DatePeriod = (props) => {
  const [currentView, setCurrentView] = useState('month');
  const [startDate, setStartDate] = useState(props.startDate || null)

  const handleSetFormStart = (val) => {
    const thisYear = new Date(val);
    const oneDay = 24 * 60 * 60 * 1000;
    let nextOneYear = new Date(
      `${thisYear.getFullYear() + 1}-${
        thisYear.getMonth() + 1
      }-${thisYear.getDate()}`
    );
    nextOneYear = new Date(nextOneYear.getTime() - oneDay);
    const formattedDate =  format(val, 'yyyy-MM-dd')
    setStartDate(formattedDate);
    props.setFormValues({
      ...props.formValues,
      date_of_incident: formattedDate
    })
    props.setDateModal(false)
  };

  return (
    <DatePickerContainer currentView={currentView}>
        <>
            <Calendar 
                minDetail="month"
                defaultView="month"
                showNeighboringMonth={false}
                next2Label={null}
                prev2Label={null}
                minDate={props.startMinDate || null}
                maxDate={props.startMaxDate}
                value={startDate}
                onChange={handleSetFormStart}
                tileClassName={({ date, view }) => {
                    if (view === 'month' && date.getDay() === 0) {
                    return 'sunday';
                    }
                }}
                formatShortWeekday={(_locale, date) => {
                    return new Intl.DateTimeFormat('id-ID', {
                    weekday: 'long',
                    }).format(date).substring(0,1);
                }}
            />
            </>
    </DatePickerContainer>
  )
}

export default DatePeriod
