import axios from 'axios'

export const createDraftCall = (payload) => {
  const url = 'api/purchase/v1/start_purchase/'

  return axios.post(`${process.env.REACT_APP_API_ENDPOINT}/${url}`, payload)
}

export const updateDraftCall = (payload) => {
    const url = 'api/purchase/v1/update_draft/'
  
    return axios.patch(`${process.env.REACT_APP_API_ENDPOINT}/${url}`, payload)
}

export const validateDraftCall = (payload) => {
const url = 'api/purchase/v1/validate_draft/?locales=id'

return axios.post(`${process.env.REACT_APP_API_ENDPOINT}/${url}`, payload)
}

export const applyCoupon = (payload) => {
  const url = 'api/purchase/v1/apply_coupon/'

  return axios.post(`${process.env.REACT_APP_API_ENDPOINT}/${url}`, payload)
}

export const acceptTncCall = (payload) => {
  const url = 'api/purchase/v1/accept_tnc/'

  return axios.post(`${process.env.REACT_APP_API_ENDPOINT}/${url}`, payload)
}
export const attemptPaymentCall = (payload) => {
  const url = 'api/purchase/v1/attempt_payment/'

  return axios.post(`${process.env.REACT_APP_API_ENDPOINT}/${url}`, payload)
}

export const validateDataCall = (payload) => {
  const url = 'api/purchase/v1/validate_data/'

  return axios.post(`${process.env.REACT_APP_API_ENDPOINT}/${url}`, payload)
}

export const zendeskApi = (payload) => {
  const url = 'api/v1/zendesk/create/'

  return axios.post(`${process.env.REACT_APP_API_ENDPOINT}/${url}`, payload)
}

export const zohoApi = (payload) => {
  const url = 'api/v1/zoho/create/'

  return axios.post(`${process.env.REACT_APP_API_ENDPOINT}/${url}`, payload)
}
export const sendEmailApi = (payload) => {
  const url = 'api/v1/notification/send-email/'

  return axios.post(`${process.env.REACT_APP_API_ENDPOINT}/${url}`, payload)
}
export const generateSignedUrl = (payload) => {
  const url = 'api/v1/file/generate-signed-url/'

  return axios.post(`${process.env.REACT_APP_API_ENDPOINT}/${url}`, payload)
}
export const uploadToSignedUrl = (signedUrl, file) => {
  return axios.put(signedUrl,
    file,
    { headers: { 'Content-Type': file.type, Host: 'storage.googleapis.com' } }
  )
}