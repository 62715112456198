import styled from "styled-components";

export const InputContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  background: ${(props) => (props.disabled ? "#EEEEEE" : "#ffffff")};

  & .filled {
    top: -10px;
    font-size: 10px;
  }
`;
export const InputText = styled.input`
  border: none;
  font-size: 14px;
  line-height: 1;
  transition: 200ms cubic-bezier(0, 0, 0.2, 1) 0ms;
  background: ${(props) => (props.disabled ? "#EEEEEE" : "#ffffff")};
  padding: ${(props) => props.value ? props.showLabel ? "20px 10px 10px 10px" : "15px 10px 15px 10px" : "15px 10px 15px 10px"};
  outline: none;
  cursor: pointer;

  :disabled {
    ${(props) =>
      props.isEmpty &&
      `
      -webkit-text-fill-color: #f5f5f5f;
    `}
    ${(props) =>
      !props.isEmpty &&
      `
      -webkit-text-fill-color: #f5f5f5;
      opacity: 1;
    `}
  }

  ${(props) =>
    props.isEmpty
      ? `
    -webkit-text-fill-color : #f5f5f5f;
    `
      : ``}
`;
export const TextLabel = styled.label`
  position: absolute;
  pointer-events: none;
  transition: 200ms cubic-bezier(0, 0, 0.2, 1) 0ms;
  padding: ${(props) => props.value ? "16px 10px 16px 10px" : "16px 10px 16px 10px"};
  color: #999999;
  font-size: 13px;
  font-weight: 700;
  width: 100%;
  line-height: 1;
`;
export const ImfoCtn = styled.div`
  display: grid;
  grid-template-columns: 8% 91%;
  gap: 1%;
  align-items: center;
  margin-top: 5px;
`;
export const InfoText = styled.div`
  font-size: 12px;
  font-weight: 500;
  line-height: 18px;
  text-align: left;
  color: #b43cfa;
`;
export const SearchImageContainer = styled.div`
  height: 18px;
  width: 18px;
  display: flex;
  align-items; center;
`;
export const SearchImageContainer2 = styled.div`
  height: 24px;
  width: 28px;
  display: flex;
  align-items; center;
  padding-left: 10px;
`;
export const TextWrapper = styled.div`
  max-width: 100%;
  padding: 5px !important;
  border-radius: 16px;
  border: ${props => props.error ? '2px solid #DC0000' : '2px solid #eeeeee'};
  display: grid;
  grid-template-columns: calc(100% - 30px) 30px;
  align-items: center;
  background: ${(props) => (props.disabled ? "#EEEEEE" : "#FFFFFF")};
  min-height: 47px;
`;

export const PromptError = styled.div`
  width: 100%;
  margin: 8px 0px;
  display: flex;
  gap: 1%;
`;
export const PromptErrorMsg = styled.div`
  display: inline;
  margin-left: 4px;
  font-family: Mulish;
  font-weight: 700;
  font-size: 0.7rem;
  line-height: 1rem;
  letter-spacing: 0.02em;
  color: #DC0000;
`;
export const InputTextCtn = styled.div`
  display: flex;
  flex-direction: column;
`;

export const InputContainer2 = styled.div`
  position: relative;
  display: flex;
  background: ${(props) => (props.disabled ? "#EEEEEE" : "#ffffff")};
`;
export const InputText2 = styled.input`
  border: none;
  font-size: 14px;
  line-height: 1;
  background: ${(props) => (props.disabled ? "#EEEEEE" : "#ffffff")};
  padding: 15px 10px 15px 10px;
  outline: none;
  cursor: pointer;
  width: -webkit-fill-available;

  :disabled {
    ${(props) =>
      props.isEmpty &&
      `
      -webkit-text-fill-color: #f5f5f5f;
    `}
    ${(props) =>
      !props.isEmpty &&
      `
      -webkit-text-fill-color: #f5f5f5;
      opacity: 1;
    `}
  }

  ${(props) =>
    props.isEmpty
      ? `
    -webkit-text-fill-color : #f5f5f5f;
    `
      : ``}
`;
export const PrefixCtn = styled.input`
    border: none;
    width: 30px;
    font-size: 14px;
    line-height: 1;
    padding: 15px 0px 15px 10px;
    background: ${(props) => props.prefixBg ? props.prefixBg : "#ffffff"};
`
export const TextWrapper2 = styled.div`
  max-width: 100%;
  padding: 5px;
  border-radius: 16px;
  border: ${props => props.error ? '2px solid #DC0000' : '2px solid #eeeeee'};
  display: grid;
  grid-template-columns: calc(100% - 45px) 45px;
  align-items: center;
  background: ${(props) => (props.disabled ? "#EEEEEE" : "#FFFFFF")};
  min-height: 47px;
`;

export const UploadContainer = styled.div`
  margin: 0px 16px;
`

export const UploadItemLabel = styled.label`
  font-family: Mulish;
  font-weight: 700;
  font-size: 1.2rem;
  line-height: 1.6rem;

  color: ${props => props.theme.color.mediumGray};
`

export const UploadItemLabelRequired = styled.sup`
color: ${props => props.theme.color.error};
`

export const UploadItemContainer = styled.div`
  margin-top: 6px;
  height: 96px;
  width: 100%;
  position: relative;
  background-color: ${props => props.theme.color.lightPink};
  border-radius: 4px;
  display: flex;
  justify-content: center;
  align-items: center;
`

export const UploadItemImage = styled.img`
  height: 96px;
  object-fit: cover;
  width: 100%;
  border-radius: 4px;
`

export const UploadItemVideo = styled.video`
  height: 96px;
  object-fit: cover;
  width: 100%;
  border-radius: 4px;
`

export const UploadItemImageContainer = styled.div`
  margin-top: 6px;
  position: relative;
  background: #ffffff;
  padding: 16px;
  border-radius: 16px;
  padding: 12px 16px 16px 16px;
  gap: 8px;
  border-radius: 12px;
  border: 1px solid #e5e5e5;
`

export const UploadItemIcon = styled.img`
  top: 0px;
  left: 0px;
  cursor: pointer;
  position: absolute;
  font-size: 100px;
  width: 100%;
  height: 100%;
`

export const UploadGuide = styled.div`
  font-size: 10px;
  font-weight: 700;
  line-height: 14px;
  letter-spacing: 0.02em;
  text-decoration: underline;
  color: #0064C8;
  margin: 5px 0px 0px 15px;
`

export const UploadGuideIcon = styled(Image)`

`
export const UploadGuideMsg = styled.div`
  display: inline;
  margin-left: 4px;
  font-family: Mulish;
  font-weight: 700;
  font-size: 1rem;
  line-height: 1.2rem;

  letter-spacing: 0.02em;

  color: ${props => props.theme.color.grey};
`

export const UploadError = styled.div`
  margin-top: 8px;
  display: flex;
  align-items: flex-start;
`

export const UploadErrorIcon = styled.img`
  flex-basis: 12px;
`
export const UploadErrorMsg = styled.div`
  display: inline;
  margin-left: 4px;
  font-family: Mulish;
  font-weight: 700;
  font-size: 1rem;
  line-height: 1.2rem;

  letter-spacing: 0.02em;
  flex: 1;
  color: ${props => props.theme.color.error};
`

export const UploadRejectRemark = styled.div`
  margin-top: 8px;
`
export const UploadImageCodeContainer = styled.div`
  border-radius: 12px;
  border: ${props => props.error ? '2px solid #DC0000' : '2px solid #eeeeee'};
  display: grid;
  grid-template-columns: 0% calc(100% - 48px) 24px;
  background: #ffffff;
  padding: 5px;
  align-items: center;

`;
export const UploadItem = styled.input`
  top: 0px;
  font-family: Mulish;
  left: 0px;
  opacity: 0;
  cursor: pointer;
  position: absolute;
  font-size: 100px;
  width: 100%;
  height: 100%;
  padding: 12px;
`
export const UploadImageLabel = styled.label`
  font-size: 14px;
  font-weight: 500;
  line-height: 16px;
  text-align: left;
  color: #999999;
  cursor: pointer;
  padding: 12px;
`;
export const UploadTextLabel = styled.label`
  font-size: 14px;
  font-weight: 500;
  line-height: 1;
  text-align: left;
  font-family: Mulish;
  color: #333333;
  cursor: pointer;
  padding: 12px;
  max-width: 100%;
`;
export const UploadIconContainer = styled.div`
  height: 24px;
  width: 24px;
  padding: 12px;
`;
export const UploadImageText = styled.div`
  font-size: 10px;
  font-weight: 600;
  line-height: 14px;
  letter-spacing: 0.02em;
  color: #999999;
  margin: 8px 0px;
  padding: 12px;
`
export const ImageUploadGuideCtn = styled.div`
  padding: 24px 24px 24px 24px;
`
export const ImageUploadCtn = styled.div`
  width: 100%;
  height: 88px;
  padding: 0px 16px;
`
export const GuideBtnCtn = styled.div`
  width: 100%;
  border: 1.5px solid #0064c8;
  margin: 10px 0px 0px 0px;
`;
export const GuideBtnCtnButton = styled.div`
  width: 100%;
  padding: 12px 16px 12px 16px;
  background: #0064C8;
  color: #FFFFFF;
  font-size: 12px;
  font-weight: 700;
  line-height: 16px;
  text-align: center;
`;
export const UploadItemV2 = styled.input`
  font-size: 12px;
  font-weight: 600;
  line-height: 16px;
  color: #999999;
  text-align: left;
  cursor: pointer;
  opacity: 0;
  background: #ffffff;
  visibility: hidden;
`
export const UploadItemCtn = styled.img`
  position: absolute;
  bottom: 27px;
  right: 27px;
  cursor: pointer;
`