import React from "react";
import ProductList from "../../components/ProductsList";
import { ProductListCtn } from './elements'

const ProductsForm = () => {

    return (
        <ProductListCtn>
          <ProductList />
        </ProductListCtn>
    )
}

export default ProductsForm