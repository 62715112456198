import React, { useState } from 'react'
import { HeaderContainer } from './Header.style'
import {imagesLink} from '../../constants/imageLink'
import { useNavigate } from "react-router-dom";

const Header = () => {
  const [dropdownVisible, setDropdownVisible] = useState(null);
  const navigate = useNavigate();

  const handleMouseEnter = (menuItem) => {
    setDropdownVisible(menuItem);
  };

  const handleMouseLeave = () => {
    setDropdownVisible(null);
  };

  const gotoAboutPage = () => {
    navigate({
      pathname: '/tentang-kami'
    })
  }
  
  const gotoVisiMisiPage = () => {
    navigate({
      pathname: '/visi-misi'
    })
  }

  const gotoOrganizationPage = () => {
    navigate({
      pathname: '/organisasi'
    }) 
  }

  const gotoCorporateGovernance = () => {
    navigate({
      pathname: '/tata-kelola-perusahaan'
    }) 
  }

  const gotoRoadsideAssistance = () => {
    navigate({
        pathname: "/road-assistance",
      });
  }

  const gotoHowtoClaim = () => {
    navigate({
        pathname: "/klaim",
      });
  }
  const gotoHomePage = () => {
    navigate({
        pathname: "/",
      });
    }
  const gotoAutoProductPage = () => {
    navigate({
        pathname: "/produk-auto",
      });
    }
    
    const gotoTravelProductPage = () => {
      navigate({
          pathname: "/produk-travel",
        });
  }

  const gotoCareProductPage = () => {
    navigate({
        pathname: "/produk-safe",
      });
  }

  const gotoDeviceProductPage = () => {
    navigate({
        pathname: "/produk-device",
      });
  }

  const gotoExtendedWarrantyProductPage = () => {
    navigate({
        pathname: "/produk-extended-warranty",
    });
  }
  
  const gotoPropertyProductPage = () => {
    navigate({
        pathname: "/produk-property",
      });
  }

  const gotoOtherProductPage = () => {
    navigate({
        pathname: "/produk-lainnya",
      });
  }

  const gotoFinanceReportPage = () => {
    navigate({
        pathname: "/laporan-keuangan",
    });
  }

  const gotoWorkshopList = () => {
    navigate({
      pathname: '/bengkel-rekanan'
    })
  }

  const gotoCustomerSupport = () => {
    navigate({
        pathname: "/hubungi-kami",
    });
  }

  const gotoWhistleBlowingForm = () => {
    navigate({
        pathname: "/whistleblowing",
    });
  }


  return (
    <HeaderContainer>
        <div className='header-inner'>
            <div onClick={gotoHomePage}>
                <img src={imagesLink.tapInsureLogo} className='logo'/>
            </div>
            <div>
                <ul className='header-item'>
                    <li onClick={gotoHomePage}>
                        Beranda
                    </li>
                    <li className='header-dropdown' onMouseEnter={() => handleMouseEnter('produk')} onMouseLeave={handleMouseLeave}>
                        Produk <img src={imagesLink.arrowDown}/>
                        {dropdownVisible === 'produk' && (
                            <ul className='dropdown-menu'>
                                <li onClick={gotoAutoProductPage}>Auto</li>
                                <li onClick={gotoTravelProductPage}>Travel</li>
                                <li onClick={gotoExtendedWarrantyProductPage}>Extended Warranty</li>
                                <li onClick={gotoCareProductPage}>Safe</li>
                                <li onClick={gotoDeviceProductPage}>Device</li>
                                <li onClick={gotoPropertyProductPage}>Property</li>
                                <li onClick={gotoOtherProductPage}>Lainnya </li>
                            </ul>
                        )}
                    </li>
                    <li className='header-dropdown' onMouseEnter={() => handleMouseEnter('tentangKami')} onMouseLeave={handleMouseLeave}>
                        Tentang Kami <img src={imagesLink.arrowDown}/>
                        {dropdownVisible === 'tentangKami' && (
                            <ul className='dropdown-menu header-width'>
                                <li onClick={gotoAboutPage}>Tentang TAP Insure</li>
                                <li onClick={gotoVisiMisiPage}>Visi, Misi & Nilai Inti</li>
                                <li onClick={gotoOrganizationPage}>Jajaran Pengurus, Struktur Organisasi & Komite</li>
                                <li onClick={gotoCorporateGovernance}>Tata Kelola Perusahaan</li>
                            </ul>
                        )}
                    </li>
                    <li className='header-dropdown' onMouseEnter={() => handleMouseEnter('layanan')} onMouseLeave={handleMouseLeave}>
                        Layanan Pelanggan <img src={imagesLink.arrowDown}/>
                        {dropdownVisible === 'layanan' && (
                            <ul className='dropdown-menu'>
                                <li onClick={gotoCustomerSupport}>Hubungi Kami</li>
                                <li onClick={gotoHowtoClaim}>Klaim Asuransi</li>
                                <li onClick={gotoRoadsideAssistance}>Roadside Assistance</li>
                                <li onClick={gotoWorkshopList}>Daftar Bengkel Rekanan</li>
                                <li onClick={gotoWhistleBlowingForm}>Whistleblowing</li>
                            </ul>
                        )}
                    </li>
                    <li className='header-dropdown' onMouseEnter={() => handleMouseEnter('publikasi')} onMouseLeave={handleMouseLeave}>
                        Publikasi <img src={imagesLink.arrowDown}/>
                        {dropdownVisible === 'publikasi' && (
                            <ul className='dropdown-menu publication-head'>
                                {/* <li>Berita Pers</li>
                                <li>Blog</li> */}
                                <li onClick={gotoFinanceReportPage}>Laporan Keuangan</li>
                            </ul>
                        )}
                    </li>
                </ul>
            </div>
        </div>
    </HeaderContainer>
  )
}

export default Header
