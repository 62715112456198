import React, { useEffect, useState } from "react";
import {
  SelectionWrapper,
  SelectInputCtn,
  SelectInput,
  Countries,
} from "./elements";
import { useField, ErrorMessage } from "formik";
import { ImageUrl } from "../../../Images";

const SelectCountry = (props) => {
  const [field, meta, helpers] = useField(props.name);
  const [countryList, setCountryList] = useState(
    props.countryListOptions || []
  );

  const handleFilter = (val) => {
    if (val === "") {
      setCountryList(props.countryListOptions);
    } else {
      const fileteredList = props.countryListOptions.filter((item) =>
        props.renderer(item).toLowerCase().includes(val.toLowerCase())
      );
      setCountryList(fileteredList);
    }
  };

  const handleClick = (val) => {
    helpers.setValue(val);
    props.onSelect(val);
  };

  return (
    <SelectionWrapper>
      <SelectInputCtn>
        <SelectInput
          onChange={(e) => handleFilter(e.target.value)}
          placeholder={props.placeholder}
        />
        <img
          src={ImageUrl.Search}
          width="16px"
          height="16px"
          alt="search-icon"
        />
      </SelectInputCtn>
      {countryList?.map((country) => {
        return (
          <Countries onClick={() => handleClick(props.getter(country))}>
            {props.renderer(country)}
          </Countries>
        );
      })}
    </SelectionWrapper>
  );
};

export default SelectCountry;
