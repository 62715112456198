import styled from 'styled-components'

export const CheckboxContainer = styled.div`
  position: relative;
`

export const CheckboxLabel = styled.label`
  position: relative;
  background-color: ${props => props.disabled ? '#EEEEEE' : '#fff'};
  border: 1px solid #DDDDDD;
  border-radius: 4px;
  cursor: pointer;
  height: 18px;
  left: 0;
  position: absolute;
  top: 0;
  width: 18px;
  
  ${props => props.checked && (
    `
      border-color: #333333;
      background-color: #333333;
    `
  )}

  &:after {
    border: 2px solid #f5f5f5;
    border-top: none;
    border-right: none;
    content: "";
    height: 5px;
    left: 3px;
    opacity: 0;
    position: absolute;
    top: 4px;
    transform: rotate(-45deg);
    width: 10px;
    ${props => props.checked && (
      `
        opacity: 1;
      `
    )}
  }

`

export const CheckboxInput = styled.input.attrs({ type: 'checkbox' })`
  visibility: hidden;
  width: 13px;
  height: 13px;
`
