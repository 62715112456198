import styled from 'styled-components'

import { Page } from 'react-pdf'

export const PDFPreviewContainer = styled.div`
`

export const PDFPreviewPage = styled(Page)`
  canvas {
    height: unset !important;
    width: 100% !important;
  }
`
export const LoaderWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 400px;
`