import React, { useEffect, useState } from "react";
import { ImageUrl } from "../../../Images";
import {
  BenefiitTitle,
  Benefitdescription,
  BenefitDetailsCtn,
  BenefitDetailSubmitBtn,
  BenefitDetailWrapper,
  FlexColumnCtn,
  HolderTitle,
} from "../elements";
import Modal from "../../../components/Modal";
import PDFPreview from "../../../components/PDFPreview";
import { BenefitPackageData } from "../../../lib/travel/benefitDetailsCardData";
import { useGetBenefitDoc } from "../../../lib/api";

const BenefitDetails = (props) => {
  const [showModal, setShowModal] = useState(false);
  const {data: benefitDocData, dataLoading, getBenefitDoc = () => {}} = useGetBenefitDoc()

  useEffect(() => {
      const currpackage = localStorage.getItem("currPackage");
      currpackage && getBenefitDoc({product_code: 'aus-travel-insurance', package_code: currpackage, data_type: 'BENEFIT_DOC' })
  }, [])

  return (
    <BenefitDetailWrapper>
      <HolderTitle>Detail Paket</HolderTitle>
      {BenefitPackageData(props.pkgListData, props.draftData?.payload).packageDeatils && BenefitPackageData(props.pkgListData, props.draftData?.payload).packageDeatils?.map((data) => {
        return (
          <BenefitDetailsCtn>
            <img width="40px" height="40px" src={data.icon} />
            <FlexColumnCtn>
              <BenefiitTitle>{data.title}</BenefiitTitle>
              <Benefitdescription>{data.value}</Benefitdescription>
            </FlexColumnCtn>
          </BenefitDetailsCtn>
        );
      })}
      <HolderTitle>Daftar Manfaat</HolderTitle>
      {BenefitPackageData(props.pkgListData, props.draftData?.payload).benefitDetails && BenefitPackageData(props.pkgListData, props.draftData?.payload).benefitDetails?.map((data) => {
        return (
          <BenefitDetailsCtn>
            <img width="40px" height="40px" src={data.icon} />
            <FlexColumnCtn>
              <BenefiitTitle>{data.title}</BenefiitTitle>
              <Benefitdescription>{data.value}</Benefitdescription>
            </FlexColumnCtn>
          </BenefitDetailsCtn>
        );
      })}
      <BenefitDetailSubmitBtn onClick={() => setShowModal(true)}>
        Lihat Daftar Manfaat
      </BenefitDetailSubmitBtn>
      <Modal
        title={"Daftar Manfaat"}
        show={showModal}
        onClose={() => setShowModal(false)}
      >
        <div style={{ padding: "20px" }}>
          <PDFPreview src={benefitDocData?.data?.doc_url} />
        </div>
      </Modal>
    </BenefitDetailWrapper>
  );
};

export default BenefitDetails;
