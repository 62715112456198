import { useState, useEffect, useRef } from 'react'
import safePhoneNo from './utility/safePhoneNo'
import { sendOTP, verifyOTP } from './otpapi'

export const useCountdown = () => {
  const [countDownDate, setCountDownDate] = useState(30)
  const timerRef = useRef()

  const resetCountdown = () => {
    setCountDownDate(30)
  }

  useEffect(() => {
    timerRef.current = setInterval(() => {
      const newCountDownDate = countDownDate - 1

      if (newCountDownDate < 0) {
        clearInterval(timerRef.current)
      } else {
        setCountDownDate(newCountDownDate)
      }
    }, 1000)

    return () => clearInterval(timerRef.current)
  }, [countDownDate])

  return [countDownDate, resetCountdown]
}

export const useSendOTP = ({ onSuccess, onFailure }) => {
    const [isLoading, setIsLoading] = useState(false)
    const [isError, setIsError] = useState(false)
    const [response, setResponse] = useState({})
  
    const handleSend = async (phoneNo) => {
      try {
        const safePhoneNoValue = safePhoneNo(phoneNo)
        const payload = {
          phone: `${safePhoneNoValue}`,
          language_code: 'id'
        }
        setIsLoading(true)
        const res = await sendOTP({ data: payload })
        setIsLoading(false)
        setResponse(res.data)
  
        if (res.status !== 200 || !res.data?.operation_id) {
          setIsError(true)
        } else {
          onSuccess({
            operationId: res.data.operation_id,
            phoneNo: safePhoneNoValue
          })
        }
      } catch (e) {
        setIsLoading(false)
        setIsError(false)
        onFailure(e)
        console.error(e)
      }
    }
  
    return {
      isLoading,
      isError,
      response,
      handleSend
    }
  }
  
  export const useVerifyOTP = ({ otpInfo, onSuccess, onFailure }) => {
    const [isLoading, setIsLoading] = useState(false)
    const [isError, setIsError] = useState(false)
    const [isInvalid, setIsInvalid] = useState(false)
    const [response, setResponse] = useState({})
  
    const handleVerify = async ({ operationId, otp }) => {
      try {
        const payload = {
          operation_id: operationId,
          otp
        }
        setIsInvalid(false)
        setIsError(false)
        setIsLoading(true)
        const res = await verifyOTP({ data: payload })
        setIsLoading(false)
        setResponse(res.data)
  
        if (res.status !== 200) {
          setIsInvalid(true)
        } else {
          const newServiceUser = res.data?.is_service_set
          onSuccess({ newServiceUser })
        }
      } catch (e) {
        if (e.response) {
          if (e.response?.status === 400) {
            setIsInvalid(true)
          }
        }
        setIsLoading(false)
        setIsError(true)
        if (onFailure) {
          onFailure(e)
        }
        console.error(e)
      }
    }
  
    return {
      isLoading,
      isError,
      isInvalid,
      response,
      handleVerify
    }
  }