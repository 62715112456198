import { useState } from "react";
import { DatePickerContainer, DatePickerWarningContainer, DatePickerWarningImg, DatePickerWarningImgCtn, DatePickerWarningText } from "./DateOfBirth.style";
import Calendar from "react-calendar";
import {format} from 'date-fns'
import { FormattedMessage } from "react-intl";

const DateOfBirth = ({title, minDate, maxDate, ...props}) => {
  const [startDate, setStartDate] = useState(new Date());
  const [fromValue, setFromValue] = useState(null);
  const [currentView, setCurrentView] = useState('decade');

  const handleSetForm = (val) => {
    const thisYear = new Date(val);
    const oneDay = 24 * 60 * 60 * 1000;
    let nextOneYear = new Date(
      `${thisYear.getFullYear() + 1}-${
        thisYear.getMonth() + 1
      }-${thisYear.getDate()}`
    );
    nextOneYear = new Date(nextOneYear.getTime() - oneDay);
    const formattedDate =  format(val, 'yyyy-MM-dd')
    setFromValue(formattedDate);
    props.setFormValues({
      ...props.formValues,
      dob: formattedDate
    })
    props.setShowDOBModal(false)
  }

  const handleViewChange = (newView) => {
    setCurrentView(newView);
  };

  return (
    <DatePickerContainer currentView={currentView}>
        {props.tooltipText && <DatePickerWarningContainer>
            <DatePickerWarningImgCtn><DatePickerWarningImg /></DatePickerWarningImgCtn>
            <DatePickerWarningText>
              <FormattedMessage
                id={props.tooltipText}
                defaultMessage={props.tooltipText}
              />
            </DatePickerWarningText>
          </DatePickerWarningContainer>}
        <Calendar
              minDetail="decade"
              defaultView="decade"
              onChange={handleSetForm}
              maxDate={maxDate}
              minDate={minDate}
              value={fromValue}
              locale='id-ID'
              defaultActiveStartDate={maxDate}
              tileContent={({ _activeStartDate, date, view }) => {
                handleViewChange(view)
                switch (view) {
                  case 'month':
                    return (
                      <div
                        className={
                          'date-tile' +
                          (date.getDay() === 0 ? 'date-tile--end' : '')
                        }
                      >
                        {/* {date.getDate()} */}
                      </div>
                    );
                  default:
                    return '';
                }
              }}
              formatShortWeekday={(_locale, date) => {
                return new Intl.DateTimeFormat('id-ID', {
                  weekday: 'long',
                }).format(date).substring(0,1);
              }}
              showNeighboringMonth={false}
              next2Label={null}
              prev2Label={null}
              className="main"
              tileClassName={({ date, view }) => {
                if (view === 'month' && date.getDay() === 0) {
                  return 'tile sunday';
                }
                return 'tile'
              }}
          />
    </DatePickerContainer>
  );
};

export default DateOfBirth;
