export const imagesLink = {
    tapInsureAdvantage : 'https://storage.googleapis.com/pp_img/tap-travel-website/Homepage/homepage-hero.png',
    travelLogoHorizontal: 'https://storage.googleapis.com/pp_img/tap-travel-website/common/tap-travel-logo.svg',
    autoLogoHorizontal: 'https://storage.googleapis.com/pp_img/tap-travel-website/common/tap-auto-logo.svg',
    deviceLogoHorizontal: 'https://storage.googleapis.com/pp_img/tap-travel-website/common/tap-device-logo.svg',
    careLogoHorizontal: 'https://storage.googleapis.com/pp_img/tap-travel-website/common/tap-safe-logo.svg',
    propertyLogoHorizontal: 'https://storage.googleapis.com/pp_img/tap-travel-website/common/tap-property-logo.svg',
    warrantyLogoHorizontal: 'https://storage.googleapis.com/pp_img/tap-travel-website/common/tap-ew-logo.svg',
    lainnyaLogo: 'https://storage.googleapis.com/pp_img/tap-travel-website/common/tap-lainnya-logo.svg',
    bannerBottom: 'https://storage.googleapis.com/pp_img/tap-travel-website/Homepage/tap-insure-bottom-banner.png',
    appleIcon: 'https://storage.googleapis.com/pp_img/tap-travel-website/road-assistance/apple.svg',
    playStoreIcon: 'https://storage.googleapis.com/pp_img/tap-travel-website/road-assistance/playstore-icon.svg',

}

export const strings = {
    appStore: "App Store",
    playStore: "Play Store",
    foundOn: 'TEMUKAN DI',
    downloadText: 'Unduh di',
}