export const imageLink = {
    heroImage: 'https://storage.googleapis.com/pp_img/tap-travel-website/Product%20Page/TAP%20Care%20Hero%20Image.png',
    safeText: 'https://storage.googleapis.com/pp_img/tap-travel-website/Product%20Page/Safe%20Text.svg',
    redShield: 'https://storage.googleapis.com/pp_img/tap-travel-website/Product%20Page/red%20shield.svg',
    section2Image: 'https://storage.googleapis.com/pp_img/tap-travel-website/Product%20Page/Section%202%20TAP%20Safe%20Banner%20Image.png',
    section3Image: 'https://storage.googleapis.com/pp_img/tap-travel-website/Product%20Page/Section%203%20TAP%20Safe%20Banner%20Image%202.svg',
    section6Image: 'https://storage.googleapis.com/pp_img/tap-travel-website/Product%20Page/Section%206%20TAP%20Safe%20Banner%20Image.png',
    section8Image: 'https://storage.googleapis.com/pp_img/tap-travel-website/Product%20Page/Section%208%20TAP%20Safe%20Banner%20Image.svg',
    RIP: "https://storage.googleapis.com/pp_img/tap-travel-website/Product%20Page/Icon_Kejadian_Tidak_Terduga.svg",
    wheelChair: 'https://storage.googleapis.com/pp_img/tap-travel-website/Product%20Page/wheel%20chair.svg',
    cructh: 'https://storage.googleapis.com/pp_img/tap-travel-website/Product%20Page/kruk.svg',
    medicalExpenses: 'https://storage.googleapis.com/pp_img/tap-travel-website/Product%20Page/medical%20Expenses.svg',
    ambulance: 'https://storage.googleapis.com/pp_img/tap-travel-website/Product%20Page/Ambulance%20without%20%2B.svg',
    RIPWithShield: 'https://storage.googleapis.com/pp_img/tap-travel-website/Product%20Page/RIP%20%2B%20Shield.svg',
    climbing: 'https://storage.googleapis.com/pp_img/tap-travel-website/Product%20Page/climbing.svg',
    war: 'https://storage.googleapis.com/pp_img/tap-travel-website/Product%20Page/war.svg',
    nuclear: 'https://storage.googleapis.com/pp_img/tap-travel-website/Product%20Page/nuclear.svg',
    ribbon: 'https://storage.googleapis.com/pp_img/tap-travel-website/Product%20Page/ribbon.svg',

}

export const riplayDocUrl = 'https://storage.googleapis.com/pp_img/tap-travel-website/RIPLAY%20Docs/Safe.pdf'