import React from "react";
import { InquirySendOk, InquirySendText, InquirySendWrapper, Title } from "./elements";
import { ImageUrl } from "../../Images";
import { FormattedMessage } from "react-intl";

const InquirySend = (props) => {
    return (
        <InquirySendWrapper>
            <img width='64px' height='64px' src={ImageUrl.SendArrow} alt='send'/>
            <Title>Form Terkirim</Title>
            <InquirySendText>
                <FormattedMessage 
                    id='Informasi dan data Anda telah terkirim ke sistem kami. Tim Marketing kami akan segera menghubungi Anda via email atau whatsapp dalam waktu 1x24 jam. Terima kasih.'
                    defaultMessage='Informasi dan data Anda telah terkirim ke sistem kami. Tim Marketing kami akan segera menghubungi Anda via email atau whatsapp dalam waktu 1x24 jam. Terima kasih.'
                />
            </InquirySendText>
            <InquirySendOk
                id="send-btn"
                loading={false}
                onClick={() => props.setShowModal(false)}
                type="yellow"
            >
                Tutup
            </InquirySendOk>
        </InquirySendWrapper>
    )
}

export default InquirySend