import styled from "styled-components";

export const OptionsWrappper = styled.div`
  display: grid;
  width: 100%;
  grid-template-columns: repeat(${(props) => props.length}, 1fr);
`;
export const OptionBtn = styled.div`
  padding: 10px 16px 10px 16px;
  font-family: Mulish;
  font-size: 12px;
  font-weight: 700;
  line-height: 20px;
  text-align: center;
  background: ${props => props.isDisabled ? '#EEEEEE' : '#FFFFFF'};
  color: ${(props) => ((props.isSelected && !props.isDisabled) ? "#333333" : "#999999")};
  border: ${props => props.error ? '2px solid #DC0000' : '2px solid #eeeeee'};
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  gap: 8px;

  &:not(first) {
    border-right: none;  
  }
  &:first-child {
    border-top-left-radius: 16px;
    border-bottom-left-radius: 16px;
  }
  &:last-child {
    border-top-right-radius: 16px;
    border-bottom-right-radius: 16px;
    border-right: ${props => props.error ? '2px solid #DC0000' : '2px solid #eeeeee'};
  }
  
`;

export const PromptError = styled.div`
  width: 100%;
  margin: 8px 0px;
  display: flex;
  gap: 1%;
`;
export const PromptErrorMsg = styled.div`
  display: inline;
  margin-left: 4px;
  font-family: "Mulish";
  font-weight: 700;
  font-size: 0.7rem;
  font-family: Mulish;
  line-height: 1rem;
  letter-spacing: 0.02em;
  color: #DC0000;
`;
export const OptionstCtn = styled.div`
  width: 100%;
`;
