import React from "react";
import { OptionBtn, OptionsWrappper, PromptError, PromptErrorMsg, OptionstCtn } from "./elements";
import { ErrorMessage, useField } from "formik";
import { ImageUrl } from "../../../Images";

const Options = (props) => {
  const [field, meta, helpers] = useField(props.name);

  const handleClick = (val) => {
    helpers.setValue(val);
    props.onChange(val);
  };

  return (
    <OptionstCtn>
      <OptionsWrappper length={props.optionsList.length}>
        {props.optionsList.map((option) => {
          return (
            <OptionBtn
              isSelected={field.value === props.getter(option)}
              onClick={() => handleClick(props.getter(option))}
              value={props.getter(option)}
            >
              {props.renderer(option)}
            </OptionBtn>
          );
        })}
      </OptionsWrappper>
      <ErrorMessage
        name={props.name}
        render={(msg) => (
          <PromptError>
            <PromptErrorMsg>
              <div>{msg}</div>
            </PromptErrorMsg>
          </PromptError>
        )}
      />
    </OptionstCtn>
  );
};

export default Options;
