import React, { useEffect, useState } from "react";
import { FormCtn, InputContainer, InputText, InputTextCtn, PromptError, PromptErrorMsg, SearchImageContainer, TextLabel, TextWrapper } from "./elements";
import { ErrorMessage, useField } from "formik";
import { ImageUrl } from "../../../Images";

const iconGenerator = {
  text: ImageUrl.UpDownArorw,
  date: ImageUrl.Calendar3,
  sms: ImageUrl.SMSGrey,
  phone: ImageUrl.PhoneGrey,
  pin: ImageUrl.Pin,
  time: ImageUrl.Time
}

export default function TextInput({
  type = "text",
  label = "",
  onHandleChange = () => {},
  inputValue = "",
  icontype = 'text',
  top = '5px',
  placeholder = '',
  showLabel=true,
  isFieldDisabled = false,
  ...props
}) {
  const [field, meta, helpers] = useField(props.name)
  const [value, setValue] = useState("");

  useEffect(() => {
    setValue(inputValue);
  }, [inputValue]);

  function handleChange(e) {
    setValue(e.target.value);
    onHandleChange(e.target.value);
  }

  return (
    <InputTextCtn>
      <TextWrapper disabled={props.disabled} error={meta.touched && !!meta.error}>
        <FormCtn disabled={props.disabled}>
          <InputContainer>
            <InputText
              type={type}
              value={value}
              onChange={handleChange}
              readonly="readonly"
              disabled={props.disabled}
              autocomplete={"off"}
              label={label}
              showLabel={showLabel}
              placeholder={label ? '' : placeholder}
              isFieldDisabled={isFieldDisabled}
              {...props}
            /> 
            <TextLabel className={value && "filled"} isFilled={value} value={value}>{label}</TextLabel>
          </InputContainer>
        </FormCtn>
        <SearchImageContainer>
          <img
            src={iconGenerator[icontype]}
            width="18px"
            height="18px"
            alt="search-icon"
          />
        </SearchImageContainer>
      </TextWrapper>
      <ErrorMessage
        name={props.name}
        render={(msg) => (
          <PromptError>
            <PromptErrorMsg>
              <div>{msg}</div>
            </PromptErrorMsg>
          </PromptError>
        )}
      />
    </InputTextCtn>
  );
}
