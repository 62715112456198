import React from "react";
import Header from "../../components/Header";
import Footer from "../../components/Footer";
import {
  AnchorTag2,
  DetailsSectionHeader,
  DetailsSectionText,
  FooterCtn,
  FormContainer,
  TermsB,
} from "./elements";
import DetailsSection from "./detailsSection";
import WhistleBlowingForm from "./whistleBlowingForm";
import { FormattedMessage } from "react-intl";
import ScrollToTop from "../../components/ScrollToTop";

const DEFAULT_CHUNK_MAP = {
  b: (chunks) => <TermsB><AnchorTag2 href='mailto: ayolapor@tap-insure.com' target="_blank">{chunks}</AnchorTag2></TermsB>,
  br: <br />,
};

const CustomerSupportForm = () => {
  return (
    <div>
      <Header />
      <FormContainer>
        <DetailsSection />
        <WhistleBlowingForm />
      </FormContainer>
      <FooterCtn>
        <DetailsSectionHeader>
          <FormattedMessage id="Ayo Lapor!" defaultMessage="Ayo Lapor!" />
        </DetailsSectionHeader>
        <DetailsSectionText>
          <FormattedMessage
            id="Melalui Formulir berikut ini atau melalui {br}Email ke ayolapor@tap-insure.com{br}"
            defaultMessage="Melalui Formulir berikut ini atau melalui {br}Email ke <b>ayolapor@tap-insure.com</b>"
            values={DEFAULT_CHUNK_MAP}
          />
        </DetailsSectionText>
      </FooterCtn>
      <ScrollToTop />
      <Footer />
    </div>
  );
};

export default CustomerSupportForm;
