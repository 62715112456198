import React, { useEffect, useMemo, useState } from "react";
import Timeline from "../../components/TravelTimeline";
import {
  HeaderText,
  PackageDetailsWrapper,
  SecondHeaderText,
} from "./elements";
import { createSearchParams, useNavigate, useSearchParams } from "react-router-dom";
import UserDetails from "./components/userFilledDetails";
import PackageDetailCards from "./components/PackageDetails";
import TravelHeader from "../../components/TravelHeader";
import { ImageUrl } from "../../Images";
import TravelFooter from "../../components/TravelFooter";
import convertToIDR from "../../lib/utility/convertToIDR";
import { useCreateDraft, useEvaluateProduct } from "../../lib/api";

const PackageDetails = () => {
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const [packageParams, setPackageParams] = useState(null);
  const [currpackage, setCurrPackage] = useState({});
  const [currSelectedPkg, setCurrSelectedPkg] = useState({});
  const { data: packageListData = {}, getPackageList = () => {} } =
    useEvaluateProduct();

  const remappedPackageParams = useMemo(() => {
    if (!packageParams) return {};
    return {
      ...packageParams,
      destinations: (packageParams?.destinations || "").split(","),
      no_of_insured_persons: Number(packageParams?.no_of_insured_persons),
      is_schengen: packageParams?.is_schengen === "true" ? true : false,
      request_type: "web",
    };
  }, [packageParams]);

  const { createDraft } = useCreateDraft({
    onSuccess: (draftId) => {
      if (draftId) {
        navigate({
          pathname: "/produk-travel-sales-flow/insured-details",
          search: createSearchParams({
            draft_id: draftId,
          }).toString(),
        })
      } else {
        // handle no draft
      }
    },
    onFailure: (e) => console.error(e),
  });

  useEffect(() => {
    if (Object.keys(remappedPackageParams).length) {
      setCurrSelectedPkg({});
      setCurrPackage({});
      getPackageList("aus-travel-insurance", remappedPackageParams);
    }
  }, [remappedPackageParams]);

  useEffect(() => {
    if (
      packageListData?.data &&
      packageListData?.data.packages &&
      Object.keys(packageListData?.data.packages).length
    ) {
      const firstPackage = packageListData?.data.packages[0];
      setCurrPackage(firstPackage);
    }
  }, [packageListData]);

  const userDetails = useMemo(() => {
    return {
      trip_type: {
        title: "Tipe Perjalanan",
        description: searchParams.get("trip_type", ""),
        iconUrl: ImageUrl.Tag,
      },
      trip_start_from: {
        title: "Dari",
        description: searchParams.get("trip_start_from", ""),
        iconUrl: ImageUrl.Rocket,
      },
      destinations: {
        title: "Tujuan",
        description: searchParams.get("destinations", ""),
        iconUrl: ImageUrl.Location,
      },
      trip_start_date: {
        title: "Tanggal Perjalanan",
        description: searchParams.get("trip_start_date", ""),
        iconUrl: ImageUrl.Date,
      },
      trip_end_date: {
        title: "Trip End Date",
        description: searchParams.get("trip_end_date", ""),
        iconUrl: ImageUrl.Date,
      },
      product_type: {
        title: "Jenis Perlindungan",
        description: searchParams.get("product_type", ""),
        iconUrl: ImageUrl.File,
      },
      no_of_insured_persons: {
        title: "Jumlah Tertanggung",
        description: searchParams.get("no_of_insured_persons", ""),
        iconUrl: ImageUrl.Profile,
      },
      is_schengen: {
        title: "Pengajuan Visa Schengen",
        description:
          searchParams.get("is_schengen") === "true" ? "true" : "false",
        iconUrl: ImageUrl.Calendar,
      },
    };
  }, [searchParams]);

  useEffect(() => {
    const searchParam = new URLSearchParams(window.location.search);
    const searchParamsObj = Object.fromEntries(searchParam);
    setPackageParams(searchParamsObj);
  }, [searchParams]);

  const handleSubmit = () => {
    const payload = {
      "product_code": "aus-travel-insurance",
      "package_code": currSelectedPkg.package_code,
      "step": 1,
      "data": {
        "package_request": {
          ...packageParams,
          'act': 1,
          'package_id': currSelectedPkg.package_code,
          "no_of_insured_persons": Number(packageParams?.no_of_insured_persons),
          "destinations": packageParams?.destinations.split(','),
          "is_schengen": packageParams?.is_schengen === 'true' ? true : false
        }
      }
    }

    createDraft(payload)
  }

  return (
    <PackageDetailsWrapper>
      <TravelHeader currpackage={currpackage} />
      <Timeline header="Pilih Paket" activeStep={2} />
      <UserDetails data={userDetails} />
      <HeaderText>Paket Asuransi Sesuai Kebutuhan Anda</HeaderText>
      <SecondHeaderText>
        Perjalanan akan terasa aman, nyaman, tanpa rasa khawatir dengan
        perlindungan terbaik dari kami
      </SecondHeaderText>
      <PackageDetailCards
        userDetails={userDetails}
        packageListData={packageListData}
        setCurrSelectedPkg={setCurrSelectedPkg}
      />
      <TravelFooter
        premium={convertToIDR(currSelectedPkg?.total_premium || "")}
        disabled={Object.keys(currSelectedPkg).length === 0}
        onSubmit={handleSubmit}
      />
    </PackageDetailsWrapper>
  );
};

export default PackageDetails;
