export default (unsafePhoneNo) => {
    let phoneNo = String(unsafePhoneNo)
  
    if (phoneNo.startsWith('0')) {
      phoneNo = phoneNo.substring(1)
    }
  
    if (phoneNo.startsWith('62-')) {
      phoneNo = phoneNo.replace('62-', '')
    }
  
    if (phoneNo.startsWith('62')) {
      phoneNo = phoneNo.replace('62', '')
    }

    if (phoneNo.startsWith('+62')) {
      phoneNo = phoneNo.replace('+62', '')
    }

    if (phoneNo.startsWith('+62-')) {
      phoneNo = phoneNo.replace('+62-', '')
    }
  
    return phoneNo
  }
  