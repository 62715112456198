export const strings = {
  bengkelHeading: "Bengkel Rekanan",
  searchCityPlaceholder: "KOTA",
  searchButtonText: "CARI",
  viewAllButtonText: "LIHAT SEMUA",
  tableHeaders: {
    name: "Nama Bengkel",
    type: "Jenis Bengkel",
    region: "Provinsi",
    city: "Kota",
    distance: "Jarak",
    location: "Lokasi",
  },
  companyFooter: "© 2024 TAP Insure. Hak cipta dilindungi Undang-Undang.",
  companyDetails: "PT Ausuradi Untuk Semua merupakan Perusahaan Asuransi yang bertitan dan diawasi oleh Otoritas Jasa Keuangan.",
  companyLocation: "Gedung One Pacific Place Lantai 10 unit C Kawasan SCBD, Jl. Jend. Sudirman Kav. 52-53, Jakarta Selatan 12190",
  companyLicense: "Nomor lisensi OJK No. KEP-540/D.06/2022",
};

export const workshopData = [
  { name: "PT Bengkel", type: "Non Authorized", region: "DKI Jakarta", city: "Jakarta Utara", distance: "1.4KM" },
  { name: "PT Bengkel", type: "Non Authorized", region: "DKI Jakarta", city: "Jakarta Utara", distance: "2 KM" },
  { name: "PT Bengkel", type: "Non Authorized", region: "DKI Jakarta", city: "Jakarta Utara", distance: "3.5 KM" },
  { name: "PT Bengkel", type: "Non Authorized", region: "DKI Jakarta", city: "Jakarta Utara", distance: "4 KM" },
  { name: "PT Bengkel", type: "Non Authorized", region: "DKI Jakarta", city: "Jakarta Utara", distance: "5 KM" },
  { name: "PT Bengkel", type: "Non Authorized", region: "DKI Jakarta", city: "Jakarta Utara", distance: "5.5 KM" },
  { name: "PT Bengkel", type: "Non Authorized", region: "DKI Jakarta", city: "Jakarta Utara", distance: "6 KM" },
  { name: "PT Bengkel", type: "Non Authorized", region: "DKI Jakarta", city: "Jakarta Utara", distance: "7 KM" },
  { name: "PT Bengkel", type: "Non Authorized", region: "DKI Jakarta", city: "Jakarta Utara", distance: "8 KM" },
  { name: "PT Bengkel", type: "Non Authorized", region: "DKI Jakarta", city: "Jakarta Utara", distance: "9 KM" },
];

export const imagesLink = {
  appleIcon: 'https://storage.googleapis.com/pp_img/tap-travel-website/road-assistance/apple.svg',
  arrowDown: 'https://storage.googleapis.com/pp_img/tap-travel-website/road-assistance/arrow-down.svg',
  arrowLeft: 'https://storage.googleapis.com/pp_img/tap-travel-website/road-assistance/arrow-left.svg',
  arrowRight: 'https://storage.googleapis.com/pp_img/tap-travel-website/road-assistance/arrow-right.svg',
  bannerForeGround: 'https://storage.googleapis.com/pp_img/tap-travel-website/road-assistance/banner-foreground.svg',
  beachForeground: 'https://storage.googleapis.com/pp_img/tap-travel-website/road-assistance/beachForeground.svg',
  beachBackground: 'https://storage.googleapis.com/pp_img/tap-travel-website/road-assistance/beach-background.svg',
  claimIllustration: 'https://storage.googleapis.com/pp_img/tap-travel-website/road-assistance/claim-illustration.svg',
  familyClaimApply: 'https://storage.googleapis.com/pp_img/tap-travel-website/road-assistance/family_claim_apply.svg',
  howToClaimBackdropBanner: 'https://storage.googleapis.com/pp_img/tap-travel-website/road-assistance/how-to-claim-backdrop-banner.svg',
  heroImage: 'https://storage.googleapis.com/pp_img/tap-travel-website/road-assistance/Workshop%20List%20Hero%20Image.png',
  claimIllustration1: 'https://storage.googleapis.com/pp_img/tap-travel-website/road-assistance/klaim%20illustration-03%201.svg',
  claimIllustration2: 'https://storage.googleapis.com/pp_img/tap-travel-website/road-assistance/klaim%20illustration-04%201.svg',
  locationIcon: 'https://storage.googleapis.com/pp_img/tap-travel-website/road-assistance/location-icon.svg',
  manCallingAfterAccident: 'https://storage.googleapis.com/pp_img/tap-travel-website/road-assistance/man-calling-after-accident.svg',
  manHoldingIpad: 'https://storage.googleapis.com/pp_img/tap-travel-website/road-assistance/man-holding-ipad.svg',
  manTryingToRepair: 'https://storage.googleapis.com/pp_img/tap-travel-website/road-assistance/man-trying-to-repair.svg',
  operationalUpdate: 'https://storage.googleapis.com/pp_img/tap-travel-website/road-assistance/operational_update.svg',
  playStoreIcon: 'https://storage.googleapis.com/pp_img/tap-travel-website/road-assistance/playstore-icon.svg',
  roadAssistanceBannerBackdrop: 'https://storage.googleapis.com/pp_img/tap-travel-website/road-assistance/raod-assistance-banner-backdrop.svg',
  roadAssistanceBannerImage: 'https://storage.googleapis.com/pp_img/tap-travel-website/road-assistance/raod-assistance-banner-image.svg',
  searchIcon: 'https://storage.googleapis.com/pp_img/tap-travel-website/road-assistance/search-icon.svg',
  towTruck: 'https://storage.googleapis.com/pp_img/tap-travel-website/road-assistance/tow-truck.svg',
  yellowLine: 'https://storage.googleapis.com/pp_img/tap-travel-website/road-assistance/yellow_line.svg',
  claimIllustrationPolicyList: 'https://storage.googleapis.com/pp_img/tap-travel-website/road-assistance/klaim%20illustration%20policy%20list.svg',
  claimIllustrationBenefitList: 'https://storage.googleapis.com/pp_img/tap-travel-website/road-assistance/klaim%20illustration-%20benefit%20list.svg',
  arrowDownWhite: 'https://storage.googleapis.com/pp_img/tap-travel-website/road-assistance/arrow-down-white.svg',
  placeholderLocation: 'https://storage.googleapis.com/pp_img/tap-travel-website/common/placeholder%20location%20icon.svg',
  workshop: 'https://storage.googleapis.com/pp_img/tap-travel-website/common/workshop%20icon.svg',
  arrowRightWhite: 'https://storage.googleapis.com/pp_img/tap-travel-website/common/Arrow%20Right%20White.svg'
}
