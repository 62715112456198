import styled from 'styled-components'
import {imageLink} from './constant'

export const DeviceContainer = styled.div`
    @import url('https://fonts.googleapis.com/css2?family=Mulish:ital,wght@0,200..1000;1,200..1000&display=swap');
    font-family: "Mulish", sans-serif;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    p, h1{
        margin: 0;
        padding: 0;
    }

    .hero-container {
        margin-top: 32px;
        background-image: url(${imageLink.heroImage});
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center;
        width: 90%;
        display: flex;
        align-items: center;
        flex-direction: column;
        height: 800px;
        max-height: 800px;
    }

    .hero-container h1 {
        font-size: 40px;
        line-height: 56px;
        font-weight: 800;
        text-align: center;
        margin-bottom: 16px;
    }

    .hero-container > p {
        max-width: 645px;
        font-size: 16px;
        line-height: 24px;
        text-align: center;
    }

    .hero-container img {
        margin-bottom: 16px;
    }

    .hero-div {
        width: 100%;
        display: flex;
        align-items: flex-end;
        flex-direction: column;
        margin-right: 256px;
        margin-top: 64px;
    }

    .hero-div-inner {
        border: 7px solid #B0B0B0;
        border-radius: 20px;
        padding: 24px;
        text-align: center;
        font-size: 14px;
        background-color: rgba(255,255,255,0.8);
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
    }

    .hero-div-items {
        border-bottom: 4px solid #B0B0B0;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
    }

    .hero-div-items .amount {
        font-size: 40px;
        color: #00B4FA;
        text-align: left;
        font-weight: 800;
    }

   
    .hero-div-items .super {
        font-size: 12px;
        color: #b0b0b0;
        vertical-align: super;
        font-weight: 800;
        position: relative;
        top: -8px; 
        margin-right: 16px;
    }

    .hero-div-items .normal {
        font-size: 14px;
        color: #000;
        font-weight: 400;
    }

    .hero-div-items:last-child {
        border-bottom: none;
    }

    .content {
        width: 100%;  
        background: rgb(217,217,217);
        background: -moz-linear-gradient(180deg, rgba(217,217,217,1) 0%, rgba(255,255,255,1) 80%);
        background: -webkit-linear-gradient(180deg, rgba(217,217,217,1) 0%, rgba(255,255,255,1) 80%);
        background: linear-gradient(180deg, rgba(217,217,217,1) 0%, rgba(255,255,255,1) 80%);
        filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#d9d9d9",endColorstr="#ffffff",GradientType=1);
    }

    .content-inner {
        width: 100%;
        max-width: 1000px;         
        margin: 0 auto;            
        text-align: justify;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        padding: 32px;
        line-height: 32px;
        gap: 40px;        
    }

    .section-title{
        text-align: center;
        margin-bottom: 16px;
        font-size: 40px;
        line-height: 56px;
        font-weight: 800;
    }

    .section-subtitle {
        text-align: center;
    }

    .section-subtitle:last-of-type {
        margin-bottom: 24px;
    }

    .section-footer {
        font-size: 16px;
        font-weight: 400;
    }

    .section-container {
        margin-top: 64px;
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .optional {
        font-size: 12px;
        font-weight: 400;
    }

    .section-container > img {
        width: 1000px;
    }

    .table-benefit {
        width: 100%;
        margin-bottom: 24px;
    }

    .table-header {
        display: grid;
        grid-template-columns: repeat(3, minmax(0, 1fr));
        border-bottom: 2px solid #FAFC00;
        padding: 16px;
        margin-bottom: 24px;
    }

    .table-header h4 {
        font-size: 16px;
        font-weight: 800;
        text-align: center;
    }

    .benefit {
        display: flex;
        align-items: center;
        gap: 16px;
        font-size: 14px;
        font-weight: 800;
        line-height: 24px;
    }

    .benefit img {
        width: 72px;
    }

    .claim-amount {
        font-size: 14px;
        font-weight: 800;
        text-align: center;
    }

    .claim-amount h1{
        font-size: 24px;
        font-weight: 800;
    }

    .claim-amount p {
        margin: 0;
        text-align: center;
    }

    .table-items {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
    }

    .table-item-container {
        display: grid;
        grid-template-columns: repeat(3, minmax(0, 1fr));
        text-align: left;
        row-gap: 24px;
    }

    .table-benefit-alt .table-header {
        grid-template-columns: repeat(2, minmax(0, 1fr));
    }

    .table-benefit-alt .table-item-container {
        grid-template-columns: repeat(2, minmax(0, 1fr));
    }

    .table-benefit:first-of-type {
        margin-bottom: 64px;
    }

    .tap-device-sec4-group {
        display: grid;
        grid-template-columns: repeat(3, minmax(0, 1fr));
        gap: 64px;
    }

    .group-items {
        max-width: 240px;
        display: flex;
        flex-direction: column;
        align-items: center;
        text-align: center;
        gap: 16px;
    }

    .group-items img {
        width: 96px;
        margin-bottom: 24px;
    }

    .group-items h3 {
        font-size: 18px;
        line-height: 24px;
        font-weight: 800;
    }

    .group-items p {
        line-height: 22px;
        font-size: 14px;
        font-weight: 500;
    }

    .btn-device {
        box-shadow: none;
        border: none;
        padding: 16px 24px;
        border-radius: 32px;
        cursor: pointer;
        background-color: #00B4FA;
        transition: background-color 0.5s ease;
        font-weight: 600;
        color: #FFF;

        &:hover {
            background-color: #0d8cbd;
        }
    }

    .sales-form {
        width: 100vw;
    }
    .btn-device-circle {
        box-shadow: none;
        border: none;
        border-radius: 50%;
        cursor: pointer;
        background-color: #00B4FA;
        transition: background-color 0.5s ease;
        color: #FFF;
        width: 47px;
        height: 47px;

        &:hover {
            background-color: #0d8cbd;
        }
    }
    .flex-ctn{
        display: flex;   
    }
    .flex-ctn img{
        width: 17px;   
    }

`