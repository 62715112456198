export const PRODUCT_TYPE_MAP = {
    'individual': 'Individual',
    'family': 'Keluarga',
    'family-A': 'Keluarga',
    'family-B': 'Keluarga',
    'group': 'Grup'
  }
  
  
  export const RELATIONSHIP_MAP = {
    'spouse': 'Pasangan',
    'child': 'Anak',
    'family': 'Keluarga',
    'friend': 'Teman',
    'parent': 'Orang tua',
    'sibling': 'Saudara',
    'other': 'Lainnya'
  }
  
  export const GENDER_MAP = {
    'male': 'Laki-laki',
    'female': 'Perempuan'
  }
  