import React, { useEffect, useState, useCallback } from "react";
import TravelHeader from "../../components/TravelHeader";
import TravelFooter from "../../components/TravelFooter";
import Timeline from "../../components/TravelTimeline";
import {
  ErrorCtn,
  PaymentHeader,
  PaymentMethodName,
  PaymentMethodsCtn,
  PaymentMethodsList,
  PaymentMethodsWrapper,
  PaymentSelectBtn,
  PaymentDescription,
  PaymentMethodsImageCtn,
  PaymentMethodImage,
} from "./elements";
import { ImageUrl } from "../../Images";
import { FormattedMessage } from "react-intl";
import convertToIDR from "../../lib/utility/convertToIDR";
import { useAttemptPayment, useGetDraftData, useGetPaymentMethods, usePaymentStatus } from "../../lib/api";
import { createSearchParams, useNavigate, useSearchParams } from "react-router-dom";
import cogoToast from "cogo-toast";
import { validateDataCall } from "../../lib/draft";

const PaymentMethods = () => {
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const [selectedPaymentMode, setSelectedPaymentMode] = useState(false)
  const { data: draftData = {}, getTravelDraftData } = useGetDraftData();
  const { data: paymentMethodsData = [], getPaymentMethods } = useGetPaymentMethods();
  const { additional_fee: {admin_fee = 0, stamp_fee = 0} = {}, coupon_data: { total_discount: discount_amount = 0, discounted_premium: discountedPremium = 0 } = {}, package_response: { premium: packagePremium = 0 } = {}, coupon_data = {}, package_response: packageDetails = {} } = draftData?.payload || {}
  const totalPremium = discountedPremium ?  convertToIDR(packageDetails.total_premium + (admin_fee + stamp_fee - discount_amount)) : packageDetails.total_premium ? convertToIDR(packageDetails.total_premium + admin_fee + stamp_fee) : 0

  const paymentAttempted = draftData?.status === 'PAYMENT_ATTEMPTED'
  const { data: paymentStatusData, getPaymentStatus } = usePaymentStatus()

  useEffect(() => {
    paymentAttempted && getPaymentStatus(searchParams.get("draft_id"))
  }, [paymentAttempted])


  useEffect(() => {
    getPaymentMethods()
    searchParams.get("draft_id") &&
      getTravelDraftData(searchParams.get("draft_id"));
  }, [])

  useEffect(() => {
    if (draftData?.status === 'QUOTE_COMPLETED') {
      navigate({
        pathname: "/payment/payment-status",
        search: createSearchParams({
          draft_id: searchParams.get("draft_id"),
        }).toString(),
      });
    }
  }, [draftData])

  useEffect(() => {
    if (!paymentStatusData?.data?.payment_info && !paymentAttempted) return

    setSelectedPaymentMode({
      ...paymentStatusData?.data?.payment_info
    })
  }, [paymentAttempted, paymentStatusData])

  const handleRedirectPaymentStatus = () => {
    navigate({
      pathname: "/payment/payment-status",
      search: createSearchParams({
        draft_id: searchParams.get("draft_id"),
      }).toString(),
    });
  }

  const attemptPaymentSuccess = (res) => {
    const token = res?.data.provider_response.token || ''

    if (token) {
      const optionMidtrans = {
        skipOrderSummary: true,
        onSuccess: () => {
          handleRedirectPaymentStatus()
          console.log('Payment Successfull')
        },
        onPending: () => {
          handleRedirectPaymentStatus()
          console.log('Payment Pending')
        },
        onError: () => {
          handleRedirectPaymentStatus()
          console.log('Payment UnSuccessfull')
        },
        onClose: () => {
          console.log('Payment Closed');
          searchParams.get("draft_id") && getTravelDraftData(searchParams.get("draft_id"));
        },
      };
      if(selectedPaymentMode.payment_method === 'GOPAY') {
        optionMidtrans.uiMode = 'qr'
      }
      try {  
        window.snap.pay(token, optionMidtrans)
      }
      catch (error) {
        console.error(error)
      }
    }
  }

  const attemptPaymentFailure = (error) => {
    cogoToast.error(
      error?.response?.data?.error || 'Unable to make Payment',
      { position: "top-right" }
    );
  }

  const { attemptPayment, isLoading: paymentLoading } = useAttemptPayment({
    onSuccess: attemptPaymentSuccess,
    onFailure: attemptPaymentFailure
  })

  const handlePayment = useCallback(async(paymentMode) => {
    const payload = {
      'payment_method': paymentMode.payment_method,
      'payment_gateway': 'midtrans',
      'type': 'aus',
      'ref_id': searchParams.get("draft_id"),
      'source': 'web'
    }

    if (paymentMode.bank_code) {
      payload['bank_code'] = paymentMode.bank_code
    }

    try {  
      const response = await validateDataCall({'product_code': draftData?.product_code, 'ref_id': searchParams.get("draft_id")})
      if (response && response?.data?.success) {
        attemptPayment(payload)
      }
    }
    catch (error) {
      cogoToast.error(
        error?.response?.data['validation_error'][0] || error?.response?.data?.error_details?.errors_v2?.message?.en,
        { position: "top-right" }
      );
    }
  }, [attemptPayment, searchParams.get("draft_id")])

  const handleSelectPaymentMode = (paymentMode) => {
    setSelectedPaymentMode({payment_method: paymentMode.key})
  }
    
  const addScript = () => {
    const script = document.createElement('script')
    script.src = process.env.REACT_APP_PAYMENT_URL
    script.async = true
    script.setAttribute('data-client-key', process.env.REACT_APP_PAYMENT_CLIENT_ID)
    document.body.appendChild(script)
  }

  const handleSubmit = useCallback(() => {
    handlePayment(selectedPaymentMode)
  }, [handlePayment, selectedPaymentMode])
  
  useEffect(() => {
    addScript()
  }, [])

  return (
    <div>
      <TravelHeader />
      <Timeline header="Pembayaran" activeStep={5} />
      <PaymentMethodsWrapper>
        <div>
          <PaymentHeader>
            <FormattedMessage
              id="Pilih Metode Pembayaran"
              defaultMessage="Pilih Metode Pembayaran"
            />
          </PaymentHeader>
          <PaymentDescription>
            <FormattedMessage
              id="Pilih cara pembayaran yang sesuai dengan kenyamanan Anda"
              defaultMessage="Pilih cara pembayaran yang sesuai dengan kenyamanan Anda"
            />
          </PaymentDescription>
        </div>
        <PaymentMethodsCtn>
          {(paymentMethodsData && paymentMethodsData.length) ? paymentMethodsData.map((method) => {
            return (
              <PaymentMethodsList>
                <PaymentMethodsImageCtn>
                  <PaymentMethodImage
                    src={method.is_active === 'True' ? selectedPaymentMode ? selectedPaymentMode.payment_method === method.key ? method.icon_active : method.icon_disabled : method.icon_idle : method.icon_disabled}
                    width="64px"
                    height="64px"
                    alt="methods"
                  />
                  <PaymentMethodName>{method.title}</PaymentMethodName>
                </PaymentMethodsImageCtn>
                <PaymentSelectBtn
                  id="payemt-btn"
                  disabled={method.is_active === 'False' ||  (paymentAttempted && paymentStatusData?.data?.payment_info.payment_method !== method.key)}
                  type={selectedPaymentMode.payment_method === method.key ? 'secondary' : 'blue'}
                  onClick={() => handleSelectPaymentMode(method)}
                >
                  {selectedPaymentMode.payment_method === method.key ? <img src={ImageUrl.WhiteCheck} width='16px' height='16px' alt='selected' /> : 'Pilih'}
                </PaymentSelectBtn>
              </PaymentMethodsList>
              );
          }) : <div>No Payment Methods Found!!!</div>}
        </PaymentMethodsCtn>
        <ErrorCtn>
          <FormattedMessage
            id={`Setelah menekan tombol 'Bayar', metode pembayaran tidak bisa diubah. Pastikan Anda memilih metode pembayaran yang sesuai.`}
            defaultMessage={`Setelah menekan tombol 'Bayar', metode pembayaran tidak bisa diubah. Pastikan Anda memilih metode pembayaran yang sesuai.`}
          />
        </ErrorCtn>
      </PaymentMethodsWrapper>
      <TravelFooter 
        premium={totalPremium}
        btnText="Bayar"
        onSubmit={() => handleSubmit()}
        loading={paymentLoading}
        disabled={!selectedPaymentMode}
        leftText='Total Biaya'
      />
    </div>
  );
};

export default PaymentMethods;
