import React, { useState } from 'react';
import { imagesLink } from '../../constants/imageLink';
import { YearDropdownContainer, YearDropdownList } from './YearDropdown.style';

const YearDropdown = ({ minYear = 2022, onChange }) => {
  const currentYear = new Date().getFullYear();
  const [selectedYear, setSelectedYear] = useState(currentYear);
  const [isOpen, setIsOpen] = useState(false);

  const years = Array.from({ length: currentYear - minYear + 1 }, (_, index) => currentYear - index);

  const toggleDropdown = () => setIsOpen(!isOpen);

  const handleYearSelect = (year) => {
    setSelectedYear(year);
    setIsOpen(false);
    if (onChange) onChange(year); // Call onChange when a year is selected
  };

  return (
    <YearDropdownContainer onClick={toggleDropdown}>
      <p>{selectedYear}</p>
      <img src={imagesLink.arrowDown} alt="Dropdown arrow" />

      {isOpen && (
        <YearDropdownList>
          {years.map((year) => (
            <li key={year} onClick={() => handleYearSelect(year)}>
              {year}
            </li>
          ))}
        </YearDropdownList>
      )}
    </YearDropdownContainer>
  );
};

export default YearDropdown;
