import { useRef, useEffect } from 'react'

import PropTypes from 'prop-types'

import {
  OTPInputContainer,
  OTPInputItem,
} from './elements'

const OTPInput = (props) => {
  const itemRef = useRef([])
  const itemList = ['first', 'second', 'third', 'fourth', 'fifth', 'sixth']
  const {
    valueDict, 
    setValueDict,
    isError,
    setIsError,
    onSubmit,
    setOTPValue
  } = props

  useEffect(() => {
    const mergeValues = itemList.reduce((prev, curr) => {
      if (valueDict[curr]) {
        return prev + (String(valueDict[curr]) | '')
      } else {
        return prev
      }
    }, '')
    if (mergeValues && mergeValues.length === 6) {
      setOTPValue(mergeValues)
    }
  }, [valueDict, itemList])

  const onChange = (e, item) => {
    const inputValue = e.target.value
    if (inputValue) {
      if (inputValue.length === itemList.length) {
        const valueList = inputValue.split('')
        const payload = valueList.reduce((prev, curr, index) => ({ ...prev, [itemList[index]]: curr }), {})
        setValueDict(prev => ({
          ...prev,
          ...payload
        }))
        itemRef.current[itemList[itemList.length - 1]].focus()
      } else if (inputValue.length > 1) {
        const itemIndex = itemList.indexOf(item)
        const [firstValue, secondValue] = inputValue.split('')
        if (itemIndex === itemList.length - 1) {
          setValueDict(prev => ({
            ...prev,
            [item]: firstValue
          }))
        } else {
          setValueDict(prev => ({
            ...prev,
            [item]: firstValue,
            [itemList[itemIndex + 1]]: secondValue
          }))
        }
        itemRef.current[item].nextSibling?.focus()
      } else {
        setValueDict(prev => ({
          ...prev,
          [item]: inputValue
        }))
        itemRef.current[item].nextSibling?.focus()
      }
    }
  }

  const moveToPreviousSibling = (item) => {
    itemRef.current[item].previousSibling.focus()
  }

  const handleDelete = (e, item) => {
    if (e.key === 'Backspace') {
      if (item !== 'end') {
        const index = itemList.indexOf(item)
        const payload = {}

        if (item === 'last' || index === itemList.length - 1) {
          payload[itemList[itemList.length - 1]] = ''
        } else {
          payload[itemList[index]] = ''
        }

        moveToPreviousSibling(item)
        setValueDict(prev => ({
          ...prev,
          ...payload
        }))
      } else {
        setIsError(false)
        setValueDict(prev => ({
          [itemList[0]]: ''
        }))
      }
    } else if (e.key === 'ArrowLeft') {
      if (item !== 'end') {
        itemRef.current[item].previousSibling.focus()
      }
    } else if (e.key === 'ArrowRight') {
      const index = itemList.indexOf(item)
      // if (item !== 'last' && index !== list.length - 1) {
      //   if (item === 'end') {
      //     itemRef.current[list[0]].nextSibling.focus()
      //   } else {
      //     itemRef.current[item].nextSibling.focus()
      //   }
      // }
    }
  }

  return (
    <OTPInputContainer>
      {itemList.map((item, index) => (
        <OTPInputItem
          isError={isError}
          key={`otp_${index + 1}`}
          className='partitioned'
          pattern='[0-9]*'
          type='number'
          minLength={1}
          maxLength={1}
          value={valueDict[item] || ''}
          autocomplete='one-time-code'
          onChange={(e) => onChange(e, item)}
          onKeyDown={e => handleDelete(e, index ? item : 'end')}
          ref={(el) => (itemRef.current[item] = el)}
        />
      ))}
    </OTPInputContainer>
  )
}

OTPInput.propTypes = {
  isError: PropTypes.bool
}

export default OTPInput
