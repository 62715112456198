import { useCallback } from 'react'
import { PaymentBreakUpContainer, AmountNameContainer, AmountRowContainer, GroupPackageAmountCtn, GroupPackageInsured, PromoTitleCtn, PromoContentCtn, PromoDisclaimer, PromoValues, PromoDiscountValue, RedAsterisk, BlackHr } from '../elements'
import convertToIDR from '../../../lib/utility/convertToIDR'
import Collapsible from '../../../components/Collapsible'

function PaymentBreakup ({
  paymentMap,
  noOfInsuredPersons,
  isGroupPackage,
  couponDetail = {},
  oneUserCouponApplied = false
}) {

  const _generatePromoTitle = (title, titleColor, titleWeight, amount, amountColor, amountWeight) => {
    return <PromoTitleCtn>
      {getAmountName(title, titleColor, titleWeight)}
      {getAmountName(amount, amountColor, amountWeight)}
    </PromoTitleCtn>
  }

  const generatePromoValues = (discountValue, discountPercentage) => {
    return <>
      <PromoValues>
          {`${convertToIDR(paymentMap.premium_per_person)} X ${discountPercentage}% =`}&nbsp;
          <PromoDiscountValue>- {convertToIDR(discountValue)}</PromoDiscountValue>
      </PromoValues>
    </>
  }

  const getAmountName = useCallback((title, color, weight) => {
    if ((title === 'Biaya Materai' || title === 'Total Premi') && isGroupPackage && noOfInsuredPersons > 1) {
      return <GroupPackageAmountCtn color={color} weight={weight}>
        <div>{title}</div>
        <GroupPackageInsured color='#999999' weight={weight}>{title === 'Total Premi' ? `(${noOfInsuredPersons} wisatawan X ${convertToIDR(paymentMap.premium_per_person)})` : `(${noOfInsuredPersons} wisatawan X ${convertToIDR(paymentMap.stamp_fee/noOfInsuredPersons)})`}</GroupPackageInsured>
      </GroupPackageAmountCtn>
    }
    return (
      <AmountNameContainer color={color} weight={weight}>{title}</AmountNameContainer>
    )
  }, [isGroupPackage, noOfInsuredPersons, paymentMap.stamp_fee, paymentMap.premium_per_person])

  const getAmountContainer = useCallback(({
    title, amount, background, titleColor, titleWeight, 
    amountColor, amountWeight, show
  }) => {
      if(!show) return
      else if (title === 'Potongan Harga' && isGroupPackage) {
        return (
          <Collapsible
            title={_generatePromoTitle(title, titleColor, titleWeight, amount, amountColor, amountWeight)}
            headerStyle={{padding: '12px 0px'}}
          >
            {
              couponDetail?.data && Object.keys(couponDetail?.data).map((key, ind) => {
                return <PromoContentCtn key={ind}>
                  <div>{couponDetail.data[key].name ? `${ind + 1}. ${couponDetail.data[key].name}` :  '-'} {couponDetail.data[key].discount_value ? '' : <RedAsterisk>*</RedAsterisk>}</div>
                  <div>{couponDetail.data[key].discount_value ? generatePromoValues(couponDetail.data[key].discount_value, couponDetail.discount_percent) : '-'}</div>
                </PromoContentCtn>
              })
            }
            {oneUserCouponApplied ? <PromoDisclaimer>
              <RedAsterisk>*  </RedAsterisk>Pernah menggunakan promo ini sebelumnya
            </PromoDisclaimer> : null}
          </Collapsible>
        )
      }
      return (
        <AmountRowContainer background={background}>
          {getAmountName(title, titleColor, titleWeight)}
          {getAmountName(amount, amountColor, amountWeight)}
        </AmountRowContainer>
      )
  }, [getAmountName, couponDetail, isGroupPackage, oneUserCouponApplied])

  return (
    <PaymentBreakUpContainer>
      {
        getAmountContainer({
          title: 'Total Premi',
          amount: paymentMap.basic_premium,
          show: paymentMap.basic_premium,
          titleColor: '#333333',
          amountColor: '#333333',
        })
      }
      {
        getAmountContainer({
          title: 'Biaya Materai',
          amount: paymentMap.total_stamp_fee,
          titleColor: '#333333',
          amountColor: '#333333',
          background: '#FFFFFF',
          show: paymentMap.total_stamp_fee
        })
      }
      {
        getAmountContainer({
          title: 'Biaya Admin',
          amount: paymentMap.admin_fee,
          titleColor: '#333333',
          amountColor: '#333333',
          background: '#FFFFFF',
          show: paymentMap.admin_fee
        })
      }
      <BlackHr />
      {
        getAmountContainer({
          title: 'Subtotal',
          amount: paymentMap.total_bill,
          titleColor: '#333333',
          amountColor: '#333333',
          background: '#FFFFFF',
          show: paymentMap.sub_total && paymentMap.discount,
          titleWeight: '800',
          amountWeight: '800'
        })
      }
      {
        getAmountContainer({
          title: 'Potongan Harga',
          amount: `- ${paymentMap.discount}`,
          titleColor: '#DC0000',
          amountColor: '#DC0000',
          background: '#FFFFFF',
          show: paymentMap.discount
        })
      }
      {paymentMap.discount && <BlackHr />}
      {
        getAmountContainer({
          title: 'Total Biaya',
          amount: paymentMap.total_premium,
          titleColor: '#B43CFA',
          amountColor: '#B43CFA',
          background: '#FFFFFF',
          show: paymentMap.total_premium,
          titleWeight: '800',
          amountWeight: '800'
        })
      }
 
    </PaymentBreakUpContainer>
  )
}

export default PaymentBreakup
