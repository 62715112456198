import React from "react";
import { DetailsSectionCtn, DetailsSectionHeader, DetailsSectionText } from "./elements.js";
import { FormattedMessage } from "react-intl";
import { ImageUrl } from "../../Images";
import { ImageCtn } from "./elements.js";

const DetailsSection = () => {

    return (
        <DetailsSectionCtn>
            <DetailsSectionHeader>
                <FormattedMessage 
                    id='Whistleblowing'
                    defaultMessage='Whistleblowing'
                />
            </DetailsSectionHeader>
            <DetailsSectionText>
                <FormattedMessage 
                    id='Sistem ini memproses pengaduan untuk melaporkan dugaan pelanggaran oleh Karyawan dan Manajemen PT. Asuransi Untuk Semua. Kerahasiaan pelapor dijamin dan dilindungi oleh Perusahaan. Untuk melaporkan pelanggaran, kamu dapat melengkapinya dengan bukti pendukung dan identitas lengkap.'
                    defaultMessage='Sistem ini memproses pengaduan untuk melaporkan dugaan pelanggaran oleh Karyawan dan Manajemen PT. Asuransi Untuk Semua. Kerahasiaan pelapor dijamin dan dilindungi oleh Perusahaan. Untuk melaporkan pelanggaran, kamu dapat melengkapinya dengan bukti pendukung dan identitas lengkap.'
                />
            </DetailsSectionText>
            <ImageCtn><img src={ImageUrl.WhistleBlowingForm2} width='105%' height='410px' alt='support'/></ImageCtn>
        </DetailsSectionCtn>
    )
}

export default DetailsSection