export const imageLink = {
    heroImage: 'https://storage.googleapis.com/pp_img/tap-travel-website/Product%20Page/TAP%20Travel%20Product%20Page%20Banner.png',
    section2Banner: 'https://storage.googleapis.com/pp_img/tap-travel-website/Product%20Page/Section%202%20Banner%20TAP%20Travel%20Product%20Page.png',
    shieldHealth: 'https://storage.googleapis.com/pp_img/tap-travel-website/Product%20Page/Icon_Lebih_Dari_Sekedar_Asuransi.svg',
    severancePay: 'https://storage.googleapis.com/pp_img/tap-travel-website/Product%20Page/Icon_Kompensasi_Tinggi_Tanpa_Khawatir%203.svg',
    certificate: 'https://storage.googleapis.com/pp_img/tap-travel-website/Product%20Page/Icon_Paket_Sesuai_Gaya_Hidupmu.svg',
    giveMoney: 'https://storage.googleapis.com/pp_img/tap-travel-website/Product%20Page/Icon_Klaim_Mudah_Cepat.svg',
    kidsAndParent: 'https://storage.googleapis.com/pp_img/tap-travel-website/Product%20Page/Icon_Untuk_Siapapun_Kapanpun.svg',
    briefcase: 'https://storage.googleapis.com/pp_img/tap-travel-website/Product%20Page/Icon_Perlindungan_Penuh_Perjalanan_Tenang.svg',
    earthAndPlane: 'https://storage.googleapis.com/pp_img/tap-travel-website/Product%20Page/Icon_Keliling_Dunia_Tanpa_Cemas.svg',
    travelGif: 'https://storage.googleapis.com/pp_img/tap-travel-website/Product%20Page/travel.gif',
    medicalExpenses: 'https://storage.googleapis.com/pp_img/tap-travel-website/Product%20Page/medical%20Expenses.svg',
    manwithBandage: 'https://storage.googleapis.com/pp_img/tap-travel-website/Product%20Page/man%20with%20bandage%20without%20%2B%20icon.svg',
    cancelPlane: 'https://storage.googleapis.com/pp_img/tap-travel-website/Product%20Page/cancel%20plane%202.svg',
    luggage: 'https://storage.googleapis.com/pp_img/tap-travel-website/Product%20Page/luggage.svg',
    checklist: 'https://storage.googleapis.com/pp_img/tap-travel-website/Product%20Page/checklist%20without%20%2B.svg',
    householdItems: 'https://storage.googleapis.com/pp_img/tap-travel-website/Product%20Page/Icon_Kehilangan_Isi_Rumah.svg',
    lawHammer: 'https://storage.googleapis.com/pp_img/tap-travel-website/Product%20Page/Icon_Tanggung_Gugat_Pribadi.svg',
    schedule: 'https://storage.googleapis.com/pp_img/tap-travel-website/Product%20Page/Icon_Perpanjangan_Jaminan_Otomatis.svg',
    manWithMask: 'https://storage.googleapis.com/pp_img/tap-travel-website/Product%20Page/Icon_Perlindungan_Dari_Pembajakan.svg',
    court: 'https://storage.googleapis.com/pp_img/tap-travel-website/Product%20Page/Icon_Pertanggungan_Biaya_Hukum.svg',
    creditCard: 'https://storage.googleapis.com/pp_img/tap-travel-website/Product%20Page/Icon_Penyalahgunaan_Kartu_Kredit.svg',
    keyCar: 'https://storage.googleapis.com/pp_img/tap-travel-website/Product%20Page/Icon_Perlindungan_Kendaraan_Sewa.svg',
    hotel: 'https://storage.googleapis.com/pp_img/tap-travel-website/Product%20Page/Icon_Kehilangan_Reservasi_Hotel.svg',
    section7Banner: 'https://storage.googleapis.com/pp_img/tap-travel-website/Product%20Page/Section%207%20TAP%20Travel%20Banner%20Image%202.png',
    section9Banner: 'https://storage.googleapis.com/pp_img/tap-travel-website/Product%20Page/Section%209%20TAP%20Travel%20Banner%20Image.png',
    travelText: 'https://storage.googleapis.com/pp_img/tap-travel-website/Product%20Page/Travel%20Text.svg'
}

export const riplayDocUrl = 'https://storage.googleapis.com/pp_img/consumer/SummaryofProductandServiceInformation-TAPTravelDec24.pdf'