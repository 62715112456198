export const imageLink = {
    heroImage: 'https://storage.googleapis.com/pp_img/tap-travel-website/Product%20Page/TAP%20Device%20Heero%20Image%202.png',
    deviceText: 'https://storage.googleapis.com/pp_img/tap-travel-website/Product%20Page/Device%20Text.svg',
    section2Image: 'https://storage.googleapis.com/pp_img/tap-travel-website/Product%20Page/Section%202%20TAP%20Device%20Banner%20Image.png',
    section5Image: 'https://storage.googleapis.com/pp_img/tap-travel-website/Product%20Page/Section%205%20Image%20TAP%20Device.png',
    section5Image2: 'https://storage.googleapis.com/pp_img/tap-travel-website/Product%20Page/Illustration.svg',
    section8Image: 'https://storage.googleapis.com/pp_img/tap-travel-website/Product%20Page/Section%208%20TAP%20Device%20Banner%20Image.png',
    phoneDrop: "https://storage.googleapis.com/pp_img/tap-travel-website/Product%20Page/Phone%20Drop.svg",
    waterDrop: 'https://storage.googleapis.com/pp_img/tap-travel-website/Product%20Page/Water%20Drop.svg',
    flammable: 'https://storage.googleapis.com/pp_img/tap-travel-website/Product%20Page/Flammable.svg',
    manWithMask: 'https://storage.googleapis.com/pp_img/tap-travel-website/Product%20Page/Icon_Tindakan_Kriminal_Pencurian_Perampokan.svg',
    phoneWithStats: 'https://storage.googleapis.com/pp_img/tap-travel-website/Product%20Page/Icon_Kerugian_Sebagian.svg',
    phoneCrack: 'https://storage.googleapis.com/pp_img/tap-travel-website/Product%20Page/Icon_Kerugian_Total.svg',
    roberry: 'https://storage.googleapis.com/pp_img/tap-travel-website/Product%20Page/Icon_Pencurian.svg'
}

export const riplayDocUrl = 'https://storage.googleapis.com/pp_img/tap-travel-website/RIPLAY%20Docs/Device.pdf'