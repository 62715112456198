import { useMemo } from "react";
import { FormattedMessage } from "react-intl";
import PropTypes from "prop-types";

import {
  TermsContentContainer,
  TermsOrderedList,
  TermsUnorderedList,
  TermsListItem,
  TermsListItemDescription,
  TermsListItemSubDescription,
  TermsP,
  TermsH2,
  TermsB,
  TermsA,
  TermsI,
  TermsU,
  Description,
} from "./elements";

const mailTo = (email) => {
  window.open(`mailto:${email}`)
}

const phoneTo = (phoneNo) => {
  window.open(`tel:${phoneNo}`)
}

const waTo = (phoneNo) => {
  window.open(`https://wa.me/${phoneNo}`)
}

const openLink = (link) => {
  window.open(link)
}

const DEFAULT_CHUNK_MAP = {
  p: (chunks) => <TermsP>{chunks}</TermsP>,
  b: (chunks) => <TermsB>{chunks}</TermsB>,
  a: (chunks) => <TermsA>{chunks}</TermsA>,
  i: (chunks) => <TermsI>{chunks}</TermsI>,
  u: (chunks) => <TermsU>{chunks}</TermsU>,
  small: (chunks) => <small>{chunks}</small>,
  br: <br />,
  ppcsmail: chunks => <TermsA id='pp-cs-mail' onClick={() => mailTo('cs@tap-insure.com')}>{chunks}</TermsA>,
  ppcsphone: chunks => <TermsA id='pp-cs-phone' onClick={() => waTo('62818889019')}>{chunks}</TermsA>,
  ppweb: chunks => <TermsA id='ppweb-link' onClick={() => openLink(process.env.NEXT_PUBLIC_WEB_LINK)}>{chunks}</TermsA>,
  claimsweb: chunks => <TermsA id='ppclaims-link' onClick={() => openLink(process.env.NEXT_PUBLIC_POLICIES_LINK)}>{chunks}</TermsA>,
  zurichcsphone: chunks => <TermsA id='zurich-cs-phone' onClick={() => phoneTo('+622150825555')}>{chunks}</TermsA>,
};

const TermsContent = (props) => {

  const renderList = (item) => {
    switch (item.bullet_points_type) {
      case "numeric_with_circle": {
        return (
          <TermsOrderedList
            listStyle="none"
            noDescriptionPadding={props.noDescriptionPadding}
          >
            {item.bullet_points?.map((listItem, ind) => (
              <TermsListItem circleBackground key={ind}>
                {listItem.description && (
                  <TermsListItemDescription>
                    <FormattedMessage
                      id={listItem.description}
                      defaultMessage={listItem.description}
                      values={DEFAULT_CHUNK_MAP}
                    />
                  </TermsListItemDescription>
                )}
                {listItem.subdescription && (
                  <TermsListItemSubDescription>
                    <FormattedMessage
                      id={listItem.subdescription}
                      defaultMessage={listItem.subdescription}
                      values={DEFAULT_CHUNK_MAP}
                    />
                  </TermsListItemSubDescription>
                )}
                {item.bullet_points_type && renderList(listItem)}
              </TermsListItem>
            ))}
          </TermsOrderedList>
        );
      }
      case "numeric_with_dot": {
        return (
          <TermsOrderedList
            listStyle="decimal"
            noDescriptionPadding={props.noDescriptionPadding}
          >
            {item.bullet_points?.map((listItem, ind) => (
              <TermsListItem key={ind}>
                <TermsListItemDescription>
                  <FormattedMessage
                    id={listItem.description}
                    defaultMessage={listItem.description}
                    values={DEFAULT_CHUNK_MAP}
                  />
                </TermsListItemDescription>
                {listItem.subdescription && (
                  <TermsListItemSubDescription>
                    <FormattedMessage
                      id={listItem.subdescription}
                      defaultMessage={listItem.subdescription}
                      values={DEFAULT_CHUNK_MAP}
                    />
                  </TermsListItemSubDescription>
                )}
                {item.bullet_points_type && renderList(listItem)}
              </TermsListItem>
            ))}
          </TermsOrderedList>
        );
      }
      case "alphabetical_with_dot": {
        return (
          <TermsOrderedList
            listStyle="lower-alpha"
            noDescriptionPadding={props.noDescriptionPadding}
          >
            {item.bullet_points?.map((listItem, ind) => (
              <TermsListItem key={ind}>
                {listItem.description && (
                  <TermsListItemDescription>
                    <FormattedMessage
                      id={listItem.description}
                      defaultMessage={listItem.description}
                      values={DEFAULT_CHUNK_MAP}
                    />
                  </TermsListItemDescription>
                )}
                {listItem.subdescription && (
                  <TermsListItemSubDescription>
                    <FormattedMessage
                      id={listItem.subdescription}
                      defaultMessage={listItem.subdescription}
                      values={DEFAULT_CHUNK_MAP}
                    />
                  </TermsListItemSubDescription>
                )}
                {item.bullet_points_type && renderList(listItem)}
              </TermsListItem>
            ))}
          </TermsOrderedList>
        );
      }
      case "roman_with_circle": {
        return (
          <TermsOrderedList
            listStyle="upper-roman"
            noDescriptionPadding={props.noDescriptionPadding}
          >
            {item.bullet_points?.map((listItem, ind) => (
              <TermsListItem key={ind}>
                {listItem.description && (
                  <TermsListItemDescription>
                    <FormattedMessage
                      id={listItem.description}
                      defaultMessage={listItem.description}
                      values={DEFAULT_CHUNK_MAP}
                    />
                  </TermsListItemDescription>
                )}
                {listItem.subdescription && (
                  <TermsListItemSubDescription>
                    <FormattedMessage
                      id={listItem.subdescription}
                      defaultMessage={listItem.subdescription}
                      values={DEFAULT_CHUNK_MAP}
                    />
                  </TermsListItemSubDescription>
                )}
                {item.bullet_points_type && renderList(listItem)}
              </TermsListItem>
            ))}
          </TermsOrderedList>
        );
      }
      case "bullet_dots": {
        return (
          <TermsUnorderedList
            listStyle="disc"
            noDescriptionPadding={props.noDescriptionPadding}
          >
            {item.bullet_points?.map((listItem, ind) => (
              <TermsListItem key={ind}>
                {listItem.description && (
                  <TermsListItemDescription>
                    <FormattedMessage
                      id={listItem.description}
                      defaultMessage={listItem.description}
                      values={DEFAULT_CHUNK_MAP}
                    />
                  </TermsListItemDescription>
                )}
                {listItem.subdescription && (
                  <TermsListItemSubDescription>
                    <FormattedMessage
                      id={listItem.subdescription}
                      defaultMessage={listItem.subdescription}
                      values={DEFAULT_CHUNK_MAP}
                    />
                  </TermsListItemSubDescription>
                )}
                {item.bullet_points_type && renderList(listItem)}
              </TermsListItem>
            ))}
          </TermsUnorderedList>
        );
      }
      default:
        <></>;
    }
  };

  const renderItem = (item) => {
    if (typeof item === "string") {
      return (
        <TermsP>
          <FormattedMessage
            id={item}
            defaultMessage={item}
            values={DEFAULT_CHUNK_MAP}
          />
        </TermsP>
      );
    }

    return (
      <>
        {item.title && (
          <>
            <TermsH2>
              <FormattedMessage
                id={item.title}
                defaultMessage={item.title}
                values={DEFAULT_CHUNK_MAP}
              />
            </TermsH2>
          </>
        )}
          {item.description ? (
            <Description
              havePaddng={item.bullet_points?.length ? true : false}
              noDescriptionPadding={props.noDescriptionPadding}
            >
              <TermsP>
                <FormattedMessage
                  id={item.description}
                  defaultMessage={item.description}
                  values={DEFAULT_CHUNK_MAP}
                />
              </TermsP>
            </Description>
          ) : null}
          {renderList(item)}
      </>
    );
  };

  const termsData = useMemo(
    () => props.getter(props.termsData),
    [props.termsData, props.getter]
  );

  return (
    <TermsContentContainer id="terms-content">
      {Array.isArray(termsData) ? termsData.map((td) => renderItem(td)) : <></>}
    </TermsContentContainer>
  );
};

TermsContent.propTypes = {
  getter: PropTypes.func,
};
export default TermsContent;
