import React, { useEffect, useState } from "react";
import {
  BenefiitTitle,
  Benefitdescription,
  BenefitDetailsCtn,
  BenefitDetailSubmitBtn,
  CardTitle,
  FlexColumnCtn,
  GridContainer,
  ReviewOrderDetailCtn,
  ReviewOrderDetailWrapper,
} from "../elements";
import PDFPreview from "../../../components/PDFPreview";
import Modal from "../../../components/Modal";
import { useGetBenefitDoc } from "../../../lib/api";

const TravelDetails = ({ benefitData = [], benefitPackageData = {} }) => {
  const [showModal, setShowModal] = useState(false)
  const {data: benefitDocData, dataLoading, getBenefitDoc = () => {}} = useGetBenefitDoc()

  useEffect(() => {
      const currpackage = localStorage.getItem("currPackage");
      currpackage && getBenefitDoc({product_code: 'aus-travel-insurance', package_code: currpackage, data_type: 'BENEFIT_DOC' })
  }, [])

  const getDetailComponent = (icon = "", title = "", value = "") => {
    return (
      <BenefitDetailsCtn>
        <img width="40px" height="40px" src={icon} />
        <FlexColumnCtn>
          <BenefiitTitle>{title}</BenefiitTitle>
          <Benefitdescription>{value}</Benefitdescription>
        </FlexColumnCtn>
      </BenefitDetailsCtn>
    );
  };

  return (
    <ReviewOrderDetailCtn>
      <ReviewOrderDetailWrapper>
        <CardTitle>Detail Perjalanan</CardTitle>
        <GridContainer>
          {benefitData.map((data) => {
            const { icon, title, value } = data;
            return <>{getDetailComponent(icon, title, value)}</>;
          })}
        </GridContainer>
      </ReviewOrderDetailWrapper>
      <ReviewOrderDetailWrapper>
        <CardTitle>Detail Paket</CardTitle>
        <GridContainer>
          {benefitPackageData?.packageDeatils?.map((data) => {
            const { icon, title, value } = data;
            return <>{getDetailComponent(icon, title, value)}</>;
          })}
        </GridContainer>
      </ReviewOrderDetailWrapper>
      <ReviewOrderDetailWrapper>
        <CardTitle>Daftar Manfaat</CardTitle>
        <GridContainer>
          {benefitPackageData?.benefitDetails?.map((data) => {
            const { icon, title, value } = data;
            return <>{getDetailComponent(icon, title, value)}</>;
          })}
        </GridContainer>

        <BenefitDetailSubmitBtn onClick={() => setShowModal(true)}>
          Lihat Daftar Manfaat
        </BenefitDetailSubmitBtn>
      </ReviewOrderDetailWrapper>
      <Modal
        title={"Daftar Manfaat"}
        show={showModal}
        onClose={() => setShowModal(false)}
      >
        <div style={{ padding: "20px" }}>
          <PDFPreview src={benefitDocData?.data?.doc_url} />
        </div>
      </Modal>
    </ReviewOrderDetailCtn>
  );
};

export default TravelDetails;
