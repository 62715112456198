import styled from 'styled-components';

export const DropdownWrapper = styled.div`
  position: relative;
  width: 30%;
`;

export const DropdownHeader = styled.div`
  border: 1px solid #ccc;
  border-radius: 4px;
  padding: 10px;
  cursor: pointer;
  font-size: 16px;
  color: #333;
  border: none;
  color: ${({ isDisabled }) => (isDisabled ? '#e0e0e0' : '#666666')};
  cursor: ${({ isDisabled }) => (isDisabled ? 'not-allowed' : 'pointer')};
  border-bottom: 2px solid #FAFC00;
  display: flex;
  align-items: center;
  justify-content: space-around;
  min-height: 48px;


  .placeholder-text {
    display: flex;
    align-items: center;
    gap: 8px;
  }
`;

export const Icon = styled.img`
  width: 16px;
  height: 16px;
  margin-left: 8px;
  transition: transform 0.5s ease;

  ${({ isOpen }) => isOpen && `
    transform: rotate(180deg);
  `}

  ${({ isDisabled }) => isDisabled && `
    opacity: 0.5;  
    cursor: not-allowed;
  `}
`;

export const PlaceholderIcon = styled.img`
  width: 32px;
  margin-left: 8px;
  transition: transform 0.5s ease;
 ${({ isDisabled }) => isDisabled && `
    opacity: 0.5;  
    cursor: not-allowed;
  `}
`;

export const DropdownList = styled.div`
  position: absolute;
  text-align: left;
  top: 100%;
  left: 0;
  width: 100%;
  background-color: #fff;
  border: 1px solid #ccc;
  border-radius: 4px;
  margin-top: 5px;
  z-index: 100;
  max-height: 200px;
  overflow-y: scroll;
`;

export const DropdownListItem = styled.div`
  padding: 10px;
  font-size: 16px;
  color: #333;
  cursor: pointer;
  &:hover {
    background-color: #007bff;
    color: #fff;
  }
`;
