import React from "react";
import { OptionBtn, OptionsWrappper, PromptError, PromptErrorMsg, OptionstCtn, OptionBtn2 } from "./elements";
import { ErrorMessage, useField } from "formik";
import { ImageUrl } from "../../../Images";

const BooleanValue = (props) => {
  const [field, meta, helpers] = useField(props.name);

  const handleClick = (val) => {
    helpers.setValue(val);
    // props.onChange(val);
  };

  return (
    <OptionstCtn>
      <OptionsWrappper length={props.optionsList.length}>
        <OptionBtn
            error={meta.touched && !!meta.error}
            isSelected={field.value === props.getter(props.optionsList[0])}
            onClick={() =>  props.disabledBtn !== props.getter(props.optionsList[0]) && handleClick(props.getter(props.optionsList[0]))}
            value={props.getter(props.optionsList[0])}
            isDisabled={props.showDisable &&  props.disabledBtn && (props.disabledBtn === props.getter(props.optionsList[0]))}
        >
            {props.renderer(props.optionsList[0])}
            {
                field.value === props.getter(props.optionsList[0]) && <img src={ImageUrl.Check} width='16px' height='16px' style={{marginLeft: '14px'}} alt='check'/>
            }
        </OptionBtn>
        <OptionBtn2
            error={meta.touched && !!meta.error}
            isSelected={field.value === props.getter(props.optionsList[1])}
            onClick={() => props.disabledBtn !== props.getter(props.optionsList[1]) && handleClick(props.getter(props.optionsList[1]))}
            value={props.getter(props.optionsList[1])}
            isDisabled={props.showDisable &&  props.disabledBtn && (props.disabledBtn === props.getter(props.optionsList[1]))}
        >
            {props.renderer(props.optionsList[1])}
            {
                field.value === props.getter(props.optionsList[1]) && <img src={ImageUrl.Check} width='16px' height='16px' style={{marginLeft: '14px'}} alt='check'/>
            }
        </OptionBtn2>
      </OptionsWrappper>
      <ErrorMessage
        name={props.name}
        render={(msg) => (
          <PromptError>
            {/* <img
              src={ImageUrl.Error}
              width="18px"
              height="18px"
            /> */}
            <PromptErrorMsg>
              <div>{msg}</div>
            </PromptErrorMsg>
          </PromptError>
        )}
      />
    </OptionstCtn>
  );
};

export default BooleanValue;
