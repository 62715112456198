import React from 'react'
import Header from '../../components/Header'
import Footer from '../../components/Footer'
import { ExtendedWarrantyContainer } from './ProductExtendedWarranty.style'
import { imageLink, riplayDocUrl } from './constant'
import InquiryForm from '../userCriteria/inquiryForm'
import ScrollToTop from '../../components/ScrollToTop'
import { ImageUrl } from '../../Images'

const ProductExtendedWarranty = () => {
  return (
   <ExtendedWarrantyContainer>
        <Header />
        <div className='hero-container'>
            <img src={imageLink.extendedWarrantyText}/>
            <h1>Garansi Kendaraanmu Habis? <br />Tenang, Kami Siap Jaga Kendaraan Kamu!</h1>
            <p>Garansi habis jangan gelisah, Mobilmu tetap aman terlindungi,Asuransi Extended Warranty Tap Insure jaga dari rusak parah, 
            Berkendara tenang, biaya pun terkendali.</p>
            <div className='hero-div'>
                <div className='hero-div-inner'>
                    <div className='hero-div-items'>
                        <p>Perlindungan Mulai Dari</p>
                        <p className='amount'><span className='super'>Rp</span>2,6 jt<span className='normal'> /tahun</span></p>
                        <p>Terlindungi Hingga</p>
                        <p className='amount'><span className='super'>Rp</span>200 jt</p>
                    </div>
                </div>
            </div>
        </div>
        <div className='content'>
            <div className='content-inner'>
                <div className='tap-ew-sec2 section-container'>
                    <h1 className='section-title'>Mengapa Asuransi Extended Warranty <br/>dari TAP Insure?</h1>
                    <p className='section-subtitle'>Kenali apa yang membuat Asuransi Extended Warranty Tap Insure berbeda.</p>
                    <img src={imageLink.section2Image} className='tap-ew-sec2-img'/>
                    <div className='tap-ew-sec2-group'>
                        <div className='group-items'>
                            <img src={imageLink.greenShield}/>
                            <h3>Layanan Mobil Pengganti</h3>
                            <p>Dapatkan mobil pengganti saat kendaraanmu mengalami kerusakan jika perbaikan berlangsung lebih dari 4 hari.</p>
                        </div>
                        <div className='group-items'>
                            <img src={imageLink.greenShield}/>
                            <h3>Perlindungan Menyeluruh</h3>
                            <p>Menjamin perbaikan dan penggantian suku cadang kendaraan untuk kerusakan mesin, transmisi, sistem rem, AC, dan komponen penting lainnya.</p>
                        </div>
                        <div className='group-items'>
                            <img src={imageLink.greenShield}/>
                            <h3>Jaringan Bengkel Terpercaya</h3>
                            <p>Lebih dari 2.500 bengkel di seluruh Indonesia siap melayani</p>
                        </div>
                    </div>
                </div>
                <div className='tap-ew-sec3 section-container'>
                    <h1 className='section-title'>Menjamin Perjalananmu dari Apa Saja?</h1>
                    <p className='section-subtitle'>Produk ini melindungi kamu dari kerugian atau kerusakan *akibat Kecelakaan:</p>
                    <div className='table-benefit'>
                        <div className='table-header'>
                            <h4>BRONZE</h4>
                            <h4>SILVER</h4>
                            <h4>GOLD</h4>
                        </div>
                        <div className='table-item-container'>
                            <div className='table-items'>
                                <div className='benefit'>
                                    <img src={imageLink.vechicleAge}/>
                                    Usia Kendaraan
                                </div>
                            </div>
                            <div className='table-items'>
                                <div className='claim-amount'>
                                    Sampai dengan
                                    <h1>11 Tahun</h1>
                                </div>
                            </div>
                            <div className='table-items'>
                                <div className='claim-amount'>
                                    Sampai dengan
                                    <h1>10 Tahun</h1>
                                </div>
                            </div>
                            <div className='table-items'>
                                <div className='claim-amount'>
                                    Sampai dengan
                                    <h1>8 Tahun</h1>
                                </div>
                            </div>
                            <div className='table-items'>
                                <div className='benefit'>
                                    <img src={imageLink.wallet}/>
                                    Batas Maksimum Pertanggungan Total per tahun
                                </div>
                            </div>
                            <div className='table-items'>
                                <div className='claim-amount'>
                                    Sampai dengan
                                    <h1>Rp80 jt</h1>
                                </div>
                            </div>
                            <div className='table-items'>
                                <div className='claim-amount'>
                                    Sampai dengan
                                    <h1>Rp100 jt</h1>
                                </div>
                            </div>
                            <div className='table-items'>
                                <div className='claim-amount'>
                                    Sampai dengan
                                    <h1>Rp200 jt</h1>
                                </div>
                            </div>
                            <div className='table-items'>
                                <div className='benefit'>
                                    <img src={imageLink.giveMoney}/>
                                    Batas Maksimum Pertanggungan per klaim
                                </div>
                            </div>
                            <div className='table-items'>
                                <div className='claim-amount'>
                                    Sampai dengan
                                    <h1>Rp30 jt</h1>
                                </div>
                            </div>
                            <div className='table-items'>
                                <div className='claim-amount'>
                                    Sampai dengan
                                    <h1>Rp40 jt</h1>
                                </div>
                            </div>
                            <div className='table-items'>
                                <div className='claim-amount'>
                                    Sampai dengan
                                    <h1>Rp50 jt</h1>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='tap-ew-sec4 section-container'>
                    <h1 className='section-title'>Jika Dibutuhkan...</h1>
                    <p className='section-subtitle'>Manfaat lainnya dari Asuransi Extended Warranty Tap Insure:</p>
                    <div className='tap-ew-sec4-group'>
                        <h2>Perlindungan saat terjadi kecelakaan</h2>
                        <div className='tap-ew-sec4-group-inner'>
                            <div className='group-items'>
                                <img src={imageLink.roadSide}/>
                                <h3 className='italic'>Roadside Assistance</h3>
                                <p>Bantuan di jalan seperti perbaikan kecil di lokasi.</p>
                            </div>
                            <div className='group-items'>
                                <img src={imageLink.gasoline}/>
                                <h3>Layanan Pengiriman Bahan Bakar</h3>
                                <p>Pengiriman bahan bakar saat kendaraan Anda kehabisan bahan bakar di jalan.</p>
                            </div>
                            <div className='group-items'>
                                <img src={imageLink.towTruck}/>
                                <h3>Layanan Derek 24 Jam</h3>
                                <p>Layanan derek selama 24 jam untuk membantu kendaraan yang mengalami kerusakan di jalan.</p>
                            </div>
                            <div className='group-items'>
                                <img src={imageLink.car}/>
                                <h3>Layanan Penggantian Mobil</h3>
                                <p>Mobil pengganti saat kendaraan Anda sedang dalam perbaikan.</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='tap-ew-sec5 section-container'>
                    <h1 className='section-title'>Mau Tau Lebih Lanjut?</h1>
                    <p className='section-subtitle'>Cek info lengkap Asuransi Extended Warranty Tap Insure sekarang!</p>
                    <div className='flex-ctn'>
                        <a href={riplayDocUrl} target='_blank'>
                            <button className="btn-ew">
                                Unduh Informasi Produk
                            </button>
                            <button className="btn-ew-circle"><img src={ImageUrl.DownloadWhite} width='17px' height='17px' alt='download'/></button>
                        </a>
                    </div>
                    <img src={imageLink.section5Image}/>
                </div>
                <div className='tap-ew-sec6 section-container'>
                    <h1 className='section-title'>Dapatkan Perlindunganmu disini!</h1>
                    <p className='section-subtitle'>Lindungi dirimu hanya dengan beberapa ketukan.</p>
                    <div className='sales-form'>
                        <InquiryForm product={"Asuransi Extended Warranty"}/>
                    </div>
                </div>
                <div className='tap-ew-sec7 section-container'>
                    <h1 className='section-title'>Saat Hidup Membawa Kejutan, <br />TAP Insure Siap Memberikan Perlindungan.</h1>
                    <p className='section-subtitle'>Tingkatkan Keamanan Kendaraanmu dengan Perlindungan Ekstra! Asuransi Extended Warranty Tap Insure <br />memberikan perlindungan komprehensif untuk kendaraanmu, memastikan ketenangan pikiran saat berkendara karena <br />kami siap melindungi kendaraanmu dari kerusakan tak terduga dan memastikan performa optimal setiap saat!</p>
                    <img src={imageLink.section7Image}/>
                </div>
            </div>
        </div>
        <ScrollToTop />
        <Footer />
   </ExtendedWarrantyContainer>
  )
}

export default ProductExtendedWarranty
