import { GENDER_MAP } from "../../constants/travel";
import { ImageUrl } from "../../Images";

const holderCardProcessor = (insuredPayload) => {
  const citizenship = !insuredPayload.citizenship ? 'Paspor' : insuredPayload.citizenship === 'Indonesian' ? 'WNI' : 'WNA'
  const idNo = insuredPayload.passport_no || insuredPayload.kitas_kitap || insuredPayload.ktp

  const finalPayloadDomestic = {
    'Detail Personal': [
        {
            title: `Nama Lengkap Sesuai ${
              citizenship === "WNI" ? "KTP" : "KITAS/KITAP"
            }`,
            content: insuredPayload.name,
            span: 1,
            icon: ImageUrl.Profile
        },
        {
          title: "Jenis Kelamin",
          content: GENDER_MAP[insuredPayload.gender],
          span: 1,
          icon: ImageUrl.Star
        },
        {
            title: "Tanggal Lahir",
            content: insuredPayload.dob,
            span: 2,
            icon: ImageUrl.DOB
        },
    ],
    'Detail Identitas': [
        {
            title: "Kewarganegaraan",
            content: citizenship,
            span: 1,
            icon: ImageUrl.Globe
        },
        {
            title: `Nomor ${!insuredPayload.citizenship ? "Sesuai Paspor" : insuredPayload.relationship === 'child' ? 'lengkap' :  insuredPayload.citizenship === "Indonesian" ? "Sesuai KTP" : "Sesuai KITAS/KITAP" }`,
            content: idNo,
            span: 1,
            icon: ImageUrl.Calendar
        }
    ],
    'Detail Kontak': [
        {
            title: "Nomor Handphone",
            content: insuredPayload.phone_no,
            span: 1,
            icon: ImageUrl.Telephone
        },
        {
            title: "Email",
            content: insuredPayload.email,
            span: 1,
            icon: ImageUrl.Mail
        },
    ],
    'Detail Alamat': [
        {
            title: 'Provinsi',
            content: insuredPayload?.policy_holder_address.province_name,
            span: 1,
            icon: ImageUrl.Location
        },
        {
            title: 'Kota',
            content: insuredPayload?.policy_holder_address.city_name,
            span: 1,
            icon: ImageUrl.Building
        },
        {
            title: 'Alamat Lengkap',
            content: insuredPayload?.policy_holder_address.address,
            span: 2,
            icon: ImageUrl.Home
        }
    ]
  }

  const finalPayloadInternational = {
    'Detail Personal': [
        {
            title: `Nama Sesuai Paspor`,
            content: insuredPayload.name,
            span: 2,
            icon: ImageUrl.Profile
        },
        {
          title: "Jenis Kelamin",
          content: GENDER_MAP[insuredPayload.gender],
          span: 1,
          icon: ImageUrl.Star
        },
        {
            title: "Tanggal Lahir",
            content: insuredPayload.dob,
            span: 1,
            icon: ImageUrl.DOB
        },
    ],
    'Detail Identitas': [
        {
            title: "Kewarganegaraan",
            content: citizenship,
            span: 1,
            icon: ImageUrl.Globe
        },
        {
            title: `Nomor ${!insuredPayload.citizenship ? "Sesuai Paspor" : insuredPayload.relationship === 'child' ? 'lengkap' :  insuredPayload.citizenship === "Indonesian" ? "Sesuai KTP" : "Sesuai KITAS/KITAP" }`,
            content: idNo,
            span: 1,
            icon: ImageUrl.Calendar
        }
    ],
    'Detail Kontak': [
        {
            title: "Nomor Handphone",
            content: insuredPayload.phone_no,
            span: 1,
            icon: ImageUrl.Telephone
        },
        {
            title: "Email",
            content: insuredPayload.email,
            span: 1,
            icon: ImageUrl.Mail
        },
    ],
    'Detail Alamat': [
        {
            title: 'Provinsi',
            content: insuredPayload?.policy_holder_address?.province_name,
            span: 1,
            icon: ImageUrl.Location
        },
        {
            title: 'Kota',
            content: insuredPayload?.policy_holder_address?.city_name,
            span: 1,
            icon: ImageUrl.Building
        },
        {
            title: 'Alamat Lengkap',
            content: insuredPayload?.policy_holder_address?.address,
            span: 2,
            icon: ImageUrl.Home
        }
    ]
  }

  return citizenship === 'Paspor' ? finalPayloadInternational : finalPayloadDomestic;
};

export default holderCardProcessor;
