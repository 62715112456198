import styled from 'styled-components'

export const CounterInputContainer = styled.div`
    margin-top: 20px;
`
export const CounterInputFieldContainer = styled.div`
`
export const CounterInputField = styled.input`
  border: 1px solid #E5E5E5;
  border-radius: 16px;
  margin: 0px 12px;
  padding: 0;
  -webkit-appearance: none;
  -moz-appearance: textfield;
  outline: none;
  
  font-family: 'Mulish';
  font-weight: 700;
  font-size: 16px;
  line-height: 3rem;
  text-align: center;
  transition: all 0.2s linear;


  color: #333333;

  &::placeholder {
    color: #E5E5E5;
  }

  :focus {
    border-color: #666666;
  }
`

export const CounterInputFieldGroup = styled.div`
    display: grid;
    grid-template-columns: 31% 37% 31%;
`

export const CounterInputButton = styled.button`
  margin: auto;
  border: none;
  width: 100%;
  height: 60px;
  flex-basis: 88px;
  border-radius: 16px;
  background-color: #ffffff;
  font-family: 'Mulish';
  font-weight: 400;
  font-size: 1.8rem;
  border: 2px solid #EEEEEE;
  cursor: pointer;

  ${props => props.disabled && (
    `
      background-color: #f5f5f5;
      color: #333333;
    `
  )}

  &:active{
    transform: scale(0.98); 
    box-shadow: 3px 2px 22px 1px rgba(0, 0, 0, 0.14); 
  }
`

export const CounterInputButtonIcon = styled.img`
  width: 14px;
  height: 14px;
`

export const CounterInputFieldLabel = styled.label`
  display: block;
  margin-bottom: 8px;
  font-family: 'Mulish';
  font-weight: 700;
  font-size: 1.2rem;
  line-height: 1.6rem;

  color: #666666;
`

export const CounterInputFieldLabelRequired = styled.span`
  color: #C80000;
`

export const CounterInputError = styled.div`
  margin: 8px 16px;
`

export const CounterInputErrorIcon = styled.img`
`

export const CounterInputErrorMsg = styled.div`
  display: inline;
  margin-left: 4px;
  font-family: 'Mulish';
  font-weight: 700;
  font-size: 1rem;
  line-height: 1.2rem;

  letter-spacing: 0.02em;

  color: #C80000;
`

export const CounterInputRejectRemark = styled.div`
  margin: 8px 16px 0px 16px;
  width: unset;
`
