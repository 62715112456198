export const imageLink = {
    heroImage: 'https://storage.googleapis.com/pp_img/tap-travel-website/Product%20Page/TAP%20Extended%20Warranty%20Product%20Page%20Hero%20Image.png',
    extendedWarrantyText: 'https://storage.googleapis.com/pp_img/tap-travel-website/Product%20Page/extended%20warranty%20text.svg',
    section2Image: 'https://storage.googleapis.com/pp_img/tap-travel-website/Product%20Page/Repair%20Car.png',
    section5Image: 'https://storage.googleapis.com/pp_img/tap-travel-website/Product%20Page/Section%205%20TAP%20Extended%20Warranty%20Banner%20Image.png',
    section7Image: 'https://storage.googleapis.com/pp_img/tap-travel-website/Product%20Page/Section%209%20TAP%20Auto%20Banner%20Image.png',
    greenShield: 'https://storage.googleapis.com/pp_img/tap-travel-website/Product%20Page/green%20shield.svg',
    vechicleAge: 'https://storage.googleapis.com/pp_img/tap-travel-website/Product%20Page/Icon_Usia_Kendaraan.svg',
    wallet: 'https://storage.googleapis.com/pp_img/tap-travel-website/Product%20Page/Icon_Batas_Maksimum_Pertanggungan.svg',
    giveMoney: 'https://storage.googleapis.com/pp_img/tap-travel-website/Product%20Page/Icon_Batas_Maksimum_Klaim.svg',
    roadSide: 'https://storage.googleapis.com/pp_img/tap-travel-website/Product%20Page/Icon_Roadside_Assitance%402x.svg',
    gasoline: 'https://storage.googleapis.com/pp_img/tap-travel-website/Product%20Page/Icon_Layanan_Pengiriman_Bahan_Bakar.svg',
    towTruck: 'https://storage.googleapis.com/pp_img/tap-travel-website/Product%20Page/Icon_Layanan_Derek_24_Jam.svg',
    car: 'https://storage.googleapis.com/pp_img/tap-travel-website/Product%20Page/Icon_Layanan_Penggantian_Mobil.svg'
}
export const riplayDocUrl = 'https://storage.googleapis.com/pp_img/tap-travel-website/RIPLAY%20Docs/Extended%20Warranty.pdf'