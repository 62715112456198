import { useMemo, useState } from 'react'
import {
  DetaillTabsContainer,
  Tab,
  TabTitle,
  ComponentWrapper,
  TabImage,
  FlexContainer
} from './elements'

const DetailsTabs = ({ tabs, active }) => {
  const [activeTab, setActiveTab] = useState(active.name)

  const onTabClickHandler = (name, component) => {
    setActiveTab(name)
  }

  const activeComponent = useMemo(() => {
    return (tabs.find(t => t.name === activeTab) || {}).component
  }, [tabs, activeTab])

  return (
    <DetaillTabsContainer>
      <FlexContainer justifyContent='space-between'>
        {tabs && tabs.length
          ? tabs.map((tab) => {
            const { id, name, displayName, component } = tab
            return (
              <>
                <Tab
                  id={id}
                  key={name}
                  onClick={() => onTabClickHandler(name, component)}
                  isActive={name === activeTab}
                >
                  {tab.pic && <TabImage>{tab.pic}</TabImage>}
                  <TabTitle isActive={name === activeTab}>
                    {displayName}
                  </TabTitle>
                </Tab>
              </>
            )
          })
          : null}
      </FlexContainer>
      <ComponentWrapper>{activeComponent}</ComponentWrapper>
    </DetaillTabsContainer>
  )
}
export default DetailsTabs
