import React from "react";
import {
  FooterCtn,
  FooterDescription,
  FooterWrapper,
  GreyText,
  SubmitBtn,
} from "./elements";
import LargeButton from "../LargeButton";
import cogoToast from "cogo-toast";

const TravelFooter = ({
  onSubmit = () => {},
  btnText = "Selanjutnya",
  premium = "",
  disabled = false,
  leftContent = () => {},
  showPremium = true,
  loading=false,
  leftText = 'Premi Dasar'
}) => {
  return (
    <FooterCtn>
      <FooterWrapper>
        {showPremium ? (
          <FooterDescription>
            <GreyText textSize="16px" color="#333333">
              {leftText} :&nbsp;
            </GreyText>
            <GreyText textSize="16px" color="#B43CFA">
              {premium} &nbsp;
            </GreyText>
          </FooterDescription>
        ) : (
          leftContent()
        )}
        <SubmitBtn
          id="submit-btn"
          loading={loading}
          disabled={disabled}
          onClick={() => onSubmit()}
          type="secondary"
        >
          {btnText}
        </SubmitBtn>
      </FooterWrapper>
    </FooterCtn>
  );
};

export default TravelFooter;
