import React, { useState } from 'react';
import { FooterContainer } from './Footer.style'
import { imagesLink } from '../../constants/imageLink'
import { useNavigate } from "react-router-dom";


const Footer = () => {
  const [openSections, setOpenSections] = useState({});
  const navigate = useNavigate();


  const toggleSection = (section) => {
    setOpenSections((prev) => ({
      ...prev,
      [section]: !prev[section],
    }));
  };

  const gotoAutoProductPage = () => {
    navigate({
        pathname: "/produk-auto",
      });
    }
    
    const gotoTravelProductPage = () => {
      navigate({
          pathname: "/produk-travel",
        });
  }

  const gotoCareProductPage = () => {
    navigate({
        pathname: "/produk-safe",
      });
  }

  const gotoDeviceProductPage = () => {
    navigate({
        pathname: "/produk-device",
      });
  }

  const gotoExtendedWarrantyProductPage = () => {
    navigate({
        pathname: "/produk-extended-warranty",
    });
  }  
  
  const gotoPropertyProductPage = () => {
    navigate({
        pathname: "/produk-property",
      });
  }

  const gotoOtherProductPage = () => {
    navigate({
        pathname: "/produk-lainnya",
      });
  }
  
  const gotoFinanceReportPage = () => {
    navigate({
        pathname: "/laporan-keuangan",
    });
  }

  const gotoPrivacyPolicyPage = () => {
    navigate({
      pathname: '/kebijakan-privasi'
    })
  }

  const gotoTermsConditionPage = () => {
    navigate({
      pathname: '/syarat-dan-ketentuan'
    })
  }

  const gotoAboutUsPage = () => {
    navigate({
      pathname: '/tentang-kami'
    })
  }

  return (
    <FooterContainer>
        <div className='inner-container'>
            <div className='footer-top'>
                <div className='footer-top-items'>
                    <img src={imagesLink.tapInsureLogo}/>
                    <p>PT Asuransi Untuk Semua merupakan Perusahaan Asuransi yang berizin dan diawasi oleh Otoritas Jasa Keuangan.<br/><strong>Nomor lisensi OJK No. KEP-56/D.05/2022</strong></p>
                    <div className='aus-mariberasuransi'>
                        <img src={imagesLink.AUSLogo}/>
                        <img src={imagesLink.MariBerasuransiLogo}/>
                    </div>
                    <div className='socmed'>
                        <a href='https://www.facebook.com/profile.php?id=100085662044998' target='_blank'><img src={imagesLink.facebookLogo} alt='Facebook Logo'/></a>
                        <a href='https://www.instagram.com/tap.insure/' target='_blank'><img src={imagesLink.instagramLogo} alt='Instagram Logo'/></a>
                        <a href='https://x.com/TapInsure_/' target='_blank'><img src={imagesLink.xLogo} alt='X Logo'/></a>
                        <a href='https://www.youtube.com/@TapInsure-n1l' target='_blank'><img src={imagesLink.youtubeLogo} alt='Youtube Logo'/></a>
                        <a href='https://www.linkedin.com/company/tap-insurance/' target='_blank'><img src={imagesLink.linkedinLogo} alt='Linkedin Logo'/></a>
                    </div>
                </div>
                <div className={`footer-top-items ${openSections['section2'] ? 'open' : ''}`}>
                    <h2 onClick={() => toggleSection('section2')}>
                      Perusahaan
                      <div className={`arrow ${openSections['section2'] ? 'open' : ''}`}>
                        <img src={imagesLink.arrowDown} alt="Arrow Down" />
                      </div>
                    </h2>
                    <ul>
                        <li onClick={gotoAboutUsPage} className='clickable'><a>Tentang Kami</a></li>
                        <li onClick={gotoFinanceReportPage} className='clickable'><a>Laporan Keuangan</a></li>
                        {/* <li><a>Berita Pers</a></li> */}
                        {/* <li><a>Blog</a></li>
                        <li><a>FAQ</a></li> */}
                    </ul>
                </div>
                <div className={`footer-top-items ${openSections['section3'] ? 'open' : ''}`}>
                    <h2 onClick={() => toggleSection('section3')}>
                      Produk
                      <div className={`arrow ${openSections['section3'] ? 'open' : ''}`}>
                        <img src={imagesLink.arrowDown} alt="Arrow Down"  />
                      </div>
                    </h2>
                    <ul>
                        <li onClick={gotoAutoProductPage}><a className='clickable'>Asuransi Auto</a></li>
                        <li onClick={gotoTravelProductPage}><a className='clickable'>Asuransi Travel</a></li>
                        <li onClick={gotoExtendedWarrantyProductPage} className='clickable'><a>Asuransi Extended Warranty</a></li>
                        <li onClick={gotoCareProductPage} className='clickable'><a>Asuransi Safe</a></li>
                        <li onClick={gotoDeviceProductPage} className='clickable'><a>Asuransi Device</a></li>
                        <li onClick={gotoPropertyProductPage} className='clickable'><a>Asuransi Property</a></li>
                        <li onClick={gotoOtherProductPage} className='clickable'><a>Lainnya</a></li>
                    </ul>
                </div>
                <div className={`footer-top-items ${openSections['section4'] ? 'open' : ''}`}>
                    <h2 onClick={() => toggleSection('section4')}>
                      Hubungi Kami
                      <div className={`arrow ${openSections['section4'] ? 'open' : ''}`}>
                        <img src={imagesLink.arrowDown} alt="Arrow Down"  />
                      </div>
                    </h2>
                    <ul>
                        <li><a className='footer-with-logo clickable' href='tel:+622150847128'><img src={imagesLink.phone}/> +62 (21) 5084-7128</a></li>
                        <li><a className='footer-with-logo clickable' href='mailto:Publicrelations@tap-insure.com'><img src={imagesLink.message}/>publicrelations@tap-insure.com</a></li>
                        <li><a className='footer-with-logo'><img src={imagesLink.location}/>Gedung One Pacific Place Lantai 10 unit C Kawasan SCBD Jl. Jend. Sudirman Kav. 52-53 Jakarta Selatan 12190</a></li>
                    </ul>
                </div>
                <div className={`footer-top-items ${openSections['section5'] ? 'open' : ''}`}>
                    <h2 onClick={() => toggleSection('section5')}>
                      Layanan Pelanggan
                      <div className={`arrow ${openSections['section5'] ? 'open' : ''}`}>
                        <img src={imagesLink.arrowDown} alt="Arrow Down" />
                      </div>
                    </h2>
                    <ul>
                        <li><a className='footer-with-logo'><img src={imagesLink.calendar}/> Senin - Jumat <br/>(09.00 - 18.00)</a></li>
                        <li><a className='footer-with-logo clickable' href='https://api.whatsapp.com/send/?phone=62818889019&text&type=phone_number&app_absent=0'><img src={imagesLink.whatsapp}/> 0818-889-019 <br />Chat Only</a></li>
                        <li><a className='footer-with-logo clickable' href='mailto:cs@tap-insure.com'><img src={imagesLink.message}/> cs@tap-insure.com</a></li>
                    </ul>
                </div>
            </div>
            <div className='footer-bottom'>
                <p>&copy; TAP Insure. Hak cipta dilindungi Undang - undang.</p>
                <div className='privacy-policy'>
                    <a onClick={gotoPrivacyPolicyPage} className='clickable'>Kebijakan Privasi</a>
                    <a onClick={gotoTermsConditionPage} className='clickable'>Syarat & Ketentuan</a>
                </div>
            </div>
        </div>
    </FooterContainer>
  )
}

export default Footer;
